import React, { useContext } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  ContactSideBar,
  ButtonSecondary,
  YourPrice,
  ButtonPrimary,
  StateTracker,
  StateEditor,
} from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ReviewStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { YourCoverReview } from './ReviewComponents/YourCover';
import { BusinessContact } from './ReviewComponents/BusinessContact';
import { BusinessDetails } from './ReviewComponents/BusinessDetails';
import clsx from 'clsx';

export const Review: React.FC = () => {
  const { activeStep, updateActiveStep, data } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  const onSubmit = () => {
    updateActiveStep(activeStep + 1);
    history.push(steps[Step.PAYMENT].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    history.push(steps[Step.BUSINESS_DETAILS].url);
  };

  const handleBackVehicleLookup = async () => {
    updateActiveStep(activeStep - 2);
    history.push(steps[Step.VEHICLE_DETAILS].url);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title="DLG Fleet - Review" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <div className={classes.gridMainDiv}>
            <Typography className="stepHeading" variant="h1">
              STEP 5 - REVIEW AND CONFIRM
            </Typography>
            <Typography className={clsx(classes.textBody2)}>
              Please review your details and make sure they’re correct:
            </Typography>

            <YourCoverReview onClick={handleBackVehicleLookup} data={data} />
            <BusinessDetails onClick={handleBack} data={data} />
            <BusinessContact onClick={handleBack} data={data} />

            <div className="stepBtnContainer">
              <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
              <ButtonPrimary onClick={onSubmit}>Next</ButtonPrimary>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} />
            <div className={classes.gridSidebarYourPriceContainer}>
              <YourPrice
                sticky={false}
                heading="Your Price:"
                cover={data.coverType}
                price={`£${data.quoteTotal.toFixed(2)}`}
                info={'Includes Insurance Premium Tax where applicable'}
              />
            </div>
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
        <Divider className="divider"></Divider>
      </Grid>
    </PageTemplate>
  );
};

export default Review;
