import React from 'react';
import { useStyles } from './ContactModelBodyStyles';
import { Typography } from '../../../components/atoms'; 

export const ContactModalBody: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.contactNumber} variant="h3">0345 246 0637</Typography>
      <Typography>Call charges will vary.</Typography>
    </div>
  );
};

export default ContactModalBody;
