import React, { useContext } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ContactSideBar,
  StateTracker,
  ButtonSecondary,
  ButtonPrimary,
  Checkbox,
  YourPrice,
  ButtonTertiary,
  StateEditor,
  Typography,
} from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './AssumptionsStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Grid, Link, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import yourPriceSchema from '../../components/atoms/YourPrice/YourPriceSchema.json';
import DescriptionIcon from '@material-ui/icons/Description';

export const Assumptions: React.FC = () => {
  const { activeStep, updateActiveStep, data } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();
  const getDocument = (url: string) => window.open(url);

  const schema = yup.object().shape({
    assumptionsStatementsAccepted: yup.bool().oneOf([true], 'Confirmation of above information is required'),
  });

  const { handleSubmit, control, formState, errors } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      assumptionsStatementsAccepted: false,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = () => {
    updateActiveStep(activeStep + 1);
    history.push(steps[Step.VEHICLE_DETAILS].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    history.push('/');
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title="DLG Fleet - Assumptions" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.gridMainDiv}>
              <Typography className="stepHeading" variant="h1">
                STEP 2 - ASSUMPTIONS
              </Typography>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                IMPORTANT STATEMENTS
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                Please make sure all the information you’ve told us is correct and your vehicle meets the criteria
                below. Your price is worked out using this information and if anything you’ve told us is wrong, you
                might not be covered.
              </Typography>
              <Typography className={classes.paragraphSpacing}>Your vehicle(s) must meet these criteria:</Typography>
              <ul className={classes.ul}>
                <li>It&apos;s either a car, light van, motorhome or motorbike</li>
                <li>It&apos;s privately or commercially registered in the UK</li>
                <li>
                  There aren&apos;t more people in it than the manufacturer would recommend, or more than nine
                  altogether including the driver
                </li>
                <li>It can&apos;t weigh more than 4 metric tons (4,000kg) in total, including any load carried</li>
                <li>
                  It can&apos;t be more than 7 metres long (apart from a tow bar or coupling device), 3 metres tall and
                  2.55 metres wide
                </li>
                <li>It&apos;s been serviced, looked after and used as recommended by the manufacturer</li>
                <li>
                  It&apos;s got a valid MOT certificate, if it needs one and meets the legal requirements and driving
                  laws that apply
                </li>
                <li>It must be fit to drive when you take out the policy and at the start of each journey</li>
                <li>
                  It&apos;s never used to carry things or people for money. For example, as a courier service or taxi
                </li>
              </ul>
              <Typography className={classes.paragraphSpacing}>
                The premium is calculated using this information and any incorrect, inaccurate or incomplete information
                could invalidate all or part of the policy and result in claims being rejected or not fully paid. This
                policy will be governed by English Law unless you and we agree otherwise.
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                If your circumstances change between the date you purchase the policy and the date when you require the
                policy to commence, please call us. Failure to inform us of any changes could invalidate all or part of
                the policy and result in claims being rejected or not fully paid.
              </Typography>
              <Typography>Please see the policy summary for more details on the types of vehicle we cover.</Typography>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                YOUR RIGHT TO CANCEL
              </Typography>
              <Typography className={classes.paragraphSpacing}>You can cancel your policy at any time.</Typography>
              <ul className={classes.ul}>
                <li>
                  If you cancel within 14 days of the policy starting or of you receiving the documents, whichever is
                  later (this is the cooling off period), we&apos;ll give you a full refund as long as you haven’t made
                  a claim.
                </li>
                <li>
                  If you cancel after that, as long as you haven&apos;t made a claim, we’ll give you a refund based on
                  how long your policy had left.
                </li>
              </ul>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                WE’VE GOT THE RIGHT TO CANCEL YOUR POLICY AT ANY TIME
              </Typography>
              <Typography>
                We&apos;ll give you 14 days notice in writing to the most recent address that you&apos;ve given us.
                We’ll give you back what you paid, apart from an amount for the time that your policy lasted.
              </Typography>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                HOW TO MAKE A COMPLAINT
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                If you&apos;re not happy about something, please let us know. The quickest way is to call us. For
                complaints to do with a breakdown, call us on 0800 051 0636.
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                For complaints about anything else, contact us on 0345 246 1558. You can also write to us at the address
                shown in your policy document which gives more details about how to make a complaint.
              </Typography>
              <Typography>Keep in mind, your cover is subject to the standard policy wording.</Typography>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                BREAKDOWN COVER POLICY WORDING
              </Typography>
              <Typography className={classes.paragraphSpacing}>Viewable in PDF. File size 239KB</Typography>
              <ButtonTertiary onClick={() => getDocument('pdfs/Fleet-Policy.pdf')} startIcon={<DescriptionIcon />}>
                Download
              </ButtonTertiary>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                PRIVACY OPTIONS
              </Typography>
              <Typography className={classes.paragraphSpacing}>How we will use your information.</Typography>
              <Typography className={classes.paragraphSpacing}>
                We will use your information and that of any other people named on your policy to assess your breakdown
                cover application and the terms on which cover may be offered. We may exchange your information with
                other insurers, reinsurers or other carefully selected third parties in order to manage your policy and
                offer you the best possible service. We will also obtain information about you from credit reference
                agencies to check your credit status and identity. The agencies record our enquiries but your credit
                standing will not be affected.
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                Green Flag breakdown cover is underwritten by U K Insurance Limited (UKI). The information you give us
                may be used by owned brands within UKI to submit to and check your details with fraud prevention
                agencies and other organisations. If you provide false or inaccurate information, we will record this.
                We and other organisations may use and search these records to prevent fraud and money laundering.
              </Typography>
              <Typography>
                If you would like full details on how we will use your information, please refer to our{' '}
                <Link
                  className={classes.textBlue}
                  href="https://u-k-insurance.co.uk/brands-policy.html"
                  target="_blank"
                >
                  privacy policy
                </Link>{' '}
                or the policy booklet.
              </Typography>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                AUTOMATIC RENEWALS
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                Your payment details will be securely stored to enable continuous payment authority. We may use these
                details to automatically renew your policy next year. This only applies to payments by Visa, Visa Delta,
                or MasterCard.
              </Typography>
              <Typography>
                We will ensure that you are sent a renewal invitation before taking any payment. Please call us if your
                circumstances change or you do not wish us to automatically renew your policy.
              </Typography>
              <Divider className="divider-my-1"></Divider>
              <Typography className="my1" variant="h2">
                INSURANCE PRODUCT INFORMATION DOCUMENT (IPID)
              </Typography>
              <Typography className={classes.paragraphSpacing}>
                To help you decide if this policy gives cover that&apos;s right for you, here&apos;s a summary of the
                main things that it will and won&apos;t cover. It&apos;s not personalised to you and doesn&apos;t
                contain everything, but full details are available before you buy and will be confirmed in documents
                we&apos;ll send if you take a policy with us.
              </Typography>
              <Typography className={classes.paragraphSpacing}>Viewable in PDF. File size 170KB</Typography>
              <ButtonTertiary
                className="my1"
                onClick={() => getDocument('pdfs/Fleet-IPID.pdf')}
                startIcon={<DescriptionIcon />}
              >
                Download
              </ButtonTertiary>
              <Typography>
                This product meets the demands and needs of those who wish to ensure that they have assistance if their
                vehicle breaks down and they are not in the UK.
              </Typography>
              <Divider className="divider-my-1"></Divider>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="assumptionsStatementsAccepted"
                  checkboxClassName={classes.checkbox}
                  control={control}
                  label="I confirm that the above statements are true and that I have read the insurance policy summary and the auto renewal wording."
                  color="primary"
                />
                {errors.assumptionsStatementsAccepted && (
                  <Typography className="error">{errors.assumptionsStatementsAccepted.message}.</Typography>
                )}
              </div>
              <Divider className="divider2-my-1"></Divider>
              <div className="stepBtnContainer">
                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
                <ButtonPrimary disabled={!formState.isValid} type="submit">
                  Next
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} />
            <div className={classes.gridSidebarYourPriceContainer}>
              <YourPrice
                sticky={false}
                heading={yourPriceSchema.heading}
                cover={data.coverType}
                price={`£${data.quoteTotal.toFixed(2)}`}
                info={yourPriceSchema.info}
              />
            </div>
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Assumptions;
