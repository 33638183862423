import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useStyles } from './ReCAPTCHAStyles';
import RGReCAPTCHA from 'react-google-recaptcha';

interface IReCAPTCHA {
  recaptchaToken: string,
  setRecaptchaToken: Dispatch<SetStateAction<string>>,
  showToken?: boolean
};

export const ReCAPTCHA: React.FC<IReCAPTCHA> = ({recaptchaToken, setRecaptchaToken, showToken}: IReCAPTCHA): JSX.Element => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
  }, []);

  const handleOnChange = (token: string) => setRecaptchaToken(token);
  const handleOnExpired = () => setRecaptchaToken('');
  const classes = useStyles();

  return (
    <div className={classes.recaptchaContainer}>
      {showToken ? <p className={classes.token}>Recaptcha token: {recaptchaToken}</p> : <></>}
      <RGReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={handleOnChange} onExpired={handleOnExpired} />
    </div>
  );
};

ReCAPTCHA.defaultProps = {
  showToken: false
};

export default ReCAPTCHA;
