import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from './VehicleInformationStyles';
import { Typography, ButtonPrimary, ButtonSecondary, TextField, SelectDropdown, DatePicker } from '../../../atoms';
import { Grid, InputLabel } from '@material-ui/core';
import { Control, FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import vehicleInfoData from './VehicleInformationData.json';

const initialVehicleFormValues = {
  make: '',
  model: '',
  transmissionType: '',
  fuelType: '',
  year: null,
};

interface IVehicleInformationData {
  make: string;
  model: string;
  transmissionType: string;
  fuelType: string;
  year: string;
}

const schema = yup.object().shape({
  make: yup.string().required('Make is required.').matches(/^[a-zA-Z0-9\\s]+$/, 'Invalid make.'),
  model: yup.string().required('Model is required.').matches(/^[a-zA-Z0-9\\s]+$/, 'Invalid model.'),
  transmissionType: yup.string().required('Transmission Type is required.'),
  fuelType: yup.string().required('Fuel Type is required.'),
  year: yup
    .date()
    .required('Year is required.')
    .nullable()
    .min('1900/01/01', 'Minimum required number invalid.')
    .max('2021/12/31', 'Max required number invalid.')
    .typeError('Invalid date.'),
});

interface IVehicleInformation {
  entry: any;
  saveFields: (data?: any) => void;
  informationIsCorrect: boolean|undefined;
  setInformationIsCorrect: Dispatch<SetStateAction<boolean|undefined>>;
}

interface IManualForm {
  control: Control
  errors: FieldErrors
  handleSubmit: any;
  saveFields: (data?: any) => void;
  setInformationIsCorrect: Dispatch<SetStateAction<boolean|undefined>>,
  formState: any;
}

const ManualForm = ({ control, errors, handleSubmit, saveFields, setInformationIsCorrect, formState }: IManualForm): JSX.Element => {
  const classes = useStyles();

  const onSubmit = (data: IVehicleInformationData) => {
    saveFields({ ...data, year: new Date(data.year).getFullYear() });
    setInformationIsCorrect(true);
  };

  return (
    <div className={classes.vehicleInfoForm}>
      <Typography className={classes.vehicleInfoFormHeader} variant="h3">
        MANUALLY ENTER VEHICLE DETAILS
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel className={classes.inputLabel} htmlFor="make">MAKE</InputLabel>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <TextField className={classes.vehicleInfoFormTextField} id="make" name="make" control={control} />
          {errors.make && <p className={classes.errorText}>{errors.make.message}</p>}  
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel className={classes.inputLabel} htmlFor="model">MODEL</InputLabel>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} className={classes.modelGrid}>
          <TextField className={classes.vehicleInfoFormTextField} id="model" name="model" control={control} />
          {errors.model && <p className={classes.errorText}>{errors.model.message}</p>}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel className={classes.inputLabel} htmlFor="transmissionType">TRANSMISSION</InputLabel>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <SelectDropdown className={classes.vehicleInfoFormDropdown} name="transmissionType" control={control} data={vehicleInfoData.transmission} />
          {errors.transmissionType && <p className={classes.errorText}>{errors.transmissionType.message}</p>}  
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel className={classes.inputLabel} htmlFor="transmission">FUEL TYPE</InputLabel>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <SelectDropdown className={classes.vehicleInfoFormDropdown} name="fuelType" control={control} data={vehicleInfoData.fuelType} />
          {errors.fuelType && <p className={classes.errorText}>{errors.fuelType.message}</p>}  
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel className={classes.inputLabel} htmlFor="year">YEAR</InputLabel>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <DatePicker
            views={['year']}
            placeholder="YYYY"
            format="yyyy"
            id="year"
            name="year"
            disableFuture={true}
            control={control}
          />
          {errors.year && <p className={classes.errorText}>{errors.year.message}</p>}  
        </Grid>
      </Grid>
      <ButtonPrimary
        disabled={!formState.isValid}
        onClick={handleSubmit(onSubmit)}
        className={classes.vehicleDetailsSubmitBtn}
      >Submit</ButtonPrimary>
    </div>
  );
};

export const VehicleInformation: React.FC<IVehicleInformation> = ({
  entry,
  saveFields,
  informationIsCorrect,
  setInformationIsCorrect,
}: IVehicleInformation): JSX.Element => {
  const classes = useStyles();

  const { handleSubmit, control, errors, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialVehicleFormValues,
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const handleVehicleInfoValid = () => {
    setInformationIsCorrect(true);
    saveFields();
  };
  const handleVehicleInfoInvalid = () => {
    setInformationIsCorrect(false);
  };

  if ((entry.make !== '') || entry?.apiNoResult === true) {
    return (
      <div className={classes.vehicleInformationContainer}>
        <div className={classes.vehicleInformation}>
          <ul className={classes.vehicleInformationList}>
            <li className={classes.vehicleInformationEntry}>
              MAKE <span className={classes.bold}>{entry.make ? entry.make : entry.apiNoResult ? 'NOT FOUND' : ''}</span>
            </li>
            <li className={classes.vehicleInformationEntry}>
              MODEL <span className={classes.bold}>{entry.model ? entry.model: entry.apiNoResult ? 'NOT FOUND' : ''}</span>
            </li>
            {entry.transmissionType !== '' ? (
              <li className={classes.vehicleInformationEntry}>
                TRANSMISSION TYPE{' '}
                <span className={classes.bold}>{entry.transmissionType ? entry.transmissionType : entry.apiNoResult ? 'NOT FOUND' : ''}</span>
              </li>
            ) : (
              <></>
            )}
            {entry.fuelType !== '' ? (
              <li className={classes.vehicleInformationEntry}>
                FUEL TYPE <span className={classes.bold}>{entry.fuelType ? entry.fuelType : entry.apiNoResult ? 'NOT FOUND' : ''}</span>
              </li>
            ) : (
              <></>
            )}
            <li className={classes.vehicleInformationEntry}>
              YEAR OF MANUFACTURE <span className={classes.bold}>{entry.year ? entry.year : entry.apiNoResult ? 'NOT FOUND' : ''}</span>
            </li>
          </ul>
        </div>
        {entry.apiNoResult === true && informationIsCorrect === undefined && entry.complete === false ? (
          <div className={classes.vehicleInformationConfirm}>
            <Typography className={classes.confirmHeading} variant="h4">
              NO VEHICLE FOUND, ADD MANUALLY?
            </Typography>
            <ButtonSecondary onClick={handleVehicleInfoInvalid}>Yes</ButtonSecondary>
          </div>
        ) : (
          <></>
        )}
        {entry.apiNoResult === false && informationIsCorrect !== true && entry.complete === false ? (
          <div className={classes.vehicleInformationConfirm}>
            <Typography className={classes.confirmHeading} variant="h4">
              IS THIS VEHICLE INFORMATION CORRECT?
            </Typography>
            <ButtonSecondary onClick={handleVehicleInfoValid}>Yes</ButtonSecondary>
            <ButtonSecondary onClick={handleVehicleInfoInvalid} className={classes.btnMargin}>No</ButtonSecondary>
          </div>
        ) : (
          <></>
        )}
        {informationIsCorrect === false ? (
          <ManualForm
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            saveFields={saveFields}
            setInformationIsCorrect={setInformationIsCorrect}
            formState={formState}
          />
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default VehicleInformation;
