import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  recaptchaContainer: {
    height: '5rem',
  },
  token: {
    overflowWrap: 'break-word'
  },
}));
