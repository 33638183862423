import React, { useState, useContext } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './PaymentStyles';
import { StepContext, IStepData, steps, Step } from '../../contexts/StepContext';
import { Grid, InputLabel, FormControl, Divider } from '@material-ui/core';
import {
  Typography,
  StepChecker,
  ButtonPrimary,
  ButtonSecondary,
  RadioButtonGroup,
  TextField,
  DocumentTitle,
  SelectDropdown,
  ContactSideBar,
  DatePicker,
  YourPrice,
  StateTracker,
  StateEditor,
} from '../../components/atoms';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PostcodeLookup, Modal } from '../../components/molecules';
import { startOfMonth, lastDayOfMonth, endOfDay } from 'date-fns';
import yourPriceSchema from '../../components/atoms/YourPrice/YourPriceSchema.json';
import PaymentSchema from './PaymentSchema.json';

export const Payment: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data } = useContext(StepContext);
  const [paymentInformationModalActive, setPaymentInformationModalActive] = useState(false);
  const { billingCheckerGroupData, dropdownData } = PaymentSchema;
  const history = useHistory();
  const classes = useStyles();
  const { modalData } = PaymentSchema;
  const minExpiryDate = startOfMonth(new Date());
  let yupCardType = '';

  const schema = yup.object().shape({
    cardholdersName: yup.string().required('Card holder name is required.'),
    cardNumber: yup
      .number()
      .typeError('Card number is required.')
      .required('Card number is required.')
      .when('cardType', {
        is: (value: string) => {
          yupCardType = value;
          return true;
        },
        then: yup
          .number()
          .typeError('Card number is required.')
          .required('Card number is required.')
          .test('card-number', 'Valid card number required', (val: number | undefined) => {
            if (yupCardType === 'MASTERCARD' && val) {
              const mPattern = new RegExp(
                '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$',
              );
              return mPattern.test(val?.toString());
            } else if (yupCardType === 'VISA' && val) {
              const vPattern = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
              return vPattern.test(val.toString());
            } else if (yupCardType === 'AMEX' && val) {
              const aPattern = new RegExp('^3[47][0-9]{13}$');
              return aPattern.test(val?.toString());
            } else {
              return false;
            }
          }),
      }),
    cardSecurityNumber: yup
      .number()
      .typeError('Security number is required.')
      .required('Card security number is required.')
      .when('cardType', {
        is: (value: string) => {
          yupCardType = value;
          return true;
        },
        then: yup
          .number()
          .typeError('Security number is required.')
          .required('Card security number is required.')
          .test('card-security-number', 'Invalid card security number', (val: number | undefined) => {
            if (yupCardType === 'MASTERCARD' && val?.toString().length === 3) {
              return true;
            } else if (yupCardType === 'VISA' && val?.toString().length === 3) {
              return true;
            } else if (yupCardType === 'AMEX' && val?.toString().length === 4) {
              return true;
            } else {
              return false;
            }
          }),
      }),
    cardType: yup.string().min(2, 'Please select a card type.').required('Card type is required.'),
    expiryDate: yup.date().typeError('Invalid Date.').required('End date is required.').min(minExpiryDate).nullable(),
    billingAddressIsSameAsPreviousSection: yup.string(),
    postcodeLookup: yup.object().when('billingAddressIsSameAsPreviousSection', {
      is: (value: string) => value === 'false',
      then: yup.object().shape({
        firstLineOfAddress: yup.string().required('First line of address is required.'),
        secondLineOfAddress: yup.string(),
        town: yup.string().required('Town is required.'),
        county: yup.string(),
        postcode: yup.string().required('Postcode is required.'),
      }),
    }),
  });

  const { handleSubmit, control, errors, setValue, formState, trigger, watch, getValues, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      cardSecurityNumber: '',
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = (stepData: IStepData) => {
    if (stepData.expiryDate == null) return;
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      expiryDate: endOfDay(lastDayOfMonth(new Date(stepData.expiryDate))),
    });
    history.push(steps[Step.SUMMARY].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({ ...data, ...getValues() });
    history.push(steps[Step.REVIEW].url);
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key == 'Delete' || event.key == 'Backspace') {
      // allow deletes - Firefox issue
    } else {
      const pattern = /[0-9\s]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }

      if ((event.target as HTMLInputElement).value.length > 15) {
        // too many characters
        event.preventDefault();
      }
    }
  };

  const handleOnFocus = () => {
    trigger('cardNumber');
    trigger('cardSecurityNumber');
  };

  const handleResetBillingAddressFields = (e): void => {
    if (e.target.value === 'true') {
      reset({
        ...data,
        ...getValues(),
        billingAddressIsSameAsPreviousSection: 'true',
        postcodeLookup: {
          firstLineOfAddress: '',
          secondLineOfAddress: '',
          county: '',
          town: '',
          postcode: '',
        },
      });
    }
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title="DLG Fleet - Payment" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.gridMainDiv}>
              <Typography className="stepHeading" variant="h1">
                STEP 6 - PAYMENT
              </Typography>
              <Typography className={classes.subTitle} variant="h2">
                CREDIT / DEBIT CARD DETAILS
              </Typography>
              <Divider className="divider-mb-1"></Divider>
              <Grid container>
                <Grid item xs={12} lg={8} className={classes.gridSecond}>
                  <InputLabel className={classes.inputLabel} htmlFor="cardholdersName">
                    CARDHOLDER{"'"}S NAME
                  </InputLabel>
                  <TextField
                    id="cardholdersName"
                    name="cardholdersName"
                    defaultValue={data.cardholdersName}
                    control={control}
                    fullWidth={true}
                  />
                  {errors.cardholdersName && <span className="error">{errors.cardholdersName.message}</span>} 
                </Grid>
                <Grid item xs={12} lg={8} className={classes.gridSecond}>
                  <InputLabel className={classes.inputLabel} htmlFor="cardNumber">
                    CARD NUMBER 
                  </InputLabel>
                  <TextField
                    id="cardNumber"
                    name="cardNumber"
                    defaultValue={data.cardNumber}
                    control={control}
                    fullWidth={true}
                    onKeyPress={handleOnKeyPress}
                  />
                  {errors.cardNumber && <span className="error">{errors.cardNumber.message}</span>} 
                </Grid>
                <Grid item xs={12} lg={8} className={classes.gridSecond}>
                  <InputLabel className={classes.inputLabel} htmlFor="cardType">
                    CARD TYPE
                  </InputLabel>
                  <SelectDropdown
                    defaultValue={data.cardType}
                    name="cardType"
                    control={control}
                    variant="outlined"
                    data={dropdownData}
                    className="w100"
                    onFocus={handleOnFocus}
                  />
                  {errors.cardType && <span className="error">{errors.cardType.message}</span>}
                  &nbsp;
                </Grid>
                <Grid item xs={12} lg={8} className={classes.gridSecond}>
                  <Grid className={classes.cardSecurityNumberGridContainer} container>
                    <InputLabel className={classes.inputLabel} htmlFor="cardSecurityNumber">
                      CARD SECURITY NUMBER
                    </InputLabel>
                    <Modal
                      modalContainerClassName={classes.cardSecurityNumberIcon}
                      componentType="icon"
                      iconType="info"
                      open={paymentInformationModalActive}
                      setOpen={setPaymentInformationModalActive}
                      heading={modalData.heading}
                      body={modalData.body}
                    />
                  </Grid>
                  <TextField
                    id="cardSecurityNumber"
                    name="cardSecurityNumber"
                    defaultValue={data.cardSecurityNumber}
                    control={control}
                    fullWidth={true}
                    inputProps={{ maxLength: 4 }}
                    onKeyPress={handleOnKeyPress}
                    onFocus={handleOnFocus}
                  />
                  {errors.cardSecurityNumber && <span className="error">{errors.cardSecurityNumber.message}</span>}
                </Grid>
                <Grid item xs={12} lg={8} className={classes.gridSecond}>
                  <InputLabel className={classes.inputLabel} htmlFor="expiryDate">
                    Expiry Date
                  </InputLabel>
                  <DatePicker
                    className="mt05"
                    id="expiryDate"
                    name="expiryDate"
                    control={control}
                    defaultValue={data.expiryDate}
                    placeholder="MM/YY"
                    format="MM/yy"
                    openTo="month"
                    views={['month', 'year']}
                    minDate={minExpiryDate}
                  />
                  {errors.expiryDate && (
                    <div>
                      <span className="error">{errors.expiryDate.message}</span>
                    </div>
                  )}
                   
                </Grid>
                <Grid item xs={12} lg={8} className={classes.gridSecond}>
                  <InputLabel className={classes.inputLabel} htmlFor="billingAddressIsSameAsPreviousSection">
                    BILLING ADDRESS IS SAME AS PREVIOUS SECTION
                  </InputLabel>
                  <FormControl component="fieldset">
                    <RadioButtonGroup
                      onClick={handleResetBillingAddressFields}
                      name="billingAddressIsSameAsPreviousSection"
                      control={control}
                      data={billingCheckerGroupData}
                      defaultValue={data.billingAddressIsSameAsPreviousSection}
                    />
                  </FormControl>
                </Grid>
                {watch('billingAddressIsSameAsPreviousSection') === 'false' ? (
                  <>
                    <Grid item xs={12} lg={12} className={classes.gridSecond}>
                      <div className={classes.postcodeLookupContainer}>
                        <Grid container alignItems="baseline" justify="flex-start" direction="row">
                          <Grid item xs={12} md={5} className={classes.gridSecond}>
                            <InputLabel className={classes.inputLabel} htmlFor="postcodeLookup.firstLineOfAddress">
                              FIRST LINE OF BILLING ADDRESS 
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={7} className={classes.gridSecond}>
                            <TextField
                              className={classes.postcodeLookupContainerField}
                              id="postcodeLookup.firstLineOfAddress"
                              name="postcodeLookup.firstLineOfAddress"
                              defaultValue={data.postcodeLookup.firstLineOfAddress}
                              control={control}
                            />
                            {errors.postcodeLookup?.firstLineOfAddress && (
                              <div className="error">{errors.postcodeLookup?.firstLineOfAddress.message}</div>
                            )}
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.gridSecond}>
                            <InputLabel className={classes.inputLabel} htmlFor="postcodeLookup.secondLineOfAddress">
                              SECOND LINE OF BILLING ADDRESS
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={7} className={classes.gridSecond}>
                            <TextField
                              className={classes.postcodeLookupContainerField}
                              id="postcodeLookup.secondLineOfAddress"
                              name="postcodeLookup.secondLineOfAddress"
                              defaultValue={data.postcodeLookup.secondLineOfAddress}
                              control={control}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.gridSecond}>
                            <InputLabel className={classes.inputLabel} htmlFor="postcodeLookup.county">
                              BILLING COUNTY
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={7} className={classes.gridSecond}>
                            <TextField
                              className={classes.postcodeLookupContainerField}
                              id="postcodeLookup.county"
                              name="postcodeLookup.county"
                              defaultValue={data.postcodeLookup.county}
                              control={control}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.gridSecond}>
                            <InputLabel className={classes.inputLabel} htmlFor="postcodeLookup.town">
                              BILLING TOWN
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={7} className={classes.gridSecond}>
                            <TextField
                              className={classes.postcodeLookupContainerField}
                              id="postcodeLookup.town"
                              name="postcodeLookup.town"
                              defaultValue={data.postcodeLookup.town}
                              control={control}
                            />
                            {errors.postcodeLookup?.town && (
                              <div className="error">{errors.postcodeLookup?.town.message}</div>
                            )}
                          </Grid>
                          <Grid item xs={12} md={5} className={`${classes.gridSecond}`}>
                            <InputLabel className={classes.inputLabel} htmlFor="postcodeLookup.postcode">
                              BILLING POSTCODE
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={7} className={classes.gridSecond}>
                            <TextField
                              className={classes.postcodeLookupContainerField}
                              id="postcodeLookup.postcode"
                              name="postcodeLookup.postcode"
                              defaultValue={data.postcodeLookup.postcode}
                              control={control}
                            />
                            {errors.postcodeLookup?.postcode && (
                              <div className="error">{errors.postcodeLookup?.postcode.message}</div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.postcodeLookupGrid}>
                          <PostcodeLookup
                            name="postcodeLookup"
                            setValue={setValue}
                            postcode={watch('postcodeLookup.postcode')}
                            trigger={trigger}
                            buttonLabel="Billing Postcode Look Up"
                          />
                        </Grid>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Divider className="divider2-mb-1"></Divider>
              <div className="stepBtnContainer">
                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
                <ButtonPrimary disabled={!formState.isValid} type="submit">
                  Next
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} />
            <div className="mt1">
              <YourPrice
                sticky={false}
                heading={yourPriceSchema.heading}
                cover={data.coverType}
                price={`£${data.quoteTotal.toFixed(2)}`}
                info={yourPriceSchema.info}
              />
            </div>
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Payment;
