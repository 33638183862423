import React, { useContext } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ContactSideBar,
  ButtonPrimary,
  ButtonSecondary,
  StateTracker,
  StateEditor,
  Typography,
} from '../../components/atoms';
import { PaymentError } from '../../components/molecules';
import { ConfirmationNotice } from '../../components/organisms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ConfirmationStyles';
import { StepContext, initialNavStepperData, steps, Step } from '../../contexts/StepContext';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const Confirmation: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  const handleGoToPaymentPage = async () => {
    updateActiveStep(activeStep - 2);
    history.push(steps[Step.PAYMENT].url);
  };

  const handleGoToHomepage = () => {
    updateActiveStep(1);
    updateData(initialNavStepperData);
    history.push('/');
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title="DLG Fleet - Confirmation" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <div className={classes.gridMainDiv}>
            <Typography className="stepHeading" variant="h1">
              STEP 8 - CONFIRMATION
            </Typography>
            {data.paymentSuccessful ? (
              <ConfirmationNotice
                policyStartDate={data.coverStartDate}
                businessContactName={data.businessContactName}
              />
            ) : (
              <PaymentError />
            )}
            {data.paymentSuccessful ? <Divider className="divider2-my-1"></Divider> : <></>}
            <div className="stepBtnContainer">
              {data.paymentSuccessful ? (
                <ButtonPrimary onClick={handleGoToHomepage}>Back to Homepage</ButtonPrimary>
              ) : (
                <ButtonSecondary onClick={handleGoToPaymentPage}>Back to Payment Page</ButtonSecondary>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} />
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Confirmation;
