import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { useStyles } from './ButtonPrimaryStyles';
import clsx from 'clsx';

interface IButtonPrimaryProps extends ButtonProps {
  loading?: boolean;
}

export const ButtonPrimary: React.FC<IButtonPrimaryProps> = ({
  disabled,
  className,
  loading,
  children,
  ...rest
}: IButtonPrimaryProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.root, classes.dropShadow, classes.heading, className)}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && <CircularProgress className={classes.circularProgress} size={15} />}
      {children}
    </Button>
  );
};

export default ButtonPrimary;
