// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import theme from '../../../theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    datePicker: {
      backgroundColor: theme.palette.common.white,
      flexDirection: 'unset',
    },
  }),
);

export const datePickerTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: theme.palette.utility.greyDark,
        },
        '&:hover $notchedOutline': {
          borderColor: theme.palette.common.black,
        },
        '&$focused $notchedOutline': {
          boxShadow: `${theme.palette.common.black} 0 0 0 1px`,
          border: `1px solid ${theme.palette.utility.greyDark}`,
          borderColor: `${theme.palette.utility.focusBlue}`,
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: theme.palette.common.white,
      },
      toolbarBtnSelected: {
        color: theme.palette.common.white,
        fontSize: '1.5rem',
        fontWeight: 700,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.palette.green.main,
      },
      dayDisabled: {
        color: theme.palette.utility.primaryGrey,
      },
      current: {
        color: theme.palette.common.black,
      },
    },
  },
  palette: {
    primary: {
      main: theme.palette.green.main,
    },
  },
  shape: {
    borderRadius: 0,
  },
});
