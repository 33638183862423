import React from 'react';
import { Typography as MuiTypography, withStyles } from '@material-ui/core';
import { typographyStyles } from './TypographyStyles';
import clsx from 'clsx';

interface ITypography {
  variant?: any;
  classes?: any;
  className?: string;
  forwardedRef?: any;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  paragraph?: boolean;
  component?: any;
  gutterBottom?: boolean;
  noWrap?: boolean;
  props?: any;
}

export const Typography: React.FC<ITypography> = ({
  variant,
  classes,
  className,
  forwardedRef,
  align,
  paragraph,
  component,
  gutterBottom,
  noWrap,
  ...props
}: ITypography) => {
  const isCustomVariant = Object.keys(classes).indexOf(variant) > -1;
  return (
    <MuiTypography
      className={isCustomVariant ? clsx(classes[variant], className) : className}
      variant={isCustomVariant ? undefined : variant}
      ref={forwardedRef}
      align={align}
      paragraph={paragraph}
      component={component}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      {...props}
    />
  );
};

export default withStyles(typographyStyles)(Typography);
