import { makeStyles, Theme } from '@material-ui/core/';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    contactContainer: {
      display: 'flex',
      alignItems: 'baseline',
      '@media (max-width: 400px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    callUsContainer: {
      fontFamily: 'Oswald',
      marginRight: '1rem',
      '@media (max-width: 400px)': {
        marginRight: 0,
      },
    },
    callUsText: {
      marginTop: 0,
      fontSize: '1rem',
      fontFamily: 'Oswald',
      marginBottom: '0.25rem',
      textTransform: 'uppercase',
    },
    telephone: {
      color: theme.palette.green.main,
      textDecoration: 'none',
    },
    openingHoursContainer: {
      display: 'flex',
      margin: '0 1rem',
      '@media (max-width: 400px)': {
        marginTop: '2rem',
      },
    },
    openingHoursDateContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginRight: '0.4rem',
    },
    openingHoursDate: {
      fontFamily: 'Oswald',
      fontSize: '1rem',
      color: theme.palette.common.white,
      margin: '0 0 0.3rem 0',
      textTransform: 'uppercase',
    },
    openingHoursTimeContainer: {
      marginLeft: '0.1rem',
      paddingLeft: '0.75rem',
      borderLeft: '6px solid #262626',
    },
    openingHoursTime: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      fontFamily: 'Oswald',
      fontSize: '1rem',
      color: theme.palette.green.main,
      margin: '0 0 0.3rem 0',
      textTransform: 'uppercase',
    },
  }),
);
