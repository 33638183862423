import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './ButtonGenericStyles';
import clsx from 'clsx';

interface IButtonGenericProps extends ButtonProps {
  loading?: boolean;
}

export const ButtonGeneric: React.FC<IButtonGenericProps> = ({
  className,
  disabled,
  loading,
  children,
  ...rest
}: IButtonGenericProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, classes.body, classes.dropShadow, className)}
      disabled={disabled || loading}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonGeneric;
