import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    ...theme.layouts.stepGrid,
    divGrey: {
        borderBottom: `1px solid ${theme.palette.utility.greyDark}`,
        paddingTop: '0rem',
    },
    //For Text between the Dividers
    text: {   
        color: theme.palette.common.black, 
        paddingTop: '0.7rem',
        paddingBottom: '0.7rem',
        textTransform: 'uppercase'
    },
    textAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    textBody2: theme.bodyStyles.body5,
    // For Buttons
    buttonEdit: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
    },
    backBtn: {
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    //For Accordion
    accordionHeading: {
        fontSize: '1.5rem',
        fontFamily: 'Roboto',
    },
    accordionBody: {
        fontSize: '0.875rem',
    },
    accordionDisplay: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
