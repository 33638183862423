import React, { useContext, useState } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ContactSideBar,
  ButtonSecondary,
  ButtonPrimary,
  TextField,
  Checkbox,
  YourPrice,
  StateTracker,
  StateEditor,
  Typography,
} from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './BusinessDetailsStyles';
import { StepContext, IStepData, steps, Step } from '../../contexts/StepContext';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PostcodeLookup } from '../../components/molecules';
import yourPriceSchema from '../../components/atoms/YourPrice/YourPriceSchema.json';

export const BusinessDetails: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data } = useContext(StepContext);
  const [showAddressFields, setShowAddressFields] = useState(data?.address?.postcode ? true : false);
  const history = useHistory();
  const classes = useStyles();

  const schema = yup.object().shape({
    companyName: yup.string().required('Company name is a required field.'),
    address: yup.object().shape({
      firstLineOfAddress: yup.string().required('First line of address is a required field.'),
      town: yup.string().required('Town is a required field.'),
      postcode: yup.string().required('Postcode is a required field.'),
    }),
    businessContactName: yup.string().required('Name is required.'),
    businessContactSurname: yup.string().required('Surname is required.'),
    businessContactNumber: yup
      .number()
      .typeError('Telephone number should contain numbers only.')
      .required('Telephone is required.'),
    businessContactEmail: yup.string().email('Email is invalid.').required('Email is required.'),
    secondPointOfContact: yup.bool(),
    secondBusinessContactName: yup.string().when('secondPointOfContact', {
      is: (value: boolean) => value === true,
      then: yup.string().required('Name is required.'),
    }),
    secondBusinessContactSurname: yup.string().when('secondPointOfContact', {
      is: (value: boolean) => value === true,
      then: yup.string().required('Surname is required.'),
    }),
  });

  const { handleSubmit, setValue, getValues, watch, control, trigger, errors, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: data,
    shouldFocusError: false,
    shouldUnregister: false,
  });

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({ ...data, ...stepData });
    history.push(steps[Step.REVIEW].url);
  };

  const preventFormSubmission = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && e.preventDefault();

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({ ...data, ...getValues() });
    history.push(steps[Step.VEHICLE_DETAILS].url);
  };

  const handleShowPostcodeFields = (): void => setShowAddressFields(true);
  const address = watch('address');
  const secondPointOfContact = watch('secondPointOfContact');

  if (
    watch('secondPointOfContact') === false &&
    (getValues('secondBusinessContactName').length > 0 || getValues('secondBusinessContactSurname').length > 0)
  ) {
    reset({
      ...data,
      ...getValues(),
      secondBusinessContactName: '',
      secondBusinessContactSurname: '',
    });
  }

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title="DLG Fleet - Business Details" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.gridMainDiv}>
              <Typography className="stepHeading" variant="h1">
                STEP 4 - BUSINESS DETAILS
              </Typography>
              <Typography className="mb1" variant="h3">
                BUSINESS ADDRESS
              </Typography>
              <Divider className="divider-my-1"></Divider>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.postcodeLookupContainer} variant="h3">
                    COMPANY NAME
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.businessInfoField}
                    name="companyName"
                    control={control}
                    onKeyDown={preventFormSubmission}
                  />
                  {errors.companyName && <p className="error">{errors.companyName.message}</p>}
                </Grid>
                {showAddressFields === false ? (
                  <> </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.postcodeLookupContainer} variant="h3">
                        FIRST LINE OF ADDRESS
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.businessInfoField}
                        name="address.firstLineOfAddress"
                        control={control}
                        defaultValue={data.address.firstLineOfAddress}
                        onKeyDown={preventFormSubmission}
                      />
                      {errors.address?.firstLineOfAddress && (
                        <p className="error">{errors.address?.firstLineOfAddress.message}</p>
                      )}
                    </Grid>
                  </>
                )}
                {showAddressFields === false ? (
                  <> </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.postcodeLookupContainer} variant="h3">
                        SECOND LINE OF ADDRESS
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.businessInfoField}
                        name="address.secondLineOfAddress"
                        control={control}
                        defaultValue={data.address.secondLineOfAddress}
                        onKeyDown={preventFormSubmission}
                      />
                    </Grid>
                  </>
                )}
                {showAddressFields === false ? (
                  <> </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.postcodeLookupContainer} variant="h3">
                        TOWN
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.businessInfoField}
                        name="address.town"
                        control={control}
                        defaultValue={data.address.town}
                        onKeyDown={preventFormSubmission}
                      />
                      {errors.address?.town && <p className="error">{errors.address?.town.message}</p>}
                    </Grid>
                  </>
                )}
                {showAddressFields === false ? (
                  <> </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.postcodeLookupContainer} variant="h3">
                        COUNTY
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.businessInfoField}
                        name="address.county"
                        control={control}
                        defaultValue={data.address.county}
                        onKeyDown={preventFormSubmission}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                  <Typography variant="h3">POSTCODE</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    className={classes.businessInfoField}
                    name="address.postcode"
                    control={control}
                    onKeyDown={preventFormSubmission}
                  />
                  {errors.address?.postcode && <p className="error">{errors.address?.postcode.message}</p>}
                </Grid>
                <Grid item xs={6}>
                  {/*Material UI doesn't support column offsets. Manual input of blank column required.*/}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.lookupButton}>
                  <PostcodeLookup
                    onClick={handleShowPostcodeFields}
                    name="address"
                    setValue={setValue}
                    postcode={address.postcode as string}
                    trigger={trigger}
                  />
                </Grid>
              </Grid>
              <div>
                <Typography className="mb1 mt2" variant="h3">
                  BUSINESS POINT OF CONTACT
                </Typography>
                <Divider className="divider-my-1"></Divider>
                <Typography className="mb1">
                  This is the person that will be able to make amendments to the policy. There&apos;s the option to add
                  a second point of contact should you wish.
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                    <Typography variant="h3">NAME</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.businessInfoField}
                      name="businessContactName"
                      control={control}
                      defaultValue={data.businessContactName}
                      onKeyDown={preventFormSubmission}
                    />
                    {errors.businessContactName && <p className="error">{errors.businessContactName.message}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                    <Typography variant="h3">SURNAME</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.businessInfoField}
                      name="businessContactSurname"
                      control={control}
                      defaultValue={data.businessContactSurname}
                      onKeyDown={preventFormSubmission}
                    />
                    {errors.businessContactSurname && <p className="error">{errors.businessContactSurname.message}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                    <Typography variant="h3">TELEPHONE NUMBER</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.businessInfoField}
                      name="businessContactNumber"
                      control={control}
                      defaultValue={data.businessContactNumber}
                      onKeyDown={preventFormSubmission}
                    />
                    {errors.businessContactNumber && <p className="error">{errors.businessContactNumber.message}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                    <Typography variant="h3">EMAIL</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.businessInfoField}
                      name="businessContactEmail"
                      control={control}
                      defaultValue={data.businessContactEmail}
                      onKeyDown={preventFormSubmission}
                    />
                    {errors.businessContactEmail && <p className="error">{errors.businessContactEmail.message}</p>}
                  </Grid>
                  <Typography className="mb1 mt2">
                    We will only use your email address to contact you about changes to your policy.
                  </Typography>
                  <Grid container>
                    <Checkbox
                      onChange={() => alert('test')}
                      name="secondPointOfContact"
                      checkboxClassName={classes.checkbox}
                      control={control}
                      label="ADD ANOTHER PERSON"
                      color="primary"
                    />
                  </Grid>
                  {secondPointOfContact === true ? (
                    <>
                      <Grid item xs={12}>
                        <Typography className="mb1 mt2" variant="h3">
                          2ND BUSINESS POINT OF CONTACT
                        </Typography>
                        <Divider className="divider-my-1"></Divider>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                          <Typography variant="h3">NAME</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.businessInfoField}
                            name="secondBusinessContactName"
                            control={control}
                            defaultValue={data.secondBusinessContactName}
                            onKeyDown={preventFormSubmission}
                          />
                          {errors.secondBusinessContactName && (
                            <p className="error">{errors.secondBusinessContactName.message}</p>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.postcodeLookupContainer}>
                          <Typography variant="h3">SURNAME</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.businessInfoField}
                            name="secondBusinessContactSurname"
                            control={control}
                            defaultValue={data.secondBusinessContactSurname}
                            onKeyDown={preventFormSubmission}
                          />
                          {errors.secondBusinessContactSurname && (
                            <p className="error">{errors.secondBusinessContactSurname.message}</p>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
              <Divider className="divider2-my-1"></Divider>
              <div className="stepBtnContainer">
                <ButtonSecondary disabled={activeStep === 1} onClick={handleBack}>
                  Back
                </ButtonSecondary>
                <ButtonPrimary
                  disabled={!showAddressFields}
                  type="submit"
                >
                  Next
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} />
            <div className={classes.gridSidebarYourPriceContainer}>
              <YourPrice
                sticky={false}
                heading={yourPriceSchema.heading}
                cover={data.coverType}
                price={`£${data.quoteTotal.toFixed(2)}`}
                info={yourPriceSchema.info}
              />
            </div>
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default BusinessDetails;
