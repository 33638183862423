import React, { useContext, useEffect } from 'react';
import { useStyles } from './ReactErrorStyles';
import { Grid, Typography } from '@material-ui/core';
import { StepContext } from '../../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { ButtonPrimary } from '../../atoms';
import StorageIcon from '@material-ui/icons/Storage';

export const ReactError: React.FC = (): JSX.Element => {
    const classes = useStyles();

    const { updateActiveStep } = useContext(StepContext);
    const history = useHistory();

    useEffect(() => {
        updateActiveStep(1);
        history.push('/');
    }, []);

    const handleGoToHomepage = () => {
        window.location.reload();
    };

    return (
        <Grid container className={classes.textCenter}>
            <Grid item xs={12} className="py1">
                <StorageIcon />
            </Grid>
            <Grid item xs={12} className="pb1">
                <Typography variant="h2">
                    We&apos;re sorry.
                </Typography>
            </Grid>
            
            <Grid item xs={12} className="pb1">
                <Typography>
                    Something went wrong, this is not a problem with your computer or connection. Please reload your page.
                </Typography>
            </Grid>
            <Grid item xs={12} className="pb1">
                <Typography>
                    Or return to
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ButtonPrimary onClick={handleGoToHomepage}>Homepage</ButtonPrimary>
            </Grid>
        </Grid>
    );
};

export default ReactError;