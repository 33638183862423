import React from 'react';
import { useStyles } from './FooterStyles';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import greenFlagLogo from '../../../assets/img/logo/greenflag-icon-only.png';
import mastercardLogo from '../../../assets/img/logo/footer-logo-mastercard.png';
import visaSecureLogo from '../../../assets/img/logo/footer-logo-visa.png';
import clsx from 'clsx';

declare const OneTrust: any;
// OneTrust.ToggleInfoDisplay();
const gridBreakpoints = {
  gridFullWidthItem: {
    xs: 12 as const,
    sm: 11 as const,
    lg: 12 as const,
  },
  gridOffset: {
    xs: 12 as const,
    sm: 1 as const,
    lg: 12 as const,
  },
  gridCenter: {
    xs: 12 as const,
    sm: 10 as const,
    lg: 12 as const,
  },
};

const CopyrightBanner = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.copyrightBannerContainer}>
      <img className={classes.greenFlagLogo} src={greenFlagLogo} height="40px" alt="Green Flag " />
      <span className={clsx(classes.copyrightText, classes.body5)}>&copy; GreenFlag Limited {new Date().getFullYear()}</span>
    </div>
  );
};

const Notice = (): JSX.Element => {
  const classes = useStyles();
  return (
    <p className={clsx(classes.notice, classes.body5)}>
      Green Flag Limited is an Introducer Appointed Representative of U K Insurance Limited. U K Insurance Limited is
      authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the
      Prudential Regulation Authority. Green Flag Breakdown Cover is underwritten by U K Insurance Limited. Registered
      office: The Wharf, Neville Street, Leeds LS1 4AZ. Registered in England and Wales No.1179980. Calls may be
      recorded. Green Flag Limited 2018. All rights reserved. iPhone and iPod touch are trademarks of Apple Inc.
    </p>
  );
};

const Logos = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <img className={classes.mastercardLogo} height="40px" src={mastercardLogo} alt="Mastercard ID Check" />
      <img src={visaSecureLogo} height="40px" alt="Visa Secure" />
    </div>
  );
};

const CookiesNotice = (): JSX.Element => {
  const classes = useStyles();
  return (
    <ul className={classes.footerLinks}>
      <li><a className={classes.cookiesNotice} href="https://www.greenflag.com/cookies-notice?src=fleet">COOKIES NOTICE</a></li>
    </ul>
  );
};

const ManageCookiesBtn = (): JSX.Element => {
  const classes = useStyles();
  return (
    <button
      id="ot-sdk-btn"
      className={classes.manageCookiesBtn}
      onClick={() => OneTrust.ToggleInfoDisplay()}
      >
        MANAGE COOKIES
    </button>
  );
  
};

export const Footer: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const { gridFullWidthItem, gridOffset, gridCenter } = gridBreakpoints;

  return (
    <footer className={classes.root}>
      <Container className={classes.container}>
        <Grid
          className={classes.gridContainer}
          container
          alignItems="flex-start"
          justify="flex-start"
          direction="row"
        >
          <Grid item xs={gridOffset.xs} sm={gridOffset.sm} lg={gridOffset.lg} />
          <Grid item xs={gridFullWidthItem.xs} sm={gridFullWidthItem.sm} lg={gridFullWidthItem.lg}>
            <CopyrightBanner/>
          </Grid>

          <Grid item xs={gridOffset.xs} sm={gridOffset.sm} lg={gridOffset.lg} />
          <Grid item xs={gridCenter.xs} sm={gridCenter.sm} lg={gridCenter.lg}>
            <Notice/>
          </Grid>
          <Grid item xs={gridOffset.xs} sm={gridOffset.sm} lg={gridOffset.lg} />

          <Grid item xs={gridOffset.xs} sm={gridOffset.sm} lg={gridOffset.lg} />
          <Grid item xs={gridCenter.xs} sm={gridCenter.sm} lg={gridCenter.lg}>
            <div className={classes.logoAndCookiesNoticeContainer}>
              <Logos/>
              <ManageCookiesBtn/>
            </div>
          </Grid>
          <Grid item xs={gridOffset.xs} sm={gridOffset.sm} lg={gridOffset.lg}></Grid>

          <Grid item xs={gridOffset.xs} sm={gridOffset.sm} lg={gridOffset.lg} />
          <Grid item xs={gridFullWidthItem.xs} sm={gridFullWidthItem.sm} lg={gridFullWidthItem.lg}>
            <CookiesNotice/>
          </Grid>

        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
