import React from 'react';
import { useStyles } from './SummaryTotalStyles';
import { Grid } from '@material-ui/core';
import { Typography } from '../../atoms';
import clsx from 'clsx';

interface ISummaryTotal {
    price: string;
    coverType: string;
    vehicleCount: number;
    sticky?: true;
};

const SummaryTotal: React.FC<ISummaryTotal> = ({ price, coverType, vehicleCount, sticky }: ISummaryTotal): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={sticky === true ? classes.sticky : ''}>
            <Grid className={clsx(classes.paper, classes.dropShadow)}>
                <Typography className={clsx(classes.textUppercase, classes.textBlack)} variant="h2">your business breakdown cover</Typography>
                <Grid container className={classes.px05}>
                    <Grid item xs={6} lg={6}>
                        <Typography className={clsx(classes.px05, classes.textBlack)}>
                            {vehicleCount} x {coverType}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} className={clsx(classes.textBlack, classes.textRight)}>  
                        <Typography variant="h1">{price}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className={classes.borderTop}>
                        <Typography variant="h2" className={clsx(classes.textUppercase, classes.textBlack)}>your total annual payment</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.px1}>
                        <Typography className={clsx(classes.body1, classes.textBlack)}>Annual payment</Typography> 
                    </Grid>
                    <Grid item xs={6} className={classes.px1}>
                        <Typography variant="h1" className={clsx(classes.textBlack, classes.textRight)}>{price}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.px05}>
                        <Typography className={clsx(classes.body2, classes.textBlack)}>
                            Total per year
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={clsx(classes.body5, classes.textBlack)}>
                            Prices include insurance premium tax where applicable.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SummaryTotal;