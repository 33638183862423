import React from 'react';
import { useStyles } from './PaymentErrorStyles';
import { Typography } from '../../atoms';
import { Divider } from '@material-ui/core/';

const PaymentError: React.FC = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h2" className={classes.text}>
        PAYMENT ERROR
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="h4" className={classes.text}>
        THERE HAS BEEN AN ERROR PROCESSING YOUR PAYMENT
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export default PaymentError;
