import React from 'react';
import { useStyles, datePickerTheme } from './DatePickerStyles';
import { isFuture, isValid, format as DateFnsFormat } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-GB';
import { KeyboardDatePicker, MuiPickersUtilsProvider, DatePickerView } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { Controller, Control } from 'react-hook-form';
import clsx from 'clsx';

interface IDatePicker {
  style?: React.CSSProperties;
  id: string;
  name: string;
  label?: string;
  control: Control;
  placeholder?: string;
  autoOk?: boolean;
  format?: string;
  views?: Array<"year" | "date" | "month">;
  disableFuture?: boolean;
  className?: string;
  disablePast?: boolean;
  minDate?: Date|string,
  minDateMessage?: React.ReactNode,
  maxDate?: Date|string,
  maxDateMessage?: React.ReactNode,
  defaultValue?: Date|string|null,
  initialFocusedDate?: Date|string;
  openTo?: DatePickerView | undefined,
}

class DatePickerUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return DateFnsFormat(date, 'E, do MMMM');
  }
}

export const DatePicker: React.FC<IDatePicker> = ({
  style,
  id,
  name,
  label,
  control,
  placeholder,
  autoOk,
  format,
  views,
  disableFuture,
  className,
  disablePast,
  minDate,
  maxDate,
  defaultValue,
  initialFocusedDate,
  openTo,
}: IDatePicker): JSX.Element => {
  const classes = useStyles();

  const handleOnChange = (date: Date|number|null, name: string, onChange: (date?: Date|number|null) => void): void => {
    if (name === "coverStartDate" && date !== null) {
      if (isFuture(date)) {
        onChange(date);
      } else if (isValid(date)) {
        onChange();
      } else {
        onChange(date);
      }
    } else {
      onChange(date);
    }
  };

  return (
    <ThemeProvider theme={datePickerTheme}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => (
          <MuiPickersUtilsProvider utils={DatePickerUtils} locale={locale}>
            <KeyboardDatePicker
              views={views}
              disableFuture={disableFuture}
              disablePast={disablePast}
              minDate={minDate}
              maxDate={maxDate}
              style={style}
              className={clsx(classes.datePicker, className)}
              id={id}
              placeholder={placeholder}
              label={label}
              value={value}
              onChange={(date) => handleOnChange(date, name, onChange)}
              variant="inline"
              format={format}
              inputVariant="outlined"
              autoOk={autoOk}
              maxDateMessage={<></>} // Disabled as yup is being used for error messages
              minDateMessage={<></>} // Disabled as yup is being used for error messages
              invalidDateMessage={<></>} // Disabled as yup is being used for error messages
              initialFocusedDate={initialFocusedDate}
              openTo={openTo}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        )}
      />
    </ThemeProvider>
  );
};

DatePicker.defaultProps = {
  placeholder: 'DD/MM/YYYY',
  autoOk: true,
  format: "dd/MM/yyyy"
};

export default DatePicker;
