import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  paragraphSpacing: {
    marginBottom: '0.75rem',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  ul: {
    ...theme.bodyStyles.body5,
    paddingLeft: '1.5rem',
    '& li': {
      fontSize: '1rem',
    },
  },
  checkbox: {
    '& span': {
      '& svg': {
        width: '36px',
        height: '36px',
      },
    },
  },
  textBlue: {
    color: theme.palette.utility.focusBlue,
  },
}));
