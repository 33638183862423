import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './ButtonTertiaryStyles';
import clsx from 'clsx';

interface IButtonTertiaryProps extends ButtonProps {
  loading?: boolean;
}

export const ButtonTertiary: React.FC<IButtonTertiaryProps> = ({
  className,
  disabled,
  loading,
  children,
  ...rest
}: IButtonTertiaryProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Button className={clsx(classes.root, classes.heading, classes.dropShadow, className)} disabled={disabled || loading} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonTertiary;
