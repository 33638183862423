import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: '1rem'
  },
  emailFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem'
  },
  emailTextField: {
    textTransform: 'uppercase'
  },
  recaptchaContainer: {
    marginTop: '1rem'
  },
  emailMeThisQuoteBtnContainer: {
    marginTop: '1rem',
  },
}));
