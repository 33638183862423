import React, { useEffect, useState } from 'react';
import { useStyles } from './VehicleCostCalculatorStyles';
import CheckIcon from '@material-ui/icons/Check';
import { Grid, Container } from '@material-ui/core';
import { Typography, TextField } from '../../atoms';
import { Control, Controller } from 'react-hook-form';
import axios from 'axios';
import clsx from 'clsx';

interface ICoverPrice {
  MinVehicles: number;
  MaxVehicles: number;
  Price: number;
  Name: string;
};

export interface IOption {
  coverType: string;
  coverDescription: Array<string>;
  option_id: number;
  site_id: string;
  coverPrice: Array<ICoverPrice>;
};

export interface IVehicleCostCalculator {
  name: string;
  control: Control;
  coverType: 'Standard' | 'National' | 'National + European';
  watch: any;
  setValue: (key: string, data: any, options?: any) => void;
  trigger: (key?: string) => void;
  queryApiOnLoad?: boolean;
  vehicleCostCalculatorData?: Array<IOption>
};

interface ICalculateCostTotal {
  id: string|null,
  cost: number
}

export const calculateCostTotal = (vehicleObject: IOption, vehicleCount: number): ICalculateCostTotal => {
  // compares user input value against json to make the calculation for the total price
  const filteredResult = vehicleObject.coverPrice.find(
    (v: ICoverPrice) => vehicleCount >= v.MinVehicles && vehicleCount <= v.MaxVehicles,
  );

  const result =
    filteredResult === undefined && vehicleCount <= 0
      ? 0
      : filteredResult === undefined && vehicleCount >= vehicleObject.coverPrice[vehicleObject.coverPrice.length - 1].MaxVehicles
      ? vehicleObject.coverPrice[vehicleObject.coverPrice.length - 1].Price
      : filteredResult?.Price;

  return {
    id: filteredResult?.Name ? filteredResult.Name : null,
    cost: result === undefined ? 0 : result * vehicleCount,
  };
};


export const VehicleCostCalculator: React.FC<IVehicleCostCalculator> = ({
  coverType,
  name,
  control,
  watch,
  setValue,
  trigger,
  queryApiOnLoad,
  vehicleCostCalculatorData,
}): JSX.Element => {
  const [data, setData] = useState<Array<IOption>>(vehicleCostCalculatorData ? vehicleCostCalculatorData : []);
  const vehicleCount = watch('vehicleCount');
  const quoteTotal = watch('quoteTotal');
  const vehicleId = watch('vehicleId');
  const classes = useStyles();

  useEffect(() => {
    if (queryApiOnLoad) getData();
  }, []);

  const getData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`);
    setData(data);
  };

  useEffect(() => {
    if (data.length > 0) {
      const vehicleObject = data.find((d: IOption) => d.coverType === coverType);
      if (vehicleObject !== undefined) {
        const result = calculateCostTotal(vehicleObject, vehicleCount);
        setValue('vehicleId', result.id);
        setValue('quoteTotal', result.cost);
        trigger('vehicleId');
        trigger('quoteTotal');
      }
    }
  }, [vehicleCount, data, trigger, coverType]);

  if (data.length === 0) {
    return <></>;
  } else {
    const vehicleObject = data.find((d: IOption) => d.coverType === coverType);

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key == 'Delete' || event.key == 'Backspace' ) {
        // allow deletes - Firefox issue
      } else {
          const pattern = /[0-9\s]/;
          const inputChar = String.fromCharCode(event.charCode);

          if (!pattern.test(inputChar)) {
              // invalid character, prevent input
              event.preventDefault();
          }

          if ((event.target as HTMLInputElement).value.length > 2) {
              // too many characters
              event.preventDefault();
          }
      }
    };

    return (
      <Container className={classes.paper}>
        <Controller name="quoteTotal" defaultValue={quoteTotal} control={control} as={<></>} />
        <Controller name="vehicleId" defaultValue={vehicleId} control={control} as={<></>} />
        <Grid container>
          <Grid item xs={12} md={8}>
            <div className="pb1">
              <Typography variant="h3">
                Number of vehicles
              </Typography>
            </div>
            <Grid container className={classes.babyPaper}>
              <Grid item xs={12} sm={8} className="mAuto">
                <div className={classes.vehicleCountContainer}>
                  <TextField
                    className={classes.inputField}
                    name={name}
                    id={name}
                    control={control}
                    defaultValue={vehicleCount}
                    type="number"
                    inputProps={{ min: 0, step: 1, max: 999 }}
                    onKeyPress={handleOnKeyPress}
                  />
                  <Typography className={classes.coverType} variant="h3">
                    {coverType}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.priceText}>
                <Typography className={classes.rogue2Mobile}>£{quoteTotal.toFixed(2)}</Typography>
              </Grid>
            </Grid>

            {/* end of paper */}
            <Grid container className="pt1">
              {vehicleObject !== undefined &&
                vehicleObject.coverDescription.map((coverDescription: string, index: number) => {
                  return (
                    <div className={classes.checkList} key={index}>
                      <CheckIcon className={classes.checkIcon} />
                      <Typography className={`${classes.body3} pl1`}>{coverDescription}</Typography>
                    </div>
                  );
                })}
            </Grid>
          </Grid>

          {/* your total right hand section */}
          <Grid item xs={12} md={4} className={classes.yourTotalClasses}>
            <Grid className={classes.textAlignRightDesktop}>
              <div>
                <Typography variant="h3">Your total</Typography>
              </div>
            </Grid>
            <Grid className={classes.textAlignRightDesktop}>
              <div>
                <Typography className={classes.rogue1}>£{quoteTotal.toFixed(2)}</Typography>
              </div>
            </Grid>
            <Grid>
              <Typography className={clsx(classes.body5, classes.textAlignRightDesktop)}>
                Prices include insurance premium tax where applicable.
              </Typography>
              <Typography className={clsx(classes.body5, classes.textAlignRightDesktop)}>
                We don&apos;t provide advice, so please read through the information to help you make your choice.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

VehicleCostCalculator.defaultProps = {
  queryApiOnLoad: true
};

export default VehicleCostCalculator;
