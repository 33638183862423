import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpIcon: {
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.utility.focusBlue,
      },
    },
    infoIcon: {
      color: theme.palette.utility.focusBlue,
      '&:hover': {
        cursor: 'pointer'
      },
    },
  }),
);