import React, { useEffect, useState, useContext, useRef } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './QuoteStyles';
import { Grid, Divider, ButtonBase } from '@material-ui/core';
import {
  StepContext,
  IStepData,
  quoteData,
  initialNavStepperData,
  steps,
  Step,
} from '../../contexts/StepContext';
import {
  DocumentTitle,
  Typography,
  ContactSideBar,
  StateTracker,
  StateEditor,
  RadioButtonGroup,
  DatePicker,
  ButtonPrimary,
} from '../../components/atoms';
import { Modal } from '../../components/molecules';
import { VehicleCostCalculator, IOption, IVehicleCostCalculator } from '../../components/organisms';
import { ContactModalBody } from './ContactModalBody/ContactModalBody';
import { EmailMeThisQuoteModalBody } from './EmailMeThisQuoteModalBody/EmailMeThisQuoteModalBody';
import { EmailMeThisQuoteConfirmationModalBody } from './EmailMeThisQuoteConfirmationModalBody/EmailMeThisQuoteConfirmationModalBody';
import { OverTwentyFiveCarsModalBody } from './OverTwentyFiveCarsModalBody/OverTwentyFiveCarsModalBody';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format, addDays, isFuture } from 'date-fns';
import {
  vehicleAmountData,
  sameLevelOfCoverData,
  breakDownCoverLocationData,
  vehicleRepairLocationData,
} from './QuoteSchema.json';
import axios from 'axios';

interface IParams {
  id: string;
}

export const Quote: React.FC = () => {
  const { activeStep, updateActiveStep, loading, updateLoading, updateQuotes, updateData, data } = useContext(
    StepContext,
  );
  const [vehicleCostCalculatorData, setVehicleCostCalculatorData] = useState<Array<IOption>>(
    data.coverOptions ? data.coverOptions : [],
  );
  const [vehicleAmountModalActive, setVehicleAmountModalActive] = useState(false);
  const [vehicleSameLevelOfCoverModalActive, setVehicleSameLevelOfCoverModalActive] = useState(false);
  const [emailMeThisQuoteModalActive, setEmailMeThisQuoteModalActive] = useState(false);
  const [emailMeThisQuiteConfirmationModalActive, setEmailMeThisQuoteConfirmationModalActive] = useState(false);
  const [overTwentyFiveCarsModalActive, setOverTwentyFiveCarsModalActive] = useState(false);
  const [overTwentyFiveCarsContinueData, setOverTwentyFiveCarsContinueData] = useState({ ...data }); // contains stepData.
  const [emailAddress, setEmailAddress] = useState('');
  const { id } = useParams<IParams>();
  const tomorrowsDate = format(addDays(new Date(), 1), 'yyyy/MM/dd');

  useEffect(() => {
    // Look up quote id if one is provided.
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_QUOTE_GET_ENDPOINT}/${id}`,
        );
        const data = {
          ...initialNavStepperData,
          ...response.data,
          coverStartDate: isFuture(new Date(response.data.coverStartDate)) ? response.data.coverStartDate : null,
        };
        updateData(data);
        reset(data);
        updateLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    id ? getData() : updateLoading(false);
  }, []);

  // Get data for vehicle cost calculator
  useEffect(() => {
    const getVehicleCostCalculatorData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`,
      );
      updateQuotes(data);
      setVehicleCostCalculatorData(data);
    };

    if (vehicleCostCalculatorData.length === 0) getVehicleCostCalculatorData();
  }, []);

  const breakdownCoverRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const classes = useStyles();

  const schema = yup.object().shape({
    moreThanFiftyVehicles: yup.string().required(),
    sameLevelOfCover: yup.string().required(),
    breakdownCoverLocation: yup.string().required(),
    vehicleRepairLocation: yup.string().optional(),
    coverType: yup.string().required(),
    vehicleCount: yup.number().required().min(1).max(999),
    quoteTotal: yup.number().required().min(1),
    vehicleId: yup.string().nullable(),
    coverStartDate: yup.date().required().min(tomorrowsDate).nullable(),
  });

  const { handleSubmit, control, watch, reset, setValue, formState, trigger, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: data,
    shouldFocusError: true,
    shouldUnregister: false, // Maintain values of hidden vehicle cost calculator component
  });

  const moreThanFiftyVehicles = watch('moreThanFiftyVehicles');
  const sameLevelOfCover = watch('sameLevelOfCover');
  const breakdownCoverLocation = watch('breakdownCoverLocation');
  const coverType = watch('coverType');

  const onSubmit = (stepData: IStepData) => {
    const vehicleCount = getValues('vehicleCount');
    let newVehiclesArray = [...data.vehicles];
    let reactIndex =
      data.vehicles.length > 0
        ? data.vehicles.reduce((p, c) => (p.reactIndex > c.reactIndex ? p : c)).reactIndex + 1
        : newVehiclesArray.length + 1;

    if (vehicleCount !== data.vehicleCount && vehicleCount !== undefined) {
      // Add new vehicle items
      if (vehicleCount > data.vehicleCount) {
        for (let i = data.vehicleCount; i < vehicleCount; i++) {
          newVehiclesArray.push({
            reactIndex: reactIndex,
            reg: '',
            make: '',
            model: '',
            transmissionType: '',
            fuelType: '',
            year: null,
            apiNoResult: null,
            complete: false,
          });
          reactIndex++;
        }
      } else if (vehicleCount < data.vehicleCount) {
        newVehiclesArray = data.vehicles.sort((a, b) => a.reactIndex - b.reactIndex).slice(0, vehicleCount);
      }
    }

    if (vehicleCount > 25 && vehicleCount <= 50) {
      setOverTwentyFiveCarsContinueData({ ...stepData, vehicles: newVehiclesArray });
      setOverTwentyFiveCarsModalActive(true);
    } else if (vehicleCount > 50) {
      setVehicleAmountModalActive(true);
    } else {
      updateActiveStep(activeStep + 1);
      updateData({
        ...data,
        ...stepData,
        coverOptions: data.coverOptions,
        vehicles: newVehiclesArray,
        coverStartDate:
          stepData.coverStartDate !== null
            ? format(new Date(stepData.coverStartDate), 'yyyy/MM/dd')
            : stepData.coverStartDate,
      });
      history.push(steps[Step.ASSUMPTIONS].url);
    }
  };

  const handleMoreThanFiftyVehiclesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === 'true' && vehicleAmountModalActive === false) {
      setVehicleAmountModalActive(true);
      reset(quoteData);
    }
  };

  const handleVehicleSameLevelOfCoverChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === 'false' && vehicleSameLevelOfCoverModalActive === false) {
      setVehicleSameLevelOfCoverModalActive(true);
      reset({ ...quoteData, moreThanFiftyVehicles });
    }
  };

  const handleVehicleAmountModalClose = (): void => {
    setVehicleAmountModalActive(false);
    setValue('moreThanFiftyVehicles', false, { shouldValidate: true });
  };

  const handleVehicleSameLevelOfCoverModalClose = (): void => {
    setVehicleSameLevelOfCoverModalActive(false);
    [
      { name: 'sameLevelOfCover', value: '' },
      { name: 'coverType', value: '' },
    ].forEach(({ name, value }) => setValue(name, value, { shouldValidate: true }));
  };

  const handleCoverTypeChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const handleFormChange = new Promise<void>((res) => {
      if (e.target.name === 'breakdownCoverLocation') {
        // Reset value
        if (e.target.value === 'THE UK') {
          setValue('coverType', '', { shouldValidate: true });
          trigger('coverType').then(() => {
            [
              { name: 'vehicleRepairLocation', value: '' },
              { name: 'breakdownCoverLocation', value: 'THE UK' },
            ].forEach(({ name, value }) => setValue(name, value, { shouldValidate: true }));
          });
        }
        // National + European
        else if (e.target.value === 'THE UK AND EUROPE') {
          setValue('coverType', 'National + European', { shouldValidate: true });
        }
      } else if (e.target.name === 'vehicleRepairLocation') {
        // Standard
        if (breakdownCoverLocation === 'THE UK' && e.target.value === 'THE NEAREST GARAGE') {
          setValue('coverType', 'Standard', { shouldValidate: true });
        }
        // National
        else if (breakdownCoverLocation === 'THE UK' && e.target.value === 'ANYWHERE IN THE UK') {
          setValue('coverType', 'National', { shouldValidate: true });
        }
      }
      res();
    });
    await handleFormChange;
    trigger();
  };

  if (loading) return <></>;

  return (
    <PageTemplate>
      <DocumentTitle title="DLG Fleet" />
      <Modal
        open={emailMeThisQuiteConfirmationModalActive}
        setOpen={setEmailMeThisQuoteConfirmationModalActive}
        componentType="fragment"
        heading="THANKS"
        disableBackdropClick={true}
        body={
          <EmailMeThisQuoteConfirmationModalBody
            setEmailMeThisQuoteConfirmationModalActive={setEmailMeThisQuoteConfirmationModalActive}
          />
        }
      />
      <Modal
        heading="You may want to call us."
        headingClassName={classes.contactModalHeading}
        open={overTwentyFiveCarsModalActive}
        setOpen={setOverTwentyFiveCarsModalActive}
        componentType="fragment"
        disableBackdropClick={true}
        body={
          <OverTwentyFiveCarsModalBody
            setOverTwentyFiveCarsModalActive={setOverTwentyFiveCarsModalActive}
            overTwentyFiveCarsContinueData={overTwentyFiveCarsContinueData}
            data={data}
          />
        }
      />
      <Modal
        heading="If you need different levels of cover for your vehicles, please call us to arrange your cover."
        headingClassName={classes.contactModalHeading}
        open={vehicleSameLevelOfCoverModalActive}
        setOpen={setVehicleSameLevelOfCoverModalActive}
        onClose={handleVehicleSameLevelOfCoverModalClose}
        componentType="fragment"
        body={<ContactModalBody />}
      />
      <Modal
        heading="If you have 51 or more vehicles, please call us to arrange your breakdown cover."
        headingClassName={classes.contactModalHeading}
        open={vehicleAmountModalActive}
        setOpen={setVehicleAmountModalActive}
        onClose={handleVehicleAmountModalClose}
        componentType="fragment"
        body={<ContactModalBody />}
      />
      <Controller control={control} name="coverType" defaultValue={data.coverType} as={<></>} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.gridMainDiv}>
              <Typography className="stepHeading" variant="h1">
                STEP 1 - YOUR QUOTE
              </Typography>
              <Typography className={classes.subTitle} variant="h2">
                FINDING YOUR RIGHT LEVEL OF COVER
              </Typography>
              <Divider className="divider-mb-1"></Divider>
              <Typography className={classes.question} forwardedRef={breakdownCoverRef}>
                How many vehicles do you want to cover?
              </Typography>
              <RadioButtonGroup
                name="moreThanFiftyVehicles"
                control={control}
                defaultValue={data.moreThanFiftyVehicles}
                className={classes.radioBtnGroup}
                data={vehicleAmountData}
                onChange={handleMoreThanFiftyVehiclesChange}
              />
              {moreThanFiftyVehicles === 'false' ? (
                <>
                  <Typography className={classes.question}>
                    Do all of your vehicles need the same level of cover?
                  </Typography>
                  <RadioButtonGroup
                    name="sameLevelOfCover"
                    control={control}
                    defaultValue={data.sameLevelOfCover}
                    className={classes.radioBtnGroup}
                    data={sameLevelOfCoverData}
                    onChange={handleVehicleSameLevelOfCoverChange}
                  />
                </>
              ) : (
                <></>
              )}
              {sameLevelOfCover === 'true' ? (
                <div>
                  <Typography className={classes.question}>Where do you need breakdown cover?</Typography>
                  <RadioButtonGroup
                    name="breakdownCoverLocation"
                    control={control}
                    defaultValue={data.breakdownCoverLocation}
                    className={classes.radioBtnGroup}
                    data={breakDownCoverLocationData}
                    onChange={handleCoverTypeChange}
                  />
                </div>
              ) : (
                <></>
              )}
              {breakdownCoverLocation === 'THE UK' ? (
                <>
                  <Typography className={classes.question}>
                    We&apos;ll always try to fix your vehicle at the roadside. If we can&apos;t, do you want us to take
                    it to the nearest garage, or anywhere in the UK? The nearest garage will be within 10 miles of your
                    breakdown.
                  </Typography>
                  <RadioButtonGroup
                    name="vehicleRepairLocation"
                    control={control}
                    defaultValue={data.vehicleRepairLocation}
                    className={classes.radioBtnGroup}
                    data={vehicleRepairLocationData}
                    onChange={handleCoverTypeChange}
                  />
                </>
              ) : (
                <></>
              )}
              {coverType !== '' ? (
                <>
                  <Typography className={classes.yourQuoteHeading} variant="h2">
                    YOUR QUOTE
                  </Typography>
                  <Divider className="divider-mb-1"></Divider>
                  <Typography className={classes.coverLevelNotice}>
                    The cover level that best suits your needs is <span className="bold">{coverType}</span>.
                  </Typography>
                  <Typography>
                    To see the total price for your fleet, please tell us how many vehicles you want to cover.
                  </Typography>
                  <div className={classes.vehicleCostContainer}>
                    {vehicleCostCalculatorData.length === 0 ? (
                      <></>
                    ) : (
                      <VehicleCostCalculator
                        name="vehicleCount"
                        control={control}
                        coverType={coverType as IVehicleCostCalculator['coverType']}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        queryApiOnLoad={false}
                        vehicleCostCalculatorData={vehicleCostCalculatorData}
                      />
                    )}
                  </div>
                  <Typography className={classes.coverLevelConfirmNotice}>
                    Not sure if this is the right cover for you?{' '}
                    <ButtonBase
                      onClick={() => breakdownCoverRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })}
                      className={classes.editAnswers}
                      aria-label="Edit your answers button"
                    >
                      EDIT YOUR ANSWERS
                    </ButtonBase>
                  </Typography>
                  <Typography className={classes.coverStartNotice}>When would you like your cover to start?</Typography>
                  <div className={classes.coverStartDate}>
                    <DatePicker
                      id="coverStartDate"
                      name="coverStartDate"
                      control={control}
                      defaultValue={data.coverStartDate}
                      disablePast={true}
                      minDate={tomorrowsDate}
                      initialFocusedDate={tomorrowsDate}
                    />
                  </div>
                  <Divider className="divider2-mb-1"></Divider>
                  <div className={classes.actionButtons}>
                    <Modal
                      open={emailMeThisQuoteModalActive}
                      setOpen={setEmailMeThisQuoteModalActive}
                      componentType="button"
                      buttonType="secondary"
                      buttonDisabled={!formState.isValid}
                      buttonLabel="Email me this quote"
                      buttonClassName={classes.emailMeThisQuoteBtn}
                      heading="EMAIL ME THIS QUOTE"
                      disableBackdropClick={true}
                      body={
                        <EmailMeThisQuoteModalBody
                          formData={getValues()}
                          emailAddress={emailAddress}
                          setEmailAddress={setEmailAddress}
                          setEmailMeThisQuoteModalActive={setEmailMeThisQuoteModalActive}
                          setEmailMeThisQuoteConfirmationModalActive={setEmailMeThisQuoteConfirmationModalActive}
                        />
                      }
                    />
                    <ButtonPrimary disabled={!formState.isValid} type="submit">NEXT</ButtonPrimary>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} />
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Quote;
