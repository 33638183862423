import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1280px',
    padding: '1.5rem 1rem 0rem 1rem',
  },
  greenFlagLogoGridContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
    '@media (max-width: 400px)': {
      justifyContent: 'center',
    },
    '& img': {
      '@media (max-width: 400px)': {
        width: '180px',
      },
    },
    '@media (max-width: 320px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        marginBottom: '1rem',
        '@media (max-width: 400px)': {
          marginBottom: 0,
        },
      },
    },
  },
  contactGridContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '1.5rem',
    },
  },
  helpButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dNoneMobile: {
    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },
  dNoneDesktop: {
    '@media (min-width: 1280px)': {
      display: 'none',
      margin: '1rem 0',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dOnlyMobile: {
    '@media (min-width: 426px)': {
      display: 'none',
    },
  },
}), { index: 1 });
