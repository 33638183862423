import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 0,
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    paddingRight: '1rem',
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.utility.greyDark}`,
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.common.black}`,
    },
    '&:active': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.utility.greyDark}`,
      backgroundColor: theme.palette.utility.greyLight,
    },
  },
  inputLabel: {
    marginBottom: '0.4rem',
  },
  body: theme.bodyStyles.body4
}), { index: 1 });
