import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './ButtonSecondaryStyles';
import clsx from 'clsx';

interface IButtonSecondaryProps extends ButtonProps {
  loading?: boolean;
}

export const ButtonSecondary: React.FC<IButtonSecondaryProps> = ({
  className,
  disabled,
  loading,
  children,
  ...rest
}: IButtonSecondaryProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Button className={clsx(classes.root, classes.dropShadow, classes.heading, className)} disabled={disabled || loading} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonSecondary;
