import React from 'react';
import { useStyles } from './VehicleDuplicateModalStyles';
import { Typography, ButtonSecondary } from '../../../atoms';
import { Modal } from '../../../molecules';

interface IVehicleDuplicateModal {
  showDuplicateRegModal: boolean;
  setShowDuplicateRegModal: (data: boolean) => void;
}

export const VehicleDuplicateModal: React.FC<IVehicleDuplicateModal> = ({
  showDuplicateRegModal,
  setShowDuplicateRegModal,
}: IVehicleDuplicateModal): JSX.Element => {
  const classes = useStyles();
  const handleClose = () => setShowDuplicateRegModal(false);

  const content = (
    <div>
      <Typography className={classes.body}>
        You have entered a duplicate registration number. You can only have one cover level type for each vehicle.
      </Typography>
      <ButtonSecondary onClick={handleClose}>CANCEL</ButtonSecondary>
    </div>
  );

  return (
    <Modal
      open={showDuplicateRegModal}
      setOpen={setShowDuplicateRegModal}
      heading="DUPLICATE REG NUMBER"
      body={content}
      componentType="fragment"
    />
  );
};

export default VehicleDuplicateModal;
