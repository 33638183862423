export const LOADING = "LOADING";
export const UPDATE_QUOTES = "UPDATE_QUOTES";
export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP';
export const UPDATE_VEHICLE_DETAILS = "UPDATE_VEHICLE_DETAILS";
export const UPDATE_DATA = 'UPDATE_DATA';

export type Action = {
    type: typeof LOADING |
          typeof UPDATE_QUOTES |
          typeof UPDATE_ACTIVE_STEP |
          typeof UPDATE_VEHICLE_DETAILS |
          typeof UPDATE_DATA;
    payload?: any;
};