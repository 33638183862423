import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...theme.typography.button,
      paddingLeft: '2rem',
      paddingRight: '2rem',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.utility.greyDark,
      border: '1px solid transparent',
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.utility.greyDark}`,
      '&:hover': {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.common.black}`,
      },
      '&:active': {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.utility.greyDark}`,
        backgroundColor: theme.palette.utility.greyLight,
      },
      '&:focus': {
        border: `1px solid ${theme.palette.common.black}`,
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineColor: `${theme.palette.utility.focusBlue}`,
        outlineOffset: '-3px',
      },
    },
    body: {
      fontSize: '1rem',
      fontFamily: theme.bodyStyles.body4.fontFamily,
      fontWeight: theme.bodyStyles.body4.fontWeight
    },
    dropShadow: theme.shadowStyles.masterDropShadow,
  }),
);
