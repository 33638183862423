import React from 'react';
import { Typography, ButtonSecondary } from '../../../../components/atoms';
import { Divider } from '@material-ui/core/';
import { useStyles } from '../../ReviewStyles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

interface IBusinessDetails {
    onClick: () => void,
    data: any,
}

const BusinessDetails: React.FC<IBusinessDetails> = ({ onClick, data }: IBusinessDetails) => {
    const classes = useStyles();

    return (
        <div>
            <div>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h3">
                            BUSINESS DETAILS
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.backBtn}>
                        <Typography variant="body2" className={classes.text}>
                            <ButtonSecondary onClick={onClick} className={clsx(classes.buttonEdit)}>EDIT</ButtonSecondary>
                        </Typography>
                    </Grid>
                </Grid>  
                <Divider className="divider"/>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            POLICY HOLDER
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {data.companyName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            ADDRESS
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {data.address?.firstLineOfAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {data.address?.secondLineOfAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {data.address?.town}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {data.address?.county}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            POSTCODE
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {data.address?.postcode}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default BusinessDetails;