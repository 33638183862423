import React from 'react';
import { Typography } from '../../atoms';
import { Grid, Container } from '@material-ui/core';
import { useStyles } from './ConfirmationSideBarStyles';
import clsx from 'clsx';

interface IConfirmationSideBar {
    heading: string,
    desc1: string,
    desc2: string,
}

export const ConfirmationSideBar: React.FC<IConfirmationSideBar> = ({ heading, desc1, desc2 }: IConfirmationSideBar) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Container className={classes.paper}>
                <div className={classes.textWhite}>
                    <Typography className={classes.uppercase} variant="h1">{heading}</Typography>
                    <Typography className={clsx(classes.body5, classes.px1)}>{desc1}</Typography>
                    <Typography className={classes.body5}>{desc2}</Typography>
                </div>
            </Container>
        </Grid>
    );
};

export default ConfirmationSideBar;