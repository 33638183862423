import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.button,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    border: '1px solid transparent',
    backgroundColor: theme.palette.error.main,
    fontWeight: 700,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.utility.errorOver,
    },
    '&:active': {
      backgroundColor: theme.palette.utility.errorHit,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.common.black}`,
      outlineStyle: 'solid',
      outlineWidth: '2px',
      outlineColor: `${theme.palette.utility.focusBlue}`,
      outlineOffset: '-3px',
    }
  },
  heading: {
    fontWeight: theme.typography.h3.fontWeight,
    fontSize: '1rem',
  },
  largeHeading: theme.typography.h3,
  dropShadow: theme.shadowStyles.masterDropShadow
}));