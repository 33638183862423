import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '1rem',
  },
  babyPaper: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: '1rem',
  },
  yourTotalClasses: {
    padding: '0 1rem 0rem 1rem',
    '@media (max-width: 960px)': {
      paddingTop: '1rem',
    },
  },
  textLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  priceText: {
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (min-width: 600px)': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  justifyLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  textAlignRightDesktop: {
    '@media (min-width: 768px)': {
      textAlign: 'right',
    },
  },
  margin: {
    marginLeft: 'auto',
    right: '0',
  },
  vehicleCountContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& h2': {
        margin: '0.5rem 0 0.25rem 0',
      },
    },
  },
  checkList: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.5rem',
  },
  inputField: {
    width: '80px',
  },
  checkIcon: {
    color: theme.palette.green.main,
  },
  coverType: {
    textTransform: 'uppercase',
    '@media (max-width: 600px)': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    '@media (min-width: 602px)': {
      marginLeft: '1rem',
    },
  },
  body5: {
    ...theme.bodyStyles.body5,
    marginTop: '1rem',
  },
  body3: {
    ...theme.bodyStyles.body3,
    fontWeight: 300
  },
  rogue1: {
    ...theme.rogueStyles.rogue1,
    fontSize: '2.8rem',
    marginTop: '1rem',
  },
  rogue2Mobile: theme.rogueStyles.rogue2Mobile,
}));
