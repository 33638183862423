import React from 'react';
import { Modal as MaterialUIModal, IconButton } from '@material-ui/core';
import { useStyles } from './ModalStyles';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary, ButtonGeneric, Typography } from '../../atoms';
import ModalIcon from './ModalIcon.component';

export interface IModal {
  open: boolean;
  setOpen: any;
  onClose?: () => void;
  heading?: string;
  headingClassName?: string;
  modalContainerClassName?: string;
  body: JSX.Element | string;
  componentType?: 'button' | 'icon' | 'fragment';
  iconType?: 'info' | 'question';
  buttonLabel?: string;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'generic';
  buttonDisabled?: boolean;
  buttonClassName?: string;
  disableBackdropClick?: boolean;
}

interface IMuiModal {
  open: boolean;
  onClose: (() => void) | undefined;
  handleDefaultClose: (() => void) | undefined;
  disableBackdropClick: boolean | undefined;
}

const Modal: React.FC<IModal> = ({
  open,
  setOpen,
  onClose,
  heading,
  headingClassName,
  modalContainerClassName,
  body,
  componentType,
  buttonLabel,
  buttonType,
  buttonDisabled,
  buttonClassName,
  disableBackdropClick,
  iconType,
}) => {
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleDefaultClose = () => setOpen(false);

  const getButton = (
    buttonDisabled: boolean,
    buttonType: 'primary' | 'secondary' | 'tertiary' | 'generic',
    buttonLabel: string,
    buttonClassName: string,
    handleOpen: () => void,
  ) => {
    switch (buttonType) {
      case 'primary':
        return (
          <ButtonPrimary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonPrimary>
        );
      case 'secondary':
        return (
          <ButtonSecondary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonSecondary>
        );
      case 'tertiary':
        return (
          <ButtonTertiary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonTertiary>
        );
      case 'generic':
        return (
          <ButtonGeneric disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonGeneric>
        );
    }
  };

  const content = (
    <div className={modalContainerClassName}>
      <div className={classes.headingContainer}>
        <Typography className={headingClassName ? headingClassName : classes.headingText} variant="h3">
          {heading}
        </Typography>
        <IconButton
          className={classes.iconButton}
          aria-label="Close"
          onClick={onClose !== undefined ? onClose : handleDefaultClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.bodyText}>{body}</div>
    </div>
  );

  const MuiModal = ({ open, onClose, handleDefaultClose }: IMuiModal) => {
    const classes = useStyles();
    return (
      <div className={modalContainerClassName}>
        <MaterialUIModal
          open={open}
          onClose={onClose !== undefined ? onClose : handleDefaultClose}
          className={classes.modal}
          disableBackdropClick={disableBackdropClick}
        >
          <div className={classes.paper}>{content}</div>
        </MaterialUIModal>
      </div>
    );
  };

  if (
    componentType === 'button' &&
    buttonDisabled !== undefined &&
    buttonType !== undefined &&
    buttonLabel !== undefined &&
    buttonClassName !== undefined
  ) {
    const button = getButton(buttonDisabled, buttonType, buttonLabel, buttonClassName, handleOpen);

    return (
      <div className={modalContainerClassName}>
        {button}
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  } else if (componentType === 'icon' && iconType !== undefined) {
    return (
      <div className={modalContainerClassName}>
        <ModalIcon handleOpen={handleOpen} iconType={iconType} />
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  } else {
    return (
      <div className={modalContainerClassName}>
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  }
};

Modal.defaultProps = {
  buttonType: 'primary',
  buttonLabel: '',
  buttonDisabled: false,
  buttonClassName: '',
  componentType: 'icon',
  disableBackdropClick: false,
  iconType: 'info',
};

export default Modal;
