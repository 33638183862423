import React, { useState } from 'react';
import { useStyles } from './PostcodeLookupStyles';
import { ButtonSecondary } from '../../atoms';
import { Modal } from '../Modal';
import {
  PostcodeLookupNoMatchesFoundModalBody,
  PostcodeLookupAddressModalBody,
  PostcodeLookupCompanyNamesModalBody,
  PostcodeLookupErrorModalBody,
} from './';
import axios from 'axios';

export interface IHopeWiserItem {
  IsComplete: boolean;
  IsExpandable: boolean;
  ItemText: string;
  Selected: boolean;
  Sid: string;
}

const endpointParams = {
  OutputFields: 'Label5',
  ReserveOrganisationLine: 'AsRequired', // optional (Options: 'Never', 'Always', 'AsRequired' (default))
  IncludeCounty: 'AsRequired', // optional (Options: 'AsRequired' (default), 'Always', 'Never')
  TownFormat: 'Uppercase', // optional (Options: 'Uppercase' (default), 'Lowercase')
  StreetsOverMultipleDistricts: 'NoSeparation',
  PostcodeInPickList: 'Include',
  PostcodeResultLevel: 'Premise',
  LabelFormat: 'FixedTown',
};

interface ISelectedItem {
  Label1?: string;
  Label2?: string;
  Label3?: string;
  Label4?: string;
  Label5?: string;
}

interface ISelectedItemWithChangedKeys {
  firstLineOfAddress: string;
  secondLineOfAddress: string;
  town: string;
  county: string;
  postcode: string;
}
interface IPostcodeLookup {
  name: string;
  postcode: string;
  setValue: (key: string, data: any, options?: any) => void;
  trigger: (key?: string) => void;
  onClick?: (() => void) | undefined;
  buttonLabel?: string;
}

export const PostcodeLookup: React.FC<IPostcodeLookup> = ({
  name,
  setValue,
  postcode,
  trigger,
  onClick,
  buttonLabel,
}: IPostcodeLookup): JSX.Element => {
  const [addresses, setAddresses] = useState<Array<IHopeWiserItem>>([]);
  const [companyList, setCompanyList] = useState<Array<IHopeWiserItem>>([]);
  const [showNoMatchesModal, setShowNoMatchesModal] = useState(false);
  const [showAddressModel, setShowAddressModal] = useState(false);
  const [showCompanyNamesModal, setShowCompanyNamesModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState({ visible: false, errorMessage: '' });
  const classes = useStyles();

  const queryEndpoint = async (query: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_POSTCODE_LOOKUP_URL}`, {
      headers: { Authorization: `Basic ${process.env.REACT_APP_POSTCODE_LOOKUP_KEY}` },
      params: {
        q: decodeURIComponent(query),
        ...endpointParams,
      },
    });
    return data;
  };

  const changeSelectedItemKeys = (items: ISelectedItem): ISelectedItemWithChangedKeys => {
    return {
      firstLineOfAddress: items.Label1 ? items.Label1 : '',
      secondLineOfAddress: items.Label2 ? items.Label2 : '',
      town: items.Label3 ? items.Label3 : '',
      county: items.Label4 ? items.Label4 : '',
      postcode: items.Label5 ? items.Label5 : '',
    };
  };

  const handlePostcodeSubmit = async (onClick: (() => void) | undefined): Promise<void> => {
    if (onClick !== undefined) onClick(); // Handle onClick being passed down as a component prop
    const data = await queryEndpoint(postcode);

    if (data.Status === 'OK') {
      setShowAddressModal(true);
      setAddresses(data.Results.Items);
    } else {
      setShowNoMatchesModal(true);
    }
  };

  const showCompanySelection = async (selectedIndex: number, name: string): Promise<void> => {
    const data = await queryEndpoint(addresses[selectedIndex].Sid);

    if (addresses[selectedIndex].IsExpandable) {
      setCompanyList(data.Results.Items);
      setShowAddressModal(false);
      setShowCompanyNamesModal(true);
    } else {
      if (data.Status === 'LABEL_NOT_FORMATTED_ERROR') {
        setShowAddressModal(false);
        setShowErrorModal({ visible: true, errorMessage: data.StatusDetails.Description });
      } else {
        const selectedItem = changeSelectedItemKeys(data.Results.Items[0]);

        setValue(name, {
          firstLineOfAddress: selectedItem.firstLineOfAddress,
          secondLineOfAddress: selectedItem.secondLineOfAddress,
          town: selectedItem.town,
          county: selectedItem.county,
          postcode: selectedItem.postcode,
        });

        setShowAddressModal(false);
        trigger(name);
      }
    }
  };

  const saveSelectedItem = async (selectedIndex: number): Promise<void> => {
    const data = await queryEndpoint(companyList[selectedIndex].Sid);

    if (data.Status === 'LABEL_NOT_FORMATTED_ERROR') {
      setShowCompanyNamesModal(false);
      setShowErrorModal({ visible: true, errorMessage: data.StatusDetails.Description });
    } else {
      const selectedItem = changeSelectedItemKeys(data.Results.Items[0]);

      setValue(name, {
        firstLineOfAddress: selectedItem.firstLineOfAddress,
        secondLineOfAddress: selectedItem.secondLineOfAddress,
        town: selectedItem.town,
        county: selectedItem.county,
        postcode: selectedItem.postcode,
      });

      setShowCompanyNamesModal(false);
      trigger(name);
    }
  };

  return (
    <>
      <Modal
        open={showErrorModal.visible}
        setOpen={setShowErrorModal}
        heading="POSSIBLE MATCHES"
        headingClassName={classes.modalHeading}
        modalContainerClassName={classes.modalContainer}
        componentType="fragment"
        disableBackdropClick={true}
        body={
          <PostcodeLookupErrorModalBody
            showErrorModal={showErrorModal}
            setShowErrorModal={setShowErrorModal}
            postcode={postcode}
          />
        }
      />
      <Modal
        open={showNoMatchesModal}
        setOpen={setShowNoMatchesModal}
        heading="POSSIBLE MATCHES"
        headingClassName={classes.modalHeading}
        modalContainerClassName={classes.modalContainer}
        componentType="fragment"
        disableBackdropClick={true}
        body={
          <PostcodeLookupNoMatchesFoundModalBody setShowNoMatchesModal={setShowNoMatchesModal} postcode={postcode} />
        }
      />
      <Modal
        open={showAddressModel}
        setOpen={setShowAddressModal}
        heading="POSSIBLE MATCHES"
        headingClassName={classes.modalHeading}
        modalContainerClassName={classes.modalContainer}
        componentType="fragment"
        disableBackdropClick={true}
        body={
          <PostcodeLookupAddressModalBody
            setShowAddressModal={setShowAddressModal}
            addresses={addresses}
            showCompanySelection={showCompanySelection}
            name={name}
            postcode={postcode}
          />
        }
      />
      <Modal
        open={showCompanyNamesModal}
        setOpen={setShowCompanyNamesModal}
        heading="POSSIBLE MATCHES"
        headingClassName={classes.modalHeading}
        modalContainerClassName={classes.modalContainer}
        componentType="fragment"
        disableBackdropClick={true}
        body={
          <PostcodeLookupCompanyNamesModalBody
            setShowCompanyNamesModal={setShowCompanyNamesModal}
            saveSelectedItem={saveSelectedItem}
            companyList={companyList}
            name={name}
            postcode={postcode}
          />
        }
      />
      <ButtonSecondary
        disabled={postcode === undefined || postcode.trim() === ''}
        onClick={() => handlePostcodeSubmit(onClick)}
      >
        {buttonLabel || 'Look Up'}
      </ButtonSecondary>
    </>
  );
};

export default PostcodeLookup;
