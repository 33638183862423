import React from 'react';
import { useStyles } from './FinalSummaryStyles';
import { Typography } from '../../atoms';
import { Grid, Container, Box } from '@material-ui/core/';
import { format } from 'date-fns';
import clsx from 'clsx';

interface IFinalSummary {
  coverType: string;
  tripAmount: string;
  travelTo: string;
  travelDate: string;
  returnDate: string;
  cardName: string;
  cardNumber: string;
  cardExpiry: string | Date | null;
  total: string;
}

export const FinalSummary: React.FC<IFinalSummary> = ({
  coverType,
  tripAmount,
  travelTo,
  travelDate,
  returnDate,
  cardName,
  cardNumber,
  cardExpiry,
  total,
}: IFinalSummary): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.headingText}>
            Credit/debit card Details
          </Typography>
          <div className={clsx(classes.body5, classes.contentSeparator)}>
            <Container className="pb2">
              <Grid container className="pt1 pb1">
                <Grid item xs={12} md={8}>
                  <Typography variant="h3">{coverType}</Typography>
                  <ul>
                    <li className="pt05">
                      <Typography className={classes.body2}>
                        A{' '}
                        <Box component="span" className={classes.body1}>
                          {tripAmount}
                        </Box>
                      </Typography>
                    </li>
                    <li className="pt05">
                      <Typography className={classes.body2}>
                        Travelling to:{' '}
                        <Box component="span" className={classes.body1}>
                          {travelTo}
                        </Box>
                      </Typography>
                    </li>
                    <li className="pt05">
                      <Typography className={`${classes.body2} pr05`}>
                        Travelling Date:{' '}
                        <Box component="span" className={classes.body1}>
                          {travelDate}
                        </Box>
                      </Typography>
                    </li>
                    <li className="pt05">
                      <Typography className={`${classes.body2} pr05`}>
                        Return Date:{' '}
                        <Box component="span" className={classes.body1}>
                          {returnDate}
                        </Box>
                      </Typography>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h3">TOTAL</Typography>
                  <Typography className={classes.rogue1}>{total}</Typography>
                  <Typography className={classes.body5}>Includes Insurance Premium Tax where applicable</Typography>
                </Grid>
              </Grid>

              <Grid className="pt2">
                <Container>
                  <Typography className={classes.body2}>
                    Card Holders Name:{' '}
                    <Box component="span" className={classes.body1}>
                      {cardName}
                    </Box>
                  </Typography>
                </Container>
                <Container>
                  <Typography className={`${classes.body2} pr05`}>
                    Card Number:{' '}
                    <Box component="span" className={classes.body1}>
                      {cardNumber.toString().replace(/\d{4}$/, '****')}
                    </Box>
                  </Typography>
                </Container>
                <Container>
                  <Typography className={`${classes.body2} pr05`}>
                    Card Expiry:{' '}
                    <Box component="span" className={classes.body1}>
                      {cardExpiry !== null ? format(new Date(cardExpiry), 'MM/yy') : ""}
                    </Box>
                  </Typography>
                </Container>
                <Container className="pt2">
                  <Typography className={classes.body2}>
                    Green Flag supports extra security measures introduced by the card industry to protect your payment
                    details online.
                  </Typography>
                </Container>
                <Container className="pt15">
                  <Typography className={classes.body2}>
                    You may be asked to enter additional card security information after you have pressed <span className={`${classes.body1}`}>Pay.</span>
                  </Typography>
                </Container>
              </Grid>
            </Container>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FinalSummary;
