import React, { useContext, useState } from 'react';
import { useStyles, jsonInputStyle } from './StateEditorStyles';
import { InputLabel } from '@material-ui/core/';
import { StepContext } from '../../../contexts/StepContext';
import { UncontrolledSelectDropDown, ButtonPrimary } from '../';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Modal } from '../../../components/molecules';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import StorageIcon from '@material-ui/icons/Storage';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import IconButton from '@material-ui/core/IconButton';

const StateEditor: React.FC = () => {
  const context = useContext(StepContext);
  const { data, updateData, updateActiveStep, activeStep, steps } = context;
  const history = useHistory();
  const classes = useStyles();
  const [showEditModal, setShowEditModal] = useState(false);

  const StateEditorBody = (): JSX.Element => {
    const [newActiveStep, setNewActiveStep] = useState(activeStep);
    const [enableLocalStorage, setEnableLocalStorage] = useState(localStorage.getItem('dlgDebug_StateEditor_Enabled') ? localStorage.getItem('dlgDebug_StateEditor_Enabled') === 'true' : true);
    const [enableOverride, setEnabledOverride] = useState(localStorage.getItem('dlgDebug_StateEditor_Override') ? localStorage.getItem('dlgDebug_StateEditor_Override') === 'true' : true);
    const [jsonObject, setJsonObject] = useState({});

    const handleStepChange = (e): void => setNewActiveStep(+e.target.value);
    const saveToLocalStorage = (itemKey: string, data: any): void => localStorage.setItem(itemKey, data);
    const handleLocalStorageOnClick = (): void => {
      saveToLocalStorage('dlgDebug_StateEditor_Enabled', (!enableLocalStorage).toString());
      setEnableLocalStorage(!enableLocalStorage);
    };
    const handleOverrideLocalStorageOnClick = (): void => {
      saveToLocalStorage('dlgDebug_StateEditor_Override', (!enableOverride).toString());
      setEnabledOverride(!enableOverride);
    };
    const handleDeleteLocalStorageOnClick = () => localStorage.removeItem("dlgDebug_StateEditor_Data");

    const handleOnBlur = (e) => {
      if (e.error) return;
      setJsonObject(e.jsObject);
    };

    const handleSave = (): void => {
      if (enableLocalStorage) {
        const ls: any = JSON.parse(localStorage.getItem('dlgDebug_StateEditor_Data') as any);
        saveToLocalStorage('dlgDebug_StateEditor_Enabled', 'true');
        if (ls && enableOverride === false) {
          saveToLocalStorage("dlgDebug_StateEditor_Data", JSON.stringify({activeStep: ls.activeStep, data: {...data,  ...ls.data}}));
          updateData({...data,  ...ls.data});
          updateActiveStep(ls.activeStep);
          history.push(steps[ls.activeStep - 1].url);
        } else {
          saveToLocalStorage("dlgDebug_StateEditor_Data", JSON.stringify({activeStep: newActiveStep, data: {...data,  ...jsonObject}}));
          updateData({ ...data, ...jsonObject });
          updateActiveStep(newActiveStep);
          history.push(steps[newActiveStep - 1].url);
        }
      }
      setShowEditModal(false);
    };

    return (
      <div className={classes.stateEditorContainer}>
        <InputLabel className={classes.inputLabel}>Step</InputLabel>
        <UncontrolledSelectDropDown
          className={classes.selectDropDown}
          name="activeStep"
          onChange={handleStepChange}
          data={steps.map((step, index: number) => ({
            value: index + 1,
            label: `Step ${index + 1} - ${step.label}`,
            url: step.url,
          }))}
          value={newActiveStep.toString()}
        />
        <div className={classes.tooltipContainer}>
          <Tooltip title={enableLocalStorage ? 'Disable LocalStorage' : 'Enable LocalStorage'} className={enableLocalStorage ? classes.active : ''} onClick={handleLocalStorageOnClick}>
            <IconButton aria-label="local storage">
              <StorageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={enableOverride ? 'Disable Override LocalStorage' : 'Enable Override LocalStorage'} className={enableOverride ? classes.active : ''} onClick={handleOverrideLocalStorageOnClick}>
            <IconButton aria-label="override local storage">
              <PriorityHighIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete LocalStorage" onClick={handleDeleteLocalStorageOnClick}>
            <IconButton aria-label="delete local storage">
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
        </div>
        <JSONInput
          id="state-editor-json"
          placeholder={data}
          locale={locale}
          height="auto"
          onBlur={handleOnBlur}
          waitAfterKeyPress={250}
          style={jsonInputStyle}
        />
        <ButtonPrimary onClick={handleSave} className={classes.saveBtn} type="submit">Save</ButtonPrimary>
      </div>
    );
  };

  return (
    <>
      <EditIcon className={classes.icon} onClick={() => setShowEditModal(true)} />
      <Modal
        heading="Edit State"
        open={showEditModal}
        setOpen={setShowEditModal}
        onClose={() => setShowEditModal(false)}
        componentType="fragment"
        body={<StateEditorBody />}
      />
    </>
  );
};

export default StateEditor;
