import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  question: {
    marginBottom: '0.5rem',
  },
  subTitle: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  radioBtnGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.25rem',
  },
  contactModalHeading: {
    '& div': {
      marginRight: '5rem',
    },
  },
  yourQuoteHeading: {
    marginBottom: '0.5rem',
  },
  coverLevelNotice: {
    marginBottom: '1.5rem',
  },
  vehicleCostContainer: {
    marginTop: '1rem',
  },
  coverLevelConfirmNotice: {
    margin: '1rem 0',
  },
  coverStartNotice: {
    marginBottom: '1.5rem',
  },
  coverStartDate: {
    marginBottom: '1.25rem',
  },
  editAnswers: {
    ...theme.bodyStyles.body4,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:focus': {
      outlineStyle: 'solid',
      outlineWidth: '2px',
      outlineColor: `${theme.palette.utility.focusBlue}`,
      outlineOffset: '0px',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    '& button:last-child': {
      marginLeft: '1rem',
    },
    '@media (max-width: 410px)': {
      flexDirection: 'column',
      '& button:last-child': {
        margin: '1rem 0',
      },
    },
  },
  emailMeThisQuoteBtn: {
    width: '100%',
  },
}));
