import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles, StyledListItem } from '../PostcodeLookupStyles';
import { Typography, ButtonPrimary, ButtonSecondary } from '../../../atoms';
import { List, ListItemText } from '@material-ui/core';
import { IHopeWiserItem } from '../PostcodeLookup.component';

interface IPostcodeLookupCompanyNamesModalBody {
  setShowCompanyNamesModal: Dispatch<SetStateAction<boolean>>;
  companyList: Array<IHopeWiserItem>;
  saveSelectedItem: (selectedIndex: number, name: string) => Promise<void>;
  name: string
  postcode: string;
}

export const PostcodeLookupCompanyNamesModalBody: React.FC<IPostcodeLookupCompanyNamesModalBody> = ({
  setShowCompanyNamesModal,
  companyList,
  saveSelectedItem,
  name,
  postcode,
}: IPostcodeLookupCompanyNamesModalBody): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const classes = useStyles();

  const handleCancel = (): void => setShowCompanyNamesModal(false);
  const handleListItemClick = (_: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.resultsContainer}>
      <Typography className={classes.information}>Searching for {postcode}</Typography>
      <Typography className={classes.information}>{companyList.length === 0 ? "No match found" : `${companyList.length} possible matches found.`}</Typography>
      <List component="nav" aria-label="company list" className={classes.list}>
        {companyList.map((companyName: IHopeWiserItem, index: number) => (
          <StyledListItem
            key={index}
            button
            selected={selectedIndex === index + 1}
            onClick={(e) => handleListItemClick(e, index + 1)}
            onDoubleClick={() => saveSelectedItem(selectedIndex - 1, name)}
          >
            <ListItemText primary={companyName.ItemText} />
          </StyledListItem>
        ))}
      </List>
      <div className={classes.actionButtonsContainer}>
        <ButtonPrimary onClick={() => saveSelectedItem(selectedIndex - 1, name)}>Select</ButtonPrimary>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
      </div>
    </div>
  );
};

export default PostcodeLookupCompanyNamesModalBody;
