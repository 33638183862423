import React, { Dispatch, SetStateAction, useState, useContext } from 'react';
import { useStyles } from './VehicleStyles';
import { StepContext } from '../../../../contexts/StepContext';
import { calculateCostTotal, IOption } from '../../../organisms';
import { Typography, ButtonPrimary, UncontrolledTextField } from '../../../atoms';
import { VehicleInformation } from '../VehicleInformation/VehicleInformation.component';
import { VehicleDeleteModal } from '../VehicleDeleteModal/VehicleDeleteModal.component';
import { VehicleDuplicateModal } from '../VehicleDuplicateModal/VehicleDuplicateModal.component';
import axios from 'axios';

export interface IVehicleData {
  reactIndex: number;
  reg: string;
  make: string;
  model: string | null;
  transmissionType: string;
  fuelType: string;
  year: number | string | null;
  apiNoResult: boolean | null;
  complete: boolean;
  fieldDisabled?: boolean;
}

interface IVehicleComponent {
  entry: IVehicleData;
  index: number;
  name: string;
  vehicles: Array<IVehicleData>;
  setVehicles: Dispatch<SetStateAction<Array<IVehicleData>>>;
  setValue: any;
  vehicleIndex: number;
  setVehicleIndex: Dispatch<SetStateAction<number>>;
  demo?: boolean;
  trigger: (key?: string) => void;
}

const Vehicle = ({
  entry,
  index,
  name,
  vehicles,
  setVehicles,
  setValue,
  vehicleIndex,
  setVehicleIndex,
  demo,
  trigger,
}: IVehicleComponent): JSX.Element => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [informationIsCorrect, setInformationIsCorrect] = useState<boolean | undefined>(undefined);
  const [showDuplicateRegModal, setShowDuplicateRegModal] = useState(false);
  const context = useContext(StepContext);
  const { updateVehicleDetails } = context;

  const handleVehicleSearch = async (): Promise<void> => {
    const duplicateSearch =
      vehicles.filter((v: IVehicleData) => v.reg.replace(/\s/g, '') === entry.reg.replace(/\s/g, ''))
        .length > 1;

    if (duplicateSearch) {
      setShowDuplicateRegModal(true);
      handleVehicleClear();
      return;
    }

    setLoading(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PATH}/${process.env.REACT_APP_VEHICLE_LOOKUP_ENDPOINT}`,
        {
          vrm: entry.reg.replace(/[^0-9a-z\s]/gi, '').replace(/ /g,'').trim(),
        },
      );
      const newVehicles = vehicles.map((v: IVehicleData) =>
        v.reactIndex === entry.reactIndex ? { ...v, ...data, reg: entry.reg.replace(/[^0-9a-z\s]/gi, '').replace(/ /g,'').trim(), apiNoResult: false, fieldDisabled: true } : v,
      );
      setVehicles(newVehicles);
      setValue(name, newVehicles);
    } catch (err) {
      const newVehicles = vehicles.map((v: IVehicleData) =>
        v.reactIndex === entry.reactIndex ? { ...v, reg: entry.reg.replace(/[^0-9a-z\s]/gi, '').replace(/ /g,'').trim(), apiNoResult: true, fieldDisabled: true } : v,
      );
      setVehicles(newVehicles);
      setValue(name, newVehicles);
    }
    setLoading(false);
  };

  const handleRemove = (): void => {
    const remainingVehicles = vehicles.filter((v: IVehicleData) => v.reactIndex !== entry.reactIndex);
  
    if (!demo) {
      const objectWithSelectedCoverType = context.data.coverOptions.find((d: IOption) => d.coverType === context.data.coverType);
      const vehicleObject = calculateCostTotal(objectWithSelectedCoverType, context.data.vehicleCount - 1);
      updateVehicleDetails(context.data.vehicleCount - 1, vehicleObject.cost, vehicleObject.id);
      setVehicles(remainingVehicles);
      setValue(name, remainingVehicles);
      setInformationIsCorrect(undefined);
      trigger(name);
    } else {
      setVehicles(remainingVehicles);
      setValue(name, remainingVehicles);
      setInformationIsCorrect(undefined);
      trigger(name);
    }
  };

  const saveFields = (data = []): void => {
    const newVehicles = vehicles.map((v: IVehicleData) =>
      v.reactIndex === entry.reactIndex ? { ...v, ...data, complete: true, fieldDisabled: true } : v,
    );
    setVehicles(newVehicles);
    setValue(name, newVehicles);
    trigger(name);
  };

  const handleVehicleClear = (): void => {
    const newVehicles = [...vehicles];
    const currentIndex = vehicles.findIndex((v: IVehicleData) => v.reactIndex === entry.reactIndex);
    newVehicles[currentIndex] = {
      reactIndex: vehicleIndex + 1,
      reg: '',
      make: '',
      model: '',
      transmissionType: '',
      fuelType: '',
      year: null,
      apiNoResult: null,
      complete: false,
      fieldDisabled: false,
    };
    setVehicles(newVehicles);
    setValue(name, newVehicles);
    setInformationIsCorrect(undefined);
    setVehicleIndex(vehicleIndex + 1);
    trigger(name);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newVehicles = vehicles.map((v: IVehicleData) =>
      v.reactIndex === entry.reactIndex ? { ...v, reg: e.target.value } : v,
    );
    setVehicles(newVehicles);
  };

  const disableOtherEntries = (): boolean => {
    const currentIndex = vehicles.findIndex((v: IVehicleData) => v.reactIndex === entry.reactIndex);
    if (vehicles[currentIndex - 1] !== undefined) {
      if (vehicles[currentIndex - 1].complete === false) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div key={entry.reactIndex}>
      <div className={classes.vehicleContainer}>
        <div className={classes.vehicleSearch}>
          <Typography variant="body3">VEHICLE {(index + 1).toString().padStart(2, '0')} REG</Typography>
          <UncontrolledTextField
            forComponent="vehicle-lookup"
            disabled={entry.fieldDisabled || disableOtherEntries() || entry.complete}
            onChange={handleOnChange}
            className={classes.vehicleTextField}
            value={entry.reg}
          />
        </div>
        <div className={classes.vehicleBtnOptions}>
          {entry.fieldDisabled === true && entry.apiNoResult !== true ? (
            <ButtonPrimary className={classes.clearBtn} onClick={handleVehicleClear}>Clear</ButtonPrimary>
          ) : (
            <ButtonPrimary
              loading={loading}
              disabled={entry.fieldDisabled || disableOtherEntries() || entry.reg.trim() === ''}
              onClick={handleVehicleSearch}
            >
              {loading ? 'Searching' : 'Look Up'}
            </ButtonPrimary>
          )}
        </div>
        <span className={classes.vehicleDeleteDesktop}>
          <VehicleDeleteModal handleRemove={handleRemove} />
        </span>
      </div>
      <div>
        <VehicleInformation
          entry={entry}
          saveFields={saveFields}
          informationIsCorrect={informationIsCorrect}
          setInformationIsCorrect={setInformationIsCorrect}
        />
        <div>
          <VehicleDuplicateModal
            showDuplicateRegModal={showDuplicateRegModal}
            setShowDuplicateRegModal={setShowDuplicateRegModal}
          />
        </div>
      </div>
      <div className={classes.vehicleDeleteMobile}>
        <VehicleDeleteModal handleRemove={handleRemove} />
      </div>
    </div>
  );
};

export default Vehicle;
