import React from 'react';
import { useStyles } from './PageTemplateStyles';
import Grid from '@material-ui/core/Grid';
import { NavBar, Footer } from '../components/organisms';

interface IPageTemplate {
  children: React.ReactNode;
}

export const PageTemplate: React.FC<IPageTemplate> = ({ children }: IPageTemplate) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <Grid container>
          <Grid className={classes.sticky} item xs={12}>
            <NavBar />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
};

export default PageTemplate;
