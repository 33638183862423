import React from 'react';
import { Typography, ButtonSecondary } from '../../../../components/atoms';
import { Divider, Grid } from '@material-ui/core/';
import { YourCoverAccordion } from './YourCoverAccordion';
import clsx from 'clsx';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useStyles } from '../../ReviewStyles';

interface IYourCoverReview {
    onClick: () => void,
    data: any
}

const YourCoverReview: React.FC<IYourCoverReview> = ({ onClick, data }: IYourCoverReview) => {
    const classes = useStyles();
    
    return (
        <div>
            <div >
                <Grid container alignItems='center' spacing={2} >
                    <Grid item xs={10}>
                        <Typography variant="h3" >
                            YOUR COVER
                        </Typography>
                    </Grid>
                    <Grid item className={classes.backBtn} xs={2} >
                        <Typography variant="body2" className={classes.text}>
                            <ButtonSecondary onClick={onClick} className={clsx(classes.buttonEdit)}>EDIT</ButtonSecondary>
                        </Typography>
                    </Grid>
                </Grid>
                
                <Divider className="divider"/>

                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            COVER START DATE
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.textBody2}>
                            {format(new Date(data.coverStartDate), 'dd MMMM yyyy', { locale: enGB,  }).toUpperCase()}
                        </Typography>  
                    </Grid> 
                </Grid>
                <Typography variant="h4" className="bold">
                    {data.coverType}
                </Typography>

                <YourCoverAccordion data={data}/>
                <Divider className="divider"/>
            </div>
            <div>
                <Typography align="right" variant="h3" className={`${classes.text} bold`}>
                    YOUR TOTAL {`£${data.quoteTotal.toFixed(2)}`}
                </Typography>
            </div>
        </div>
    );
};

export default YourCoverReview;