import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const GlobalCss = withStyles((theme: Theme) => ({
  '@global': {
    /* Helper */
    '.bold': {
      fontWeight: 700,
    },
    '.error': {
      color: theme.palette.error.main,
    },

    /* Margin */
    '.mt05': {
      marginTop: '0.5rem',
    },
    '.mt1': {
      marginTop: '1rem',
    },
    '.mt2': {
      marginTop: '2rem',
    },
    '.mr1': {
      marginRight: '1rem',
    },
    '.mb05': {
      marginBottom: '0.5rem',
    },
    '.mb1': {
      marginBottom: '1rem',
    },
    '.mb2': {
      marginBottom: '2rem',
    },
    '.mb3': {
      marginBottom: '3rem',
    },
    '.my1': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    'mAuto': {
      margin: 'auto',
    },
    '.mxAuto': {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    /* Padding */
    '.p1': {
      padding: '2rem',
    },
    '.pt05': {
      paddingTop: '.5rem',
    },
    '.pt1': {
      paddingTop: '1rem',
    },
    '.pt15': {
      paddingTop: '1.5rem',
    },
    '.pt2': {
      paddingTop: '2rem',
    },
    '.pb1': {
      paddingBottom: '1rem',
    },
    '.pb2': {
      paddingBottom: '2rem',
    },
    '.pl05': {
      paddingLeft: '.5rem',
    },
    '.pl1': {
      paddingLeft: '1rem'
    },
    '.pr05': {
      paddingRight: '0.5rem'
    },
    '.py1': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },


    /* Width */
    '.w100': {
      width: '100%',
    },

    /* Step */
    '.stepHeading': {
      marginBottom: '2rem',
    },
    '.stepBtnContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 340px)': {
        display: 'flex',
        flexDirection: 'column',
        '& button': {
          marginBottom: '1rem',
        },
      },
    },
  },
}))(() => null);

export default GlobalCss;
