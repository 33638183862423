import React from 'react';
import { useStyles } from './AccordionStyles';
import { CSSAccordion, AccordionSummary, AccordionDetails } from './AccordionStyles';
import { Typography } from '../../atoms';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IAccordion {
  heading: string;
  body: string;
}

const Accordion: React.FC<IAccordion> = ({ heading, body }: IAccordion): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <CSSAccordion className={classes.accordionHeading} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="accordion1d-content" id="accordion1d-header">
          <Typography variant="h2">{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.accordionBody}>{body}</Typography>
        </AccordionDetails>
      </CSSAccordion>
    </div>
  );
};

export default Accordion;
