import { makeStyles, Theme } from '@material-ui/core/';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    ...theme.shadowStyles.masterDropShadow,
    backgroundColor: theme.palette.common.white,
    padding: '1rem',
  },
  pr1: {
    paddingRight: '1rem',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  body5: {
    ...theme.bodyStyles.body5,
    lineHeight: '1rem',
    margin: '0.2rem 0',
  },
  openingHoursContainer: {
    display: 'flex',
    '@media (max-width: 400px)': {
      marginTop: '2rem',
    },
  },
  anchorStyling: {
    textDecoration: 'none',
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.green.main,
    },
  },
  sticky: {
    position: 'sticky',
    display: 'block',
    top: '1rem',
  },
  dNoneMobile: {
    '@media (max-width: 1279px)': {
      display: 'none',
    },
  },
  callUsOn: {
    marginBottom: '1rem',
  },
}));
