import React, { createContext, useReducer } from 'react';
import * as actions from '../actions/StepActions';
import { IVehicleData } from '../components/molecules/VehicleLookup/Vehicle/Vehicle.component';

export enum Step {
  QUOTE = 0,
  ASSUMPTIONS = 1,
  VEHICLE_DETAILS = 2,
  BUSINESS_DETAILS = 3,
  REVIEW = 4,
  PAYMENT = 5,
  SUMMARY = 6,
  CONFIRMATION = 7
};

export const steps = [
  {
    label: 'Quote',
    url: '/quote'
  },
  {
    label: 'Assumptions',
    url: '/assumptions'
  },
  {
    label: 'Vehicle Details',
    url: '/vehicle-details'
  },
  {
    label: 'Business Details',
    url: '/business-details'
  },
  {
    label: 'Review',
    url: '/review'
  },
  {
    label: "Payment",
    url: "/payment"
  },
  {
    label: "Summary",
    url: "/summary"
  },
  {
    label: "Confirmation",
    url: "/confirmation"
  }
];

export const quoteData = {
  coverOptions: null,
  moreThanFiftyVehicles: '',
  sameLevelOfCover: '',
  breakdownCoverLocation: '',
  vehicleRepairLocation: '',
  coverType: '',
  vehicleCount: 0,
  quoteTotal: 0,
  vehicleId: '',
  coverStartDate: null,
  expires: undefined,
  quote_id: undefined,
  timestamp: undefined,
  user_id: undefined,
};

export const vehicleDetailsData = {
  vehicles: [],
};

export const businessDetailsData = {
  companyName: '',
  address: {
    firstLineOfAddress: '',
    secondLineOfAddress: '',
    town: '',
    county: '',
    postcode: '',
  },
  businessContactName: '',
  businessContactSurname: '',
  businessContactNumber: '',
  businessContactEmail: '',
  secondPointOfContact: false,
  secondBusinessContactName: '',
  secondBusinessContactSurname: '',
};

export const paymentData = {
  cardholdersName: '',
  cardNumber: '',
  cardType: ' ',
  cardSecurityNumber: '',
  expiryDate: null,
  billingAddressIsSameAsPreviousSection: 'true',
  postcodeLookup: {
    firstLineOfAddress: '',
    secondLineOfAddress: '',
    town: '',
    county: '',
    postcode: '',
  },
};

export const summaryData = {
  paymentSuccessful: null
};

export const initialNavStepperData = {
  ...quoteData,
  ...vehicleDetailsData,
  ...businessDetailsData,
  ...paymentData,
  ...summaryData,
};

export interface IBusinessDetailsAddress {
  firstLineOfAddress: string;
  secondLineOfAddress: string;
  town: string;
  county: string;
  postcode: string;
}
export interface IBillingAddress {
  firstLineOfAddress: string;
  secondLineOfAddress: string;
  town: string;
  county: string;
  postcode: string;
}
export interface IStepData {
  secondPointOfContact: boolean;
  cardholdersName: string;
  cardNumber: string;
  cardType: string;
  cardSecurityNumber: string;
  billingAddressIsSameAsPreviousSection: string;
  expiryDate: Date | string | null;
  coverOptions: any;
  moreThanFiftyVehicles: string;
  sameLevelOfCover: string;
  breakdownCoverLocation: string;
  vehicleRepairLocation: string;
  coverType: string;
  vehicleCount: number;
  quoteTotal: number;
  vehicleId: string | null;
  coverStartDate: Date | string | null;
  vehicles: Array<IVehicleData>;
  companyName: string;
  address: IBusinessDetailsAddress;
  postcodeLookup: IBillingAddress;
  businessContactName: string;
  businessContactSurname: string;
  businessContactNumber: string;
  businessContactEmail: string;
  secondBusinessContactName: string;
  secondBusinessContactSurname: string;
  expires?: number;
  quote_id?: string;
  timestamp?: number;
  user_id?: string;
  paymentSuccessful: boolean|null;
}

export interface IStep {
  activeStep: number;
  steps: Array<{ label: string, url: string }>;
  data: IStepData;
  loading: boolean;
}

const debugEnabled: string|null = localStorage.getItem("dlgDebug_StateEditor_Enabled");
const debugData = debugEnabled === 'true' ? localStorage.getItem("dlgDebug_StateEditor_Data") : null;

const initialState = {
  activeStep: debugData ? JSON.parse(debugData).activeStep : 1,
  steps: steps,
  data: debugData ? JSON.parse(debugData).data : initialNavStepperData,
  loading: true,
  updateLoading: () => {},
  updateQuotes: () => {},
  updateActiveStep: () => {},
  updateVehicleDetails: () => {},
  updateData: () => {},
};

export type StepType = {
  activeStep: number;
  steps: Array<{ label: string, url: string }>
  data: IStepData;
  loading: boolean;
  updateLoading: (isLoading: boolean) => void;
  updateQuotes: (quotes) => void;
  updateActiveStep: (newCount: number) => void;
  updateVehicleDetails: (newVehicleCount: number, newQuoteTotal: number, newVehicleId: string|null) => void;
  updateData: (newStepData: IStepData) => void;
};

export const StepContext = createContext<StepType>(initialState);

const reducer = (state: IStep, action: actions.Action) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: action.payload };
    case actions.UPDATE_QUOTES:
      return { ...state, data: { ...state.data, coverOptions: action.payload } };
    case actions.UPDATE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case actions.UPDATE_VEHICLE_DETAILS:
      return { ...state, data: { ...state.data, ...action.payload } };
    case actions.UPDATE_DATA:
      return { ...state, data: { ...initialNavStepperData, ...action.payload } };
    default:
      return state;
  }
};

export const StepProvider = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StepContext.Provider
      value={{
        activeStep: state.activeStep,
        data: state.data,
        steps: state.steps,
        loading: state.loading,
        updateLoading: (isLoading: boolean) => dispatch({ type: actions.LOADING, payload: isLoading }),
        updateQuotes: (quotes) => dispatch({ type: actions.UPDATE_QUOTES, payload: quotes }),
        updateActiveStep: (newStep: number) => dispatch({ type: actions.UPDATE_ACTIVE_STEP, payload: newStep }),
        updateVehicleDetails: (newVehicleCount: number, newQuoteTotal: number, newVehicleId: string|null) =>
          dispatch({
            type: actions.UPDATE_VEHICLE_DETAILS,
            payload: { vehicleCount: newVehicleCount, quoteTotal: newQuoteTotal, vehicleId: newVehicleId },
          }),
        updateData: (newStepData: IStepData) => dispatch({ type: actions.UPDATE_DATA, payload: newStepData }),
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default StepProvider;
