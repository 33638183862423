import { createMuiTheme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const theme = createMuiTheme({
  overrides: {
    MuiDivider: {
      root:{
        '&.divider': {
          borderBottom: `1px solid #1B1D1B`,
        },
        '&.divider-mb-1': {
          borderBottom: `1px solid #1B1D1B`,
          marginBottom: '1rem',
        },
        '&.divider-my-1': {
          borderBottom: '1px solid #1B1D1B',
          margin: '1rem 0',
        },
        '&.divider2-mb-1': {
          borderBottom: `2px solid #1B1D1B`,
          marginBottom: '1rem',
        },
        '&.divider2-my-1': {
          borderBottom: '2px solid #1B1D1B',
          margin: '1rem 0',
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableTouchRipple: true,
      focusVisibleClassName: 'focused-element',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.75rem',
      fontWeight: 300,
      fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 300,
      fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 400,
      fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 300,
      fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    button: {
      fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    }
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    common: {
      white: '#FFF',
      black: '#1B1D1B',
    },
    primary: {
      main: '#1B1D1B',
      dark: '',
      light: '',
    },
    secondary: {
      main: '#FFF',
    },
    background: {
      default: isMobile ? '#FFF' : '#F3F3F3',
    },
    error: {
      main: '#FC005E',
      light: '',
    },
    green: {
      main: '#70ED5B',
      [50]: '',
      [100]: '',
      [200]: '',
      [300]: '',
      [400]: '',
      [500]: '',
      [600]: '',
      [700]: '',
      [800]: '',
      [900]: '',
    },
    dark: {
      main: '#1B1D1B',
      [50]: '',
      [100]: '',
      [200]: '',
      [300]: '',
      [400]: '',
      [500]: '',
      [600]: '',
      [700]: '',
      [800]: '',
      [900]: '',
    },
    light: {
      main: '#FFF',
      [50]: '',
      [100]: '',
      [200]: '',
      [300]: '',
      [400]: '',
      [500]: '',
      [600]: '',
      [700]: '',
      [800]: '',
      [900]: '',
    },
    utility: {
      amberLight: '',
      amberDark: '',
      redLight: '',
      redDark: '',
      purpleLight: '',
      purpleDark: '',
      greenHover: '#08CF00',
      greenHit: '#9CFF89',
      primaryGrey: '#AAAAAA',
      greyDark: '#595959',
      greyLight: '#F3F3F3',
      focusBlue: '#2DD1FF',
      bgDesktop: '#F3F3F3',
      bgMobile: '#FFF',
      lightBox: '#1B1D1B',
      vehicleInformationConfirmBg: '#F1F1F1', // Vehicle Lookup Manual Entry Form
      errorOver: '#DD0052', // Tertiary Button
      errorHit: '#FA3F85', // Tertiary Button
      errorTextBg: '#F8D7DA', // Vehicle Lookup Manual Entry Form
      errorTextBorderColor: '#F5C6CB', // Vehicle Lookup Manual Entry Form
      manageCookiesBtnGreen: '#68b631', // Footer
      cookiesNotice: '#A9A9A9' // Footer
    },
  },
  layouts: {
    stepGrid: {
      gridMainContainer: {
        maxWidth: '1280px',
        margin: 'auto',
        padding: '1rem',
      },
      gridMain: {
        boxShadow: '0px 2px 6px 0px #7D7D7D',
        backgroundColor: '#FFF',
        padding: '1rem',
      },
      gridMainDiv: {
        padding: '1rem',
      },
      gridSidebarContainer: {
        display: 'none',
        padding: '0 4rem 0 2rem',
        '@media (max-width: 1279px)': {
          padding: '1rem 0rem 0rem 0rem',
        },
        '@media (min-width: 426px)': {
          display: 'block',
        },
      },
      gridSidebarSticky: {
        position: 'sticky' as const,
        display: 'block',
        top: '8rem',
      },
      gridSidebarYourPriceContainer: {
        marginTop: '1rem',
      },
    },
  },
  shadowStyles: {
    masterDropShadow: {
      boxShadow: '0px 2px 6px 0px #7D7D7D',
    },
    masterDropShadowWhite: {
      boxShadow: `0px 2px 6px 0px #FFF`,
    },
    masterDropShadowPrimaryGreen: {
      boxShadow: `0px 2px 6px 0px #1B1D1B`,
    },
  },
  bodyStyles: {
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 'normal',
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 'normal',
    },
    body3: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    body4: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    body5: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
  },
  rogueStyles: {
    rogue1: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '3.75rem',
      lineHeight: 'normal',
    },
    rogue2Mobile: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '1.875rem',
      lineHeight: 'normal',
    },
    rogue3: {
      fontFamily: 'Oswald',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 'normal',
    },
    rogue4: {
      fontFamily: 'Oswald',
      fontWeight: 300,
      fontSize: '1.125rem',
      lineHeight: 'normal',
    },
  }
});

export default theme;
