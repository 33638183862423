import { makeStyles, Theme } from '@material-ui/core/';

export const useStyles = makeStyles((theme: Theme) => ({
  headingText: {
    textTransform: 'uppercase',
    paddingBottom: '1rem',
  },
  contentSeparator: {
    borderTop: `1px solid ${theme.palette.common.black}`,
  },
  body1: theme.bodyStyles.body1,
  body2: theme.bodyStyles.body2,
  body5: theme.bodyStyles.body5,
  rogue1: theme.rogueStyles.rogue1,
}));
