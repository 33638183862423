import React from 'react';
import { useStyles } from './ConfirmationNoticeStyles';
import { Typography } from '../../atoms';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import CalendarGreen from '../../../assets/img/icons/calendar-green.png';

interface IConfirmationNotice {
  policyStartDate: string|Date|null;
  businessContactName: string;
}

export const ConfirmationNotice: React.FC<IConfirmationNotice> = ({ policyStartDate, businessContactName }: IConfirmationNotice): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid className="w100">
          <div className="pb1">
            <Typography className={classes.heading} variant="h1">ALL DONE</Typography>
          </div>
          <Grid container className={classes.contentSpacers}>
            <div className="mxAuto">
              <img src={CalendarGreen} className={classes.img} alt="Calendar Icon" />
            </div>
            <div className={`${classes.policyContainer} pl1 py1`}>
              <Typography className={classes.policyStarts}>Your policy starts:</Typography>
              <Typography className={classes.policyStartDate} variant="h1">
                {policyStartDate !== null && policyStartDate.toString() !== "Invalid Date" ? format(new Date(policyStartDate), 'EEEE, d MMMM yyyy').toUpperCase() : <></>}
              </Typography>
            </div>
          </Grid>
          <div className={classes.contentSpacers}>
            <Typography className={`${classes.subHeading} pt1`} variant="h3">
              thanks for your purchase { businessContactName }
            </Typography>
            <Typography className="pt1">
              Your policy documents will be sent out to you shortly, and you&apos;ll receive a confirmation email.
            </Typography>
            <Typography className="py1">
              if you have any queries please get in touch on 0345 246 0637.
            </Typography>
          </div>
          <div className={classes.contentSpacers}>
            <Typography className={`${classes.subHeading} pt1`} variant="h3">
              AUTOMATIC RENEWALS
            </Typography>
            <Typography className="pt1">
              Your payment details will be securely stored to enable continuous payment authority. We may use these
              details to automatically renew your policy next year. This only applies to payments by Visa, Visa Delta,
              or Mastercard.
            </Typography>
            <Typography className="pt1">
              We will ensure that you are sent a renewal invitation before taking any payment. Please call us if your
              circumstances change or you do not wish us to automatically renew your policy.
            </Typography>
          </div>
      </Grid>
    </Grid>
  );
};

ConfirmationNotice.defaultProps = {
  policyStartDate: '',
};

export default ConfirmationNotice;
