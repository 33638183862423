import React from 'react';
import { CSSAccordion, AccordionSummary, AccordionDetails } from './YourCoverAccordionStyles';
import { useStyles } from '../../../ReviewStyles';
import { Typography } from '../../../../../components/atoms';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { IVehicleData } from '../../../../../components/molecules';

interface IYourCoverAccordion {
  data: any;
  className?: string;
}

const YourCoverAccordion: React.FC<IYourCoverAccordion> = ({ data }: IYourCoverAccordion): JSX.Element => {
  const classes = useStyles();
  const vehicleArray = data.vehicles;
  
  return (
      <CSSAccordion className={classes.accordionHeading} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="accordion1d-content" id="accordion1d-header">
          <Grid container spacing={2} >
              <Grid item xs={3}>
                  <Typography variant="h4" className={`${classes.text} bold`}>
                      {data.vehicleCount} X VEHICLE
                  </Typography>
              </Grid>
              <Grid item xs={9} >
                  <Typography variant="body2" className={`${classes.text} bold`}>
                      £{data.quoteTotal}
                  </Typography>
              </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDisplay} >
          {vehicleArray.map((vehicle: IVehicleData, index: number) => {
            return(
            <div key={index+1}> 
              <Typography variant="h4" className={`${classes.text} "bold"`}>
                VEHICLE {(index+1).toString().padStart(2,"0")} {vehicle.reg.toUpperCase()}
              </Typography>
              <Grid container>
                <Grid item xs={3}>
                    <Typography variant="h4" className={clsx(classes.accordionBody, classes.text)}>
                        MAKE <span className="bold">{vehicle.make}</span>
                    </Typography>
                </Grid>
                <Grid item xs={4} >
                    <Typography variant="h4" className={clsx(classes.accordionBody, classes.text)}>
                        MODEL <span className="bold">{vehicle.model}</span>
                    </Typography>
                </Grid>
                <Grid item xs={5} >
                    <Typography variant="h4" className={clsx(classes.accordionBody, classes.text)}>
                        YEAR OF MANUFACTURE <span className="bold">{vehicle.year}</span>
                    </Typography>
                </Grid>
              </Grid>
              {index === data.vehicleCount ?
                (<></>) :
                (<Divider className={classes.divGrey}/>)
              }  
            </div> 
            );
          }
          )}
        </AccordionDetails>
      </CSSAccordion>
    
  );
};

export default YourCoverAccordion;
