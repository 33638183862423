import { createStyles, Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import regPlate from '../../../assets/img/logo/gb-plate.jpg';
import { STRIPE_BASE } from '../../molecules/Stripe/CardElementStyles';

export const TextFieldBase = withStyles((theme: Theme) => ({
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.utility.greyDark}`,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.common.black,
    },
    '&:focus': {
      boxShadow: `${theme.palette.common.black} 0 0 0 1px`,
      border: `1px solid ${theme.palette.utility.greyDark}`,
      borderColor: `${theme.palette.utility.focusBlue}`,
      textShadow: `0px 0px 0.5px ${theme.palette.common.black}`,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.utility.greyLight,
    },
  },
}))(InputBase);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stripe: {
      ['& .' + STRIPE_BASE]: {
        height: '1em',
      },
    },
    body: { ...theme.bodyStyles.body5 },
    vehicleLookup: {
      backgroundImage: `url(${regPlate})`,
      backgroundPosition: '0px 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '32px',
      paddingLeft: '50px',
    },
  }),
);
