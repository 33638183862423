import React, { useState, useCallback, useContext } from 'react';
import { useStyles } from './VehicleLookupStyles';
import { StepContext } from '../../../contexts/StepContext';
import { calculateCostTotal } from '../../organisms';
import { Modal } from '../../molecules/';
import { Link, Divider, ButtonBase } from '@material-ui/core';
import { Typography, ButtonSecondary } from '../../atoms';
import { Controller, Control } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import Vehicle, { IVehicleData } from './Vehicle/Vehicle.component';
import ContactModalBody from '../../../pages/Quote/ContactModalBody/ContactModalBody';

interface IVehicleLookup {
  vehicles: any;
  setVehicles: any;
  vehicleIndex: any;
  setVehicleIndex: any;
  name: string;
  control: Control;
  coverType: string;
  setValue: any;
  demo?: boolean;
  handleChangeYourRequirements?: () => void;
  trigger: (key?: string) => void;
}

interface INoVehicles {
  coverType: string;
}

export const vehicleLookupDataIsValid = (vehicles: Array<IVehicleData>): boolean => {
  let incomplete = 0;
  if (vehicles === null || vehicles === undefined) return false;
  vehicles.forEach((v: IVehicleData) => {
    if (!v['complete'] === true) {
      incomplete++;
    }
  });
  return incomplete === 0 && vehicles.length > 0 ? true : false;
};

const NoVehicles: React.FC<INoVehicles> = ({ coverType }: INoVehicles): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography className={classes.noVehicles}>YOU CURRENTLY HAVE NO VEHICLES FOR {coverType.toUpperCase()}</Typography>
  );
};

export const VehicleLookup: React.FC<IVehicleLookup> = ({
  vehicles,
  setVehicles,
  vehicleIndex,
  setVehicleIndex,
  name,
  control,
  coverType,
  setValue,
  trigger,
  demo,
  handleChangeYourRequirements,
}: IVehicleLookup): JSX.Element => {
  const [overFiftyVehiclesModalActive, setOverFiftyVehiclesModalActive] = useState(false);
  const context = useContext(StepContext);
  const { updateVehicleDetails } = context;
  const classes = useStyles();

  const handleVehicleAdd = useCallback(
    (initialisation = false) => {
      if (vehicles.length >= 50) {
        setOverFiftyVehiclesModalActive(true);
        return;
      }

      const initialValues = [
        ...vehicles,
        {
          reactIndex: vehicleIndex + 1,
          reg: '',
          make: '',
          model: '',
          transmissionType: '',
          fuelType: '',
          year: null,
          apiNoResult: null,
          complete: false,
        },
      ];

      // Update state properties
      if (initialisation === false && demo === false) {
        const objectWithSelectedCoverType = context.data.coverOptions.find((d) => d.coverType === context.data.coverType);
        const vehicleObject = calculateCostTotal(objectWithSelectedCoverType, context.data.vehicleCount + 1);
        updateVehicleDetails(context.data.vehicleCount + 1, vehicleObject.cost, vehicleObject.id);
      }

      setVehicles(initialValues);
      setValue(name, initialValues);
      trigger(name);
      setVehicleIndex(vehicleIndex + 1);
    },
    [setVehicles, vehicleIndex, vehicles, setValue],
  );

  return (
    <>
      <Modal
        heading="If you have 51 or more vehicles, please call us to arrange your breakdown cover."
        open={overFiftyVehiclesModalActive}
        setOpen={setOverFiftyVehiclesModalActive}
        componentType="fragment"
        body={<ContactModalBody />}
      />
      <Typography variant="h4" className={classes.heading}>
        ADD YOUR VEHICLES
      </Typography>
      <Divider className={classes.divider} />
      <Controller name={name} control={control} as={<></>} />
      {vehicles.length === 0 ? (
        <NoVehicles coverType={coverType} />
      ) : (
        vehicles.map((entry: IVehicleData, index: number) => {
          return (
            <Vehicle
              key={index}
              name={name}
              entry={entry}
              index={index}
              vehicles={vehicles}
              setVehicles={setVehicles}
              setValue={setValue}
              vehicleIndex={vehicleIndex}
              setVehicleIndex={setVehicleIndex}
              demo={demo}
              trigger={trigger}
            />
          );
        })
      )}
      <div className={classes.btnContainer}>
        <ButtonSecondary onClick={() => handleVehicleAdd(false)} className={classes.btnAdd} startIcon={<AddIcon />}>
          {vehicles.length === 0 ? 'Add a vehicle' : 'Add another vehicle'}
        </ButtonSecondary>
      </div>
      <Typography className={classes.selection}>YOU&apos;VE CHOSEN {coverType.toUpperCase()}</Typography>
      <div className={classes.changeYourRequirementsContainer}>
        <Typography className={classes.selection}>NOT THE RIGHT COVER?</Typography>
        <ButtonBase className={classes.selection}>
          <Link onClick={handleChangeYourRequirements} className={classes.link}>
            CHANGE YOUR REQUIREMENTS
          </Link>
        </ButtonBase>
      </div>
    </>
  );
};

VehicleLookup.defaultProps = {
  demo: false,
};

export default VehicleLookup;
