import React, { useState, useContext } from 'react';
import { useStyles } from './StyleGuideStyles';
import { StepContext } from '../../contexts/StepContext';
import { Grid, InputLabel, FormLabel, FormControl, Divider } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  ButtonGeneric,
  RadioButtonGroup,
  RadioButtonTabs,
  TextField,
  DocumentTitle,
  Typography,
  SelectDropdown,
  ContactSideBar,
  Checkbox,
  DatePicker,
  Accordion,
  MiniAccordion,
  YourPrice,
  ReCAPTCHA,
} from '../../components/atoms';
import { Modal, PaymentError, VehicleLookup, IVehicleData, IModal, PostcodeLookup } from '../../components/molecules';
import {
  ConfirmationNotice,
  ConfirmationSideBar,
  FinalSummary,
  VehicleCostCalculator,
} from '../../components/organisms';
import styleGuideSchema from './StyleGuideSchema.json';
import styleGuideYupSchema from './StyleGuideYupSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const StyleGuide: React.FC = (): JSX.Element => {
  const [errorBoundaryValue, setErrorBoundaryValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [vehicles, setVehicles] = useState<Array<IVehicleData>>([]);
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const initialSchemaValues = {
    radioBtnTest: '',
    textFieldTest: '',
    textFieldMinMaxTest: 0,
    checkboxTest: false,
    dropdownTest: '',
    datePickerTest: null,
    vehicleLookup: [],
    vehicleCount: 0,
    quoteTotal: 0,
    vehicleId: '',
    postcodeLookup: {
      firstLineOfAddress: '',
      secondLineOfAddress: '',
      town: '',
      county: '',
      postcode: '',
    },
  };

  const { handleSubmit, reset, control, errors, setValue, formState, getValues, trigger, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(styleGuideYupSchema),
    defaultValues: initialSchemaValues,
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const context = useContext(StepContext);
  const { activeStep, updateActiveStep, steps } = context;
  const {
    radioBtnGroupData,
    accordionData,
    dropdownData,
    radioBtnTabsData,
    modalData,
    contactSideBarData,
    yourPriceData,
    confirmationSideBarData,
    finalSummaryData,
  } = styleGuideSchema;

  const onSubmit = (data: React.FormEvent) => alert(JSON.stringify(data, null, 2));
  const handleBack = () => updateActiveStep(activeStep - 1);
  const handleNext = () => updateActiveStep(activeStep + 1);
  const handleShowPostcodeFields = () => setShowAddressFields(true);
  const showErrorBoundary = () => setErrorBoundaryValue(1);
  const plPostcode = watch('postcodeLookup.postcode') as string;
  const classes = useStyles();

  if (errorBoundaryValue === 1) throw new Error('error boundary');

  return (
    <PageTemplate>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <DocumentTitle title="Style Guide" />
          <div className={classes.nextStepBtnContainer}>
            {activeStep === steps.length ? (
              <ButtonPrimary onClick={() => alert('Submitted')}>Submit</ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={handleNext}>Next Step</ButtonPrimary>
            )}
            <ButtonSecondary disabled={activeStep === 1} onClick={handleBack}>Back</ButtonSecondary>
          </div>
          <Divider className="divider"></Divider>

          <div className={classes.btnContainer}>
            <ButtonPrimary className={classes.btn}>Label</ButtonPrimary>
            <ButtonSecondary className={classes.btn}>Label</ButtonSecondary>
            <ButtonTertiary className={classes.btn}>Label</ButtonTertiary>
            <ButtonGeneric className={classes.btn}>Label</ButtonGeneric>
            <form className={classes.formContainer} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Radio Button Label</FormLabel>
                <RadioButtonGroup
                  ariaLabel="radio button group"
                  control={control}
                  name="radioBtnTest"
                  data={radioBtnGroupData}
                />
              </FormControl>
              {errors.radioBtnTest && <p className={classes.error}>{errors.radioBtnTest.message}.</p>}      
              <div className={classes.textFieldContainer}>
                <div>
                  <InputLabel className={classes.inputLabel} htmlFor="textFieldTest">
                    TextField
                  </InputLabel>
                  <TextField id="textFieldTest" name="textFieldTest" control={control} />
                  {errors.textFieldTest && <p className={classes.error}>{errors.textFieldTest.message}</p>}     
                </div>
                <div>
                  <InputLabel className={classes.inputLabel} htmlFor="textFieldMinMaxTest">
                    TextField Min Max
                  </InputLabel>
                  <TextField
                    name="textFieldMinMaxTest"
                    control={control}
                    id="textFieldMinMaxTest"
                    type="number"
                    inputProps={{ min: 0, max: 10, step: 1 }}
                  />
                  {errors.textFieldMinMaxTest && <p className={classes.error}>{errors.textFieldMinMaxTest.message}</p>}
                </div>
              </div>
              <div className={classes.checkboxContainer}>
                <FormControl component="fieldset">
                  <Checkbox
                    name="checkboxTest"
                    control={control}
                    label="I Agree With Terms & Conditions"
                    color="primary"
                  />
                </FormControl>
              </div>
              {errors.checkboxTest && <p className={classes.error}>{errors.checkboxTest.message}</p>}
              <div className={classes.genericContainer}>
                <Accordion heading={accordionData.heading} body={accordionData.body} />
              </div>
              <div className={classes.genericContainer}>
                <MiniAccordion heading="More Info" body="This is more information." />
              </div>
              <div className={classes.genericContainer}></div>
              <SelectDropdown name="dropdownTest" control={control} variant="outlined" data={dropdownData} />
              {errors.dropdownTest && <p className={classes.error}>{errors.dropdownTest.message}</p>}
              <div className={classes.datePickerContainer}>
                <InputLabel htmlFor="datePickerTest">Date Picker</InputLabel>
                <DatePicker
                  className={classes.datePicker}
                  id="datePickerTest"
                  name="datePickerTest"
                  control={control}
                />
                {errors.datePickerTest && <p className={classes.error}>{errors.datePickerTest.message}</p>} 
              </div>
              <div className={classes.postcodeLookupContainer}>
                <Typography className={classes.postcodeLookupContainerField} variant="h5">
                  Postcode Lookup
                </Typography>
                <Grid container alignItems="baseline" justify="flex-start" direction="row">
                  {showAddressFields ? (
                    <>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          FIRST LINE OF ADDRESS
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          id="postcodeLookup.firstLineOfAddress"
                          name="postcodeLookup.firstLineOfAddress"
                          control={control}
                        />
                        {errors.postcodeLookup?.firstLineOfAddress && (
                          <p className={classes.error}>{errors.postcodeLookup?.firstLineOfAddress.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          SECOND LINE OF ADDRESS
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          defaultValue={watch('postcodeLookup.secondLineOfAddress')}
                          id="postcodeLookup.secondLineOfAddress"
                          name="postcodeLookup.secondLineOfAddress"
                          control={control}
                        />
                        {errors.postcodeLookup?.secondLineOfAddress && (
                          <p className={classes.error}>{errors.postcodeLookup?.secondLineOfAddress.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          TOWN
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          id="postcodeLookup.town"
                          name="postcodeLookup.town"
                          control={control}
                        />
                        {errors.postcodeLookup?.town && (
                          <p className={classes.error}>{errors.postcodeLookup?.town.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          COUNTY
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          id="postcodeLookup.county"
                          name="postcodeLookup.county"
                          control={control}
                        />
                        {errors.postcodeLookup?.county && (
                          <p className={classes.error}>{errors.postcodeLookup?.county.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          POSTCODE
                        </InputLabel>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={9}>
                    <TextField
                      className={classes.postcodeLookupContainerField}
                      placeholder="Postcode"
                      id="postcodeLookup.postcode"
                      name="postcodeLookup.postcode"
                      control={control}
                    />
                    {errors.postcodeLookup?.postcode && (
                      <p className={classes.error}>{errors.postcodeLookup?.postcode.message}</p>
                    )}
                     
                  </Grid>
                </Grid>
                <PostcodeLookup
                  onClick={handleShowPostcodeFields}
                  name="postcodeLookup"
                  setValue={setValue}
                  postcode={plPostcode}
                  trigger={trigger}
                />
              </div>
              <div className={classes.recaptcha}>
                <ReCAPTCHA recaptchaToken={recaptchaToken} setRecaptchaToken={setRecaptchaToken} showToken={true} />
              </div>
              <VehicleLookup
                vehicles={vehicles}
                setVehicles={setVehicles}
                vehicleIndex={vehicleIndex}
                setVehicleIndex={setVehicleIndex}
                name="vehicleLookup"
                control={control}
                coverType="National"
                setValue={setValue}
                trigger={trigger}
                demo={true}
              />
              {errors.vehicleLookup && getValues('vehicleLookup').length === 0 ? (
                <p className={classes.error}>You must add at least one vehicle.</p>
              ) : (
                <></>
              )}
              <div className={classes.actionBtnsContainer}>
                <ButtonPrimary disabled={!formState.isValid} className={classes.submitBtn} type="submit">
                  Submit
                </ButtonPrimary>
                <ButtonPrimary onClick={() => reset()}>Reset</ButtonPrimary>
              </div>
            </form>
          </div>
          <div>
            <div className={classes.genericContainer}>
              <RadioButtonTabs data={radioBtnTabsData} />
            </div>
            <Modal
              open={modalOpen}
              setOpen={setModalOpen}
              componentType={modalData.type as IModal['componentType']}
              heading={modalData.heading}
              body={modalData.body}
            />
            <Grid container>
              <VehicleCostCalculator
                name="vehicleCount"
                coverType="National + European"
                watch={watch}
                control={control}
                setValue={setValue}
                trigger={trigger}
              />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar sticky={false} heading={contactSideBarData.heading} callUs={contactSideBarData.callUs} />
            <div className={classes.yourPriceContainer}>
              <YourPrice
                sticky={false}
                heading={yourPriceData.heading}
                cover={yourPriceData.cover}
                price={yourPriceData.price}
                info={yourPriceData.info}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} md={8}>
          <ConfirmationNotice policyStartDate={watch('datePickerTest')} businessContactName="John Doe" />
        </Grid>
        <Grid item xs={12} md={4} className={classes.contactSideMobile}>
          <ConfirmationSideBar
            heading={confirmationSideBarData.heading}
            desc1={confirmationSideBarData.desc1}
            desc2={confirmationSideBarData.desc2}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <PaymentError />
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8}>
          <FinalSummary
            coverType={finalSummaryData.body.coverType}
            tripAmount={finalSummaryData.body.tripAmount}
            travelTo={finalSummaryData.body.travelTo}
            travelDate={finalSummaryData.body.travelDate}
            returnDate={finalSummaryData.body.returnDate}
            cardName={finalSummaryData.body.cardName}
            cardNumber={finalSummaryData.body.cardNumber}
            cardExpiry={finalSummaryData.body.cardExpiry}
            total={finalSummaryData.body.total}
          />
        </Grid>
        <Grid item xs={12} lg={8} className={classes.mt2}>
          <ButtonPrimary onClick={showErrorBoundary}>React Error</ButtonPrimary>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default StyleGuide;
