import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

export const STRIPE_BASE = 'stripeBase';
export const STRIPE_COMPLETE = 'stripeComplete';
export const STRIPE_EMPTY = 'stripeEmpty';
export const STRIPE_FOCUS = 'stripeFocus';
export const STRIPE_INVALID = 'stripeInvalid';
export const STRIPE_WEBKIT_AUTOFILL = 'stripeWebkitAutofill';

export const createOptionsMultiple = (theme: Theme): CreateCSSProperties | CSSProperties => ({
  classes: {
    base: STRIPE_BASE,
    complete: STRIPE_COMPLETE,
    empty: STRIPE_EMPTY,
    focus: STRIPE_FOCUS,
    invalid: STRIPE_INVALID,
    webkitAutofill: STRIPE_WEBKIT_AUTOFILL,
  },
  style: {
    base: {
      iconColor: theme.palette.common.black,
      color: theme.palette.common.black,
      '::placeholder': {
        color: theme.palette.utility.primaryGrey,
        fontWeight: 300,
        textShadow: 'none',
      },
      ':focus': {
        textShadow: `0px 0px 0px ${theme.palette.common.black}`,
      },
    },
    invalid: {
      iconColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
});

export const createOptionsSingular = (theme: Theme): CreateCSSProperties | CSSProperties => ({
  iconStyle: 'solid',
  hidePostalCode: true,
  classes: {
    base: STRIPE_BASE,
    complete: STRIPE_COMPLETE,
    empty: STRIPE_EMPTY,
    focus: STRIPE_FOCUS,
    invalid: STRIPE_INVALID,
    webkitAutofill: STRIPE_WEBKIT_AUTOFILL,
  },
  style: {
    base: {
      iconColor: theme.palette.common.black,
      color: theme.palette.common.black,
      '::placeholder': {
        color: theme.palette.utility.primaryGrey,
        fontWeight: 300,
        textShadow: 'none',
      },
      ':focus': {
        textShadow: `0px 0px 0px ${theme.palette.common.black}`,
      },
    },
    invalid: {
      iconColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
});

export const useStyles = makeStyles((theme: Theme) => ({
  stripeInputMargin: {
    marginBottom: '1rem',
  },
  stripeSingularBtn: {
    marginTop: '1rem',
  },
  container: {
    marginLeft: '25%',
    marginTop: '5%',
    marginRight: '25%',
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 0,
  },
}));
