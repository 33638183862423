import React, { ChangeEvent, FocusEventHandler } from 'react';
import { useStyles, useOutlinedInputStyles } from './SelectDropdownStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Controller, Control } from 'react-hook-form';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';

interface IData {
  value: string | number;
  label: string;
}

interface ISelectDropdownProps {
  id?: string;
  data: Array<IData>;
  placeholder?: string;
  defaultValue?: string;
  helperText?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  name: string;
  control: Control;
  className?: string;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement | undefined>;
}

interface IUncontrolledSelectDropdownProps {
  id?: string;
  data: Array<IData>;
  placeholder?: string;
  value: string;
  helperText?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  name: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement | undefined>;
}

export const SelectDropdown: React.FC<ISelectDropdownProps> = ({
  id,
  variant,
  placeholder,
  defaultValue,
  data,
  name,
  control,
  className,
  onChange,
  onFocus,
}: ISelectDropdownProps) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      as={
        <Select
          id={id}
          placeholder={placeholder}
          defaultValue={defaultValue}
          variant={variant}
          className={clsx(classes.select, className)}
          input={
            <OutlinedInput name="outlined-input-name" id={`outlined-input-id-${name}`} classes={outlinedInputClasses} />
          }
          value={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
        >
          {data.map((option: IData) => (
            <MenuItem style={{ width: '100%' }} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export const UncontrolledSelectDropDown = ({
  id,
  variant,
  placeholder,
  value,
  data,
  name,
  className,
  onChange,
  onFocus,
}: IUncontrolledSelectDropdownProps): JSX.Element => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  return (
    <Select
      id={id}
      placeholder={placeholder}
      variant={variant}
      className={clsx(classes.select, className)}
      input={
        <OutlinedInput name="outlined-input-name" id={`outlined-input-id-${name}`} classes={outlinedInputClasses} />
      }
      value={value}
      onChange={onChange}
      onFocus={onFocus}
    >
      {data.map((option: IData) => (
        <MenuItem style={{ width: '100%' }} key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectDropdown;
