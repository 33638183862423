import React from 'react';
import { Typography, ButtonSecondary } from '../../../../components/atoms';
import { Divider } from '@material-ui/core/';
import { useStyles } from '../../ReviewStyles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

interface IBusinessContact {
    onClick: () => void,
    data: any,
}

const BusinessContact: React.FC<IBusinessContact> = ({ onClick, data }: IBusinessContact): JSX.Element => {
    const classes = useStyles();

    return (
        <div>
            <div>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h3">
                            BUSINESS POINT OF CONTACT
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.backBtn} >
                        <Typography variant="body2" className={classes.text}>
                            <ButtonSecondary onClick={onClick} className={clsx(classes.buttonEdit)}>EDIT</ButtonSecondary>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className="divider"/>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            NAME
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body2" className={classes.textBody2}>
                            {data.businessContactName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            SURNAME
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body2" className={classes.textBody2}>
                            {data.businessContactSurname}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            TELEPHONE NUMBER
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body2" className={classes.textBody2}>
                            {data.businessContactNumber}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4" className="bold">
                            EMAIL ADDRESS
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body2" className={classes.textBody2}>
                            {data.businessContactEmail}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            {data.secondBusinessContactName === '' ?
                (<Divider className="divider2-mb-1"></Divider>) :
                (<div>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="h3">
                                2ND BUSINESS POINT OF CONTACT
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.backBtn} >
                            <Typography variant="body2" className={classes.text}>
                                <ButtonSecondary onClick={onClick} className={clsx(classes.buttonEdit)}>EDIT</ButtonSecondary>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className="divider"/>
                    <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant="h4" className="bold">
                                NAME
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" className={classes.textBody2}>
                                {data.secondBusinessContactName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={clsx(classes.text, classes.textAlign)} spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant="h4" className="bold">
                                SURNAME
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" className={classes.textBody2}>
                                {data.secondBusinessContactSurname}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className="divider2-mb-1"></Divider>
                </div>)
              }
        </div>
    );
};

export default BusinessContact;