import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  checkbox: {
    '& span': {
      '& svg': {
        width: '36px',
        height: '36px',
      },
    },
  },
  postcodeLookupContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  lookupButton: {
    marginTop: '1rem',
  },
  businessInfoField: {
    width: '100%',
  },
}));
