import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useStyles } from './OverTwentyFiveCarsModalBodyStyles';
import { IStepData, StepContext, steps, Step } from '../../../contexts/StepContext';
import { Typography, ButtonPrimary } from '../../../components/atoms';
import { ContactModalBody } from '../ContactModalBody/ContactModalBody';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

interface IOverTwentyFiveCarsModalBody {
  data: IStepData;
  overTwentyFiveCarsContinueData: IStepData;
  setOverTwentyFiveCarsModalActive: Dispatch<SetStateAction<boolean>>;
}

export const OverTwentyFiveCarsModalBody: React.FC<IOverTwentyFiveCarsModalBody> = ({
  data,
  overTwentyFiveCarsContinueData,
  setOverTwentyFiveCarsModalActive,
}: IOverTwentyFiveCarsModalBody) => {
  const context = useContext(StepContext);
  const { activeStep, updateActiveStep, updateData } = context;
  const classes = useStyles();
  const history = useHistory();

  const handleContinue = () => {
    setOverTwentyFiveCarsModalActive(false);
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...overTwentyFiveCarsContinueData,
      coverOptions: data.coverOptions,
      coverStartDate:
      overTwentyFiveCarsContinueData.coverStartDate !== null ? format(new Date(overTwentyFiveCarsContinueData.coverStartDate), 'yyyy/MM/dd') : overTwentyFiveCarsContinueData.coverStartDate,
    });
    history.push(steps[Step.ASSUMPTIONS].url);
  };

  return (
    <>
      <Typography>
        Clients who have over 25 cars may wish to ring one of our consultants on the number below.
      </Typography>
      <ContactModalBody />
      <ButtonPrimary onClick={handleContinue} className={classes.continueBtn}>Continue</ButtonPrimary>
    </>
  );
};

export default OverTwentyFiveCarsModalBody;
