import React, { useContext } from 'react';
import { useStyles } from './NavBarStyles';
import { StepContext, steps } from '../../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { AppBar, Grid, Container } from '@material-ui/core';
import { NavStepper, YourPrice } from '../../atoms';
import greenFlagLogo from '../../../assets/img/logo/GF-logo-on-black-bg.svg';

export const NavBar: React.FC = () => {
  const context = useContext(StepContext);
  const { data } = context;
  const classes = useStyles();
  const history = useHistory();

  const arrayOfSteps = [...steps];
  arrayOfSteps.shift();
  const showYourPriceComponent = arrayOfSteps.map((s) => '/' + s.label.toLowerCase()).includes(history.location.pathname);

  return (
    <AppBar position="static">
      <Container className={classes.container}>
        <Grid container>
          <Grid item className={classes.greenFlagLogoGridContainer} xs={12} lg={12}>
            <img src={greenFlagLogo} alt="Green Flag" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NavStepper />
        </Grid>
      </Container>
      <Grid container className={classes.dOnlyMobile}>
        <Grid item xs={12}>
          {showYourPriceComponent ? (
            <YourPrice
              sticky={false}
              heading="Your Price: "
              cover={data.coverType}
              price={`£${data.quoteTotal.toFixed(2)}`}
              info="Includes Insurance Premium Tax where applicable"
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default NavBar;
