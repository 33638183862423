import React from 'react';
import { Checkbox as MaterialUiCheckbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { Controller, Control } from 'react-hook-form';

interface Checkbox extends CheckboxProps {
  name: string;
  label?: string;
  control: Control;
  checkboxClassName?: string;
}

const Checkbox = (props: Checkbox): JSX.Element => {
  const { name, label, disabled, control, color, checkboxClassName, defaultValue } = props;
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={(props) => (
            <MaterialUiCheckbox
              className={checkboxClassName}
              color={color}
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
              disabled={disabled}
            />
          )}
        />
      }
      label={label}
    />
  );
};

Checkbox.defaultProps = {
  label: '',
  color: 'primary',
  defaultChecked: false,
  inputProps: null,
  checked: false,
};

export default Checkbox;
