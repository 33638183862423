import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    padding: '1.5rem',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  textWhite: {
    color: theme.palette.common.white,
  },
  px1: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  body5: theme.bodyStyles.body5,
}));
