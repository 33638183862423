import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    body: {
      margin: '1rem 0',
      fontSize: '1.2rem',
    },
  }),
);
