import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  active: {
    color: theme.palette.green.main
  },
  icon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '2rem',
    left: '-1.75rem',
  },
  selectDropDown: {
    marginBottom: '1rem'
  },
  stateEditorContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  tooltipContainer: {
    margin: '0 0.5rem'
  },
  inputLabel: {
    marginBottom: '0.5rem'
  },
  saveBtn: {
    marginTop: '1rem'
  },
  '@media (max-width: 1279px)': {
    icon: {
      position: 'absolute',
      top: '3rem',
      left: 'calc(100% - 2rem)',
    }
  }
}));

export const jsonInputStyle = {
  body: {
    maxHeight: '25rem',
    overflow: 'auto',
  },
  container: {
    height: 'auto',
    width: 'auto',
    overflow: 'auto',
  },
  outerBox: {
    height: 'auto',
    width: 'auto',
  },
};