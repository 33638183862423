import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  vehicleInformationContainer: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    marginBottom: '1rem',
    '@media (min-width: 625px)': {
      marginTop: '-0.4rem',
      '&:before': {
        top: '-14px',
        left: '240px',
        position: 'relative',
        width: 0,
        border: '16px solid transparent',
        height: 0,
        content: '" "',
        borderBottomColor: theme.palette.utility.focusBlue,
      },
    },
  },
  vehicleInformation: {
    padding: '0.4rem',
    backgroundColor: theme.palette.utility.focusBlue,
    '@media (max-width: 625px)': {
      marginTop: '1rem',
    },
  },
  vehicleInformationList: {
    display: 'flex',
    listStyleType: 'none',
    paddingLeft: '0.5rem',
    '@media (max-width: 840px)': {
      flexDirection: 'column',
    },
  },
  vehicleInformationEntry: {
    fontFamily: 'Oswald',
    paddingRight: '1rem',
    '@media (max-width: 840px)': {
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: 0,
      }
    },
  },
  vehicleInformationConfirm: {
    backgroundColor: theme.palette.utility.vehicleInformationConfirmBg,
    padding: '1rem',
  },
  confirmHeading: {
    marginBottom: '1rem',
    fontWeight: 700,
  },
  vehicleInfoForm: {
    marginTop: '1rem',
  },
  vehicleInfoFormHeader: {
    fontWeight: 700,
    marginBottom: '1.5rem',
  },
  vehicleInfoFormGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  btnMargin: {
    marginLeft: '1rem',
  },
  bold: { fontWeight: 700 },
  inputLabel: {
    color: theme.palette.common.black,
    fontSize: '1.3rem',
    fontWeight: 600,
    '@media (max-width: 600px)': {
      fontSize: '1rem',
      marginBottom: '1rem',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    margin: 0,
    padding: '1rem',
    backgroundColor: theme.palette.utility.errorTextBg,
    borderColor: theme.palette.utility.errorTextBorderColor,
  },
  vehicleDetailsSubmitBtn: {
    display: 'flex',
    marginLeft: 'auto',
    '@media (max-width: 600px)': {
      marginTop: '1rem',
    },
  },
  vehicleInfoFormTextField: {
    width: '100%',
  },
  vehicleInfoFormDropdown: {
    width: '100%'
  },
  modelGrid: {
    marginBottom: '1.5rem'
  }
}), { index: 1 });
