import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  rogue3: theme.rogueStyles.rogue3,
  rogue4: theme.rogueStyles.rogue4,
  stepperContainer: {
    display: 'flex',
    paddingLeft: 0,
    margin: '1rem 0 0 0',
    '@media (max-width: 1279px)': {
      marginTop: '0.85rem',
    },
    '@media (max-width: 855px)': {
      justifyContent: 'space-around',
    },
  },
  stepper: {
    display: 'flex',
    maxWidth: '1280px',
    margin: '0 auto',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.utility.primaryGrey,
    textTransform: 'uppercase',
    '@media (max-width: 855px)': {
      display: 'block',
      justifyContent: 'space-around',
    },
    '& .active': {
      color: theme.palette.common.white,
    },
  },
  step: {
    marginRight: '1rem',
    marginTop: 0,
    listStyleType: 'none',
    fontWeight: 300,
    '@media (max-width: 1070px)': {
      fontSize: '1rem',
    },
    '@media (max-width: 973px)': {
      fontSize: '0.85rem',
    },
  },
  stepActive: {
    borderBottom: `3px solid ${theme.palette.green.main}`,
  },
  stepSpanActive: {
    color: theme.palette.green.main,
    fontWeight: 700,
  },
}));
