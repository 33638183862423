import React, { useContext } from 'react';
import { StepContext } from '../../../contexts/StepContext';
import { useStyles } from './StateTrackerStyles';
import clsx from 'clsx';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

const StateTracker: React.FC = () => {
  const context = useContext(StepContext);
  const { data } = context;
  const classes = useStyles();

   const showState = () => {
    const jsonData = JSON.stringify(data, null, 2);
    const x = window.open();
      if (x !== null) {
        x.document.open();
        x.document.write('<html><body><pre>' + jsonData + '</pre></body></html>');
        x.document.close();
      }
  };

  return (
      <SettingsApplicationsIcon
        className={clsx(classes.icon)}
        onClick={showState}
      />
  );
};

export default StateTracker;
