import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import { useStyles, TextFieldBase } from './TextFieldStyles';
import { Controller, Control } from 'react-hook-form';
import clsx from 'clsx';
import { FocusEventHandler } from 'react';

interface ITextField {
  id?: string;
  style?: React.CSSProperties;
  type?: string;
  name: string;
  control: Control;
  className?: string;
  placeholder?: string;
  uppercase?: boolean;
  inputProps?: any;
  stripeInputProps?: any;
  disabled?: boolean;
  defaultValue?: string | number;
  autoComplete?: string;
  inputPropsClassName?: string;
  fullWidth?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLDivElement | HTMLInputElement | undefined>;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement | undefined>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement | undefined>;
}

interface IUncontrolledTextField {
  id?: string;
  style?: React.CSSProperties;
  type?: string;
  className?: string;
  placeholder?: string;
  uppercase?: boolean;
  inputProps?: any;
  stripeInputProps?: any;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  value?: any;
  defaultValue?: any;
  forComponent?: 'vehicle-lookup' | 'vehicle-lookup123';
  fullWidth?: boolean;
  onPaste?: any;
}

export const TextField: React.FC<ITextField> = ({
  id,
  style,
  type,
  placeholder,
  name,
  control,
  className,
  inputProps,
  disabled,
  defaultValue,
  autoComplete,
  inputPropsClassName,
  stripeInputProps,
  fullWidth,
  onChange,
  onKeyPress,
  onKeyDown,
  onFocus,
}: ITextField) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      as={
        <TextFieldBase
          id={id}
          style={style}
          type={type}
          className={clsx(classes.body, className)}
          inputProps={{ ...inputProps, className: inputPropsClassName }}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          fullWidth={fullWidth}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          {...stripeInputProps}
        />
      }
    />
  );
};

export const UncontrolledTextField: React.FC<IUncontrolledTextField> = ({
  id,
  style,
  type,
  placeholder,
  className,
  inputProps,
  stripeInputProps,
  onChange,
  disabled,
  value,
  forComponent,
  onPaste
}: any) => {
  const classes = useStyles();

  return (
    <TextFieldBase
      onChange={onChange}
      id={id}
      style={style}
      type={type}
      className={clsx(classes.body, className)}
      placeholder={placeholder}
      inputProps={{ ...inputProps, className: forComponent === 'vehicle-lookup' ? classes.vehicleLookup : '' }}
      disabled={disabled}
      value={value}
      onPaste={onPaste}
      {...stripeInputProps}
    />
  );
};

export default TextField;
