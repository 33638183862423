import React, { useState } from 'react';
import { useStyles } from './VehicleDeleteModalStyles';
import { Typography, ButtonSecondary } from '../../../atoms';
import { Modal } from '../../../molecules';
import ClearIcon from '@material-ui/icons/Clear';
import { ButtonBase } from '@material-ui/core';

interface IVehicleDeleteModal {
  handleRemove: () => void;
}

export const VehicleDeleteModal: React.FC<IVehicleDeleteModal> = ({
  handleRemove,
}: IVehicleDeleteModal): JSX.Element => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemoval = () => {
    handleRemove();
    setOpen(false);
  };

  const content = (
    <div>
      <Typography className={classes.body}>
        This vehicle will be removed and won&apos;t be covered in the event of a breakdown.
      </Typography>
      <div className={classes.btnContainer}>
        <ButtonSecondary onClick={handleRemoval}>YES, REMOVE</ButtonSecondary>
        <ButtonSecondary className={classes.cancelBtn} onClick={handleClose}>CANCEL</ButtonSecondary>
      </div>
    </div>
  );

  return (
    <>
      <ButtonBase onClick={handleOpen} className={classes.clearBtn}>
        <ClearIcon onClick={handleOpen} className={classes.clearIcon} /> REMOVE
      </ButtonBase>
      <Modal open={open} setOpen={setOpen} heading="ARE YOU SURE?" body={content} componentType="fragment" />
    </>
  );
};

export default VehicleDeleteModal;
