import React from 'react';
import { useStyles } from './NavStepperStyles';
import { StepContext } from '../../../contexts/StepContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

export const NavStepper: React.FC = () => {
  const classes = useStyles();
  const context = React.useContext(StepContext);
  const { activeStep, steps } = context;

  const numberedView = useMediaQuery('(max-width:855px)');

  if (numberedView) {
    return (
      <div className={classes.root}>
        <div className={classes.stepper}>
          <ul className={classes.stepperContainer}>
            {steps.map((_, index: number) => (
              <li
                key={index}
                className={
                  activeStep === index + 1
                    ? clsx(classes.step, classes.stepActive, classes.rogue3, 'active')
                    : clsx(classes.step, classes.rogue4)
                }
              >
                {index + 1}.{' '}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.stepper}>
        <ul className={classes.stepperContainer}>
          {steps.map((step: { label: string, url: string }, index: number) => (
            <li
              key={index}
              className={
                activeStep === index + 1
                  ? clsx(classes.step, classes.stepActive, classes.rogue3, 'active')
                  : clsx(classes.step, classes.rogue4)
              }
            >
              {index + 1}. <span className={activeStep === index + 1 ? classes.stepSpanActive : ''}>{'// '}</span>
              <span>{step.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavStepper;
