import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    subTitle: {
      marginTop: '1rem',
      marginBottom: '0.5rem',
    },
    gridSecond: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
    },
    inputLabel: {
      ...theme.rogueStyles.rogue3,
      color: theme.palette.common.black,
      marginBottom: '.25rem',
      marginTop: '.25rem',
      textTransform: 'uppercase'
    },
    postcodeLookupGrid: {
      marginTop: '1rem',
    },
    postcodeLookupContainer: {
      margin: '1rem 0',
    },
    postcodeLookupContainerField: {
      width: '15rem',
    },
    cardSecurityNumberGridContainer: {
      alignItems: 'flex-end'
    },
    cardSecurityNumberIcon: {
      marginLeft: '0.25rem'
    }
  }),
);
