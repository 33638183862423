import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  sticky: {
    position: 'sticky',
    display: 'block',
    top: 0,
    zIndex: 1,
  },
  mainContent: {
    flex: 1,
  },
}));
