import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  heading: {
    marginBottom: '1rem'
  },
  radioBtnGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  stateComponentContainer: {
    position: 'absolute',
    top: '18%',
    right: '18%',
  }
}));