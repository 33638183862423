import React, { useContext, useState } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  ButtonSecondary,
  StateTracker,
  StateEditor,
  SummaryTotal,
  ReCAPTCHA,
  ButtonPrimary,
} from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './SummaryStyles';
import { StepContext, IStepData, steps, Step, summaryData } from '../../contexts/StepContext';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FinalSummary } from '../../components/organisms';
import summaryMockData from './SummaryMockData.json';

export const Summary: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  const schema = yup.object().shape({});

  const { handleSubmit, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: summaryData,
    shouldFocusError: true,
    shouldUnregister: true,
  });

  // This might not be needed
  const getSelectedCoverIndex = (data: IStepData, coverType: string): number => {
    return data.coverOptions.map(c => c.coverType).indexOf(coverType);
  };

  const onSubmit = (stepData: IStepData) => {

    if (data.coverStartDate == null || data.expiryDate == null) return;

    const orderObject = {
      'order': {
        'quote': {
          'showDemandsAndNeeds': [
            null,
            data.moreThanFiftyVehicles === 'true',
            data.sameLevelOfCover === 'true',
            data.breakdownCoverLocation === 'THE UK'
          ],
          'quoteTotal': data.quoteTotal,
          'coverOptions': data.coverOptions,
          'selectedCover': getSelectedCoverIndex(data, data.coverType),
          'startDate': new Date(data.coverStartDate).toISOString()
        },
        'vehicles': {
          'vehicleType': data.coverType,
          'vehicles': data.vehicles,
        },
        'businessAddress': {
          'companyName': data.companyName,
          'postcode': data.address.postcode,
          'firstLine': data.address.firstLineOfAddress,
          'secondLine': data.address.secondLineOfAddress,
          'town': data.address.town,
          'county': data.address.county
        },
        'businessContacts': [
          {
            name: data.businessContactName,
            telephoneNumber: `${data.businessContactNumber}`,
            emailAddress: data.businessContactEmail,
            surname: data.businessContactSurname,
          },
          {
            name: data.secondBusinessContactName,
            surname: data.secondBusinessContactSurname
          }
        ],
        'cardDetails': {
          'cardHolderName': data.cardholdersName,
          'cardNumber': `${data.cardNumber}`,
          'cardType': data.cardType,
          'cardEndDate': data.expiryDate,
          'cardSecurityNumber': `${data.cardSecurityNumber}`,
          'cardHolderAddress1': data.billingAddressIsSameAsPreviousSection ? data.address.firstLineOfAddress : data.postcodeLookup.firstLineOfAddress,
          'cardHolderAddress2': data.billingAddressIsSameAsPreviousSection ? data.address.secondLineOfAddress : data.postcodeLookup.secondLineOfAddress,
          'cardHolderCounty': data.billingAddressIsSameAsPreviousSection ? data.address.county : data.postcodeLookup.county,
          'cardHolderTown': data.billingAddressIsSameAsPreviousSection ? data.address.town : data.postcodeLookup.town,
          'cardHolderPostcode': data.billingAddressIsSameAsPreviousSection ? data.address.postcode : data.postcodeLookup.postcode
        },
        'captchaValue': recaptchaToken
      }
    };

    console.info(orderObject);
    updateActiveStep(activeStep + 1);
    updateData({ ...data, ...stepData });
    history.push(steps[Step.CONFIRMATION].url);
  };

  const handleBack = () => {
    updateActiveStep(activeStep - 1);
    history.push(steps[Step.PAYMENT].url);
  };

  const [recaptchaToken, setRecaptchaToken] = useState('');

  return (
    <PageTemplate>
      <StepChecker/>
      <DocumentTitle title="DLG Fleet - Summary" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.gridMainDiv}>
              <Typography className="stepHeading" variant="h1">
                STEP 7 - FINAL SUMMARY
              </Typography>
              <FinalSummary
                coverType={data.coverType}
                tripAmount={summaryMockData.tripAmount}
                travelTo={summaryMockData.travelTo}
                travelDate={summaryMockData.travelDate}
                returnDate={summaryMockData.returnDate}
                cardName={data.cardholdersName}
                cardNumber={data.cardNumber}
                cardExpiry={data.expiryDate}
                total={`£${data.quoteTotal.toFixed(2)}`}
              />
              <div className={classes.recaptchaContainer}>
                <ReCAPTCHA recaptchaToken={recaptchaToken} setRecaptchaToken={setRecaptchaToken} />
              </div>
              <Divider className="divider2-my-1"></Divider>
              <div className="stepBtnContainer">
                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
                <ButtonPrimary disabled={!formState.isValid || recaptchaToken === ''} type="submit">Pay</ButtonPrimary>
              </div>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <SummaryTotal vehicleCount={data.vehicleCount} coverType={data.coverType} price={`£${data.quoteTotal.toFixed(2)}`} />
            <StateTracker />
            <StateEditor />
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Summary;
