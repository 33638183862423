import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Typography } from '../../atoms/Typography';
import { useStyles } from './YourPriceStyles';
import clsx from 'clsx';

interface IYourPrice {
  heading?: string;
  cover: string;
  price: string;
  sticky?: boolean;
  info?: string;
}

const YourPrice: React.FC<IYourPrice> = ({ heading, cover, price, info, sticky }: IYourPrice) => {
  const classes = useStyles();

  return (
    <div className={sticky === true ? classes.sticky : ''}>
      <Grid>
        <Container className={clsx(classes.paper, classes.dropShadow)}>
          <div className={classes.mobileGroup}>
            {/* mobile group */}
            <Typography variant="h3" className={classes.textUppercase}>{cover}</Typography>
            <div className={classes.dFlex}>
              <Typography variant="h3" className={clsx(classes.pr1, classes.textUppercase)}>{heading}</Typography>
              <Typography className={clsx(classes.wordBreak, classes.mobileText)}>{price}</Typography>
            </div>
            <Typography className={clsx(classes.body)}>{info}</Typography>
          </div>
          <div className={classes.tabletGroup}>
            {/* tablet group */}
            <Typography variant="h3">{heading}</Typography>
            <Typography variant="h3">{cover}</Typography>
            <Typography className={clsx(classes.wordBreak, classes.tabletText)}>{price}</Typography>
            <Typography className={clsx(classes.body)}>{info}</Typography>
          </div>
        </Container>
      </Grid>
    </div>
  );
};

export default YourPrice;
