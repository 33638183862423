import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    select: {
      minWidth: 120,
    },
  }),
);

export const useOutlinedInputStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.utility.greyDark,
    },
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${theme.palette.utility.greyDark}`,
      borderColor: theme.palette.utility.greyDark,
      maxWidth: 120,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.common.black,
    },
    '&$focused $notchedOutline': {
      boxShadow: `${theme.palette.common.black} 0 0 0 1px`,
      border: `1px solid ${theme.palette.utility.greyDark}`,
      borderColor: `${theme.palette.utility.focusBlue}`,
    },
  },
  focused: {},
  notchedOutline: {},
}), { index: 1 });
