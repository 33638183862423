import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.green.main,
    padding: '1.5rem',
    color: theme.palette.common.black,
  },
  wordBreak: {
    wordWrap: 'break-word',
  },
  sticky: {
    position: 'sticky',
    display: 'block',
    top: '1rem',
  },
  mobileText: {
    display: 'none',
    ...theme.rogueStyles.rogue2Mobile,
    '@media (max-width: 426px)': {
      display: 'block',
    },
  },
  mobileGroup: {
    display: 'none',
    '@media (max-width: 426px)': {
      display: 'block',
    },
  },
  tabletText: {
    ...theme.rogueStyles.rogue1,
    '@media (max-width: 426px)': {
      display: 'none',
    },
  },
  tabletGroup: {
    '@media (max-width: 426px)': {
      display: 'none',
    },
  },
  dFlex: {
    display: 'flex',
    alignItems: 'baseline',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  pr1: {
    paddingRight: '1rem',
  },
  body: theme.bodyStyles.body5,
  dropShadow: theme.shadowStyles.masterDropShadow,
}));
