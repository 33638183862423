import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  vehicleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    '@media (max-width: 750px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  vehicleSearch: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      alignItems: 'start',
      '& div': {
        marginLeft: 0,
      },
      '& p': {
        marginBottom: '0.75rem',
      },
    },
    '@media (max-width: 750px)': {
      marginRight: 'auto',
    },
  },
  vehicleBtnOptions: {
    marginLeft: '1rem',
    '@media (max-width: 750px)': {
      marginTop: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 0,
    },
  },
  vehicleTextField: {
    marginLeft: '1rem',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  vehicleDeleteDesktop: {
    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  vehicleDeleteMobile: {
    '@media (min-width: 750px)': {
      display: 'none',
    },
    '& button': {
      marginTop: '0.75rem',
      marginLeft: 0,
    },
  },
  clearBtn: {
    marginLeft: 0,
  },
}));
