import { withStyles, ListItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const StyledListItem = withStyles((theme: Theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.green.main,
      '&:hover': {
        backgroundColor: theme.palette.utility.greenHover,
      },
    },
  },
  button: {
    '&:focus': {
      backgroundColor: theme.palette.utility.focusBlue,
    },
  },
}))(ListItem);

export const useStyles = makeStyles((theme: Theme) => ({
  modalHeading: {
    ...theme.bodyStyles.body1,
    display: 'flex',
    alignItems: 'center',
  },
  modalContainer: {
    maxWidth: '777px',
  },
  resultsContainer: {
    '@media (max-width: 400px)': {
      marginTop: '0.5rem',
    },
  },
  list: {
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.utility.greyLight,
    minWidth: '700px',
    minHeight: '150px',
    maxHeight: '300px',
    overflow: 'auto',
    marginBottom: '1rem',
    '@media (max-width: 850px)': {
      minWidth: '400px',
    },
    '@media (max-width: 500px)': {
      minWidth: 'inherit',
    },
  },
  information: {
    marginBottom: '0.5rem',
  },
  actionButtonsContainer: {
    '& button:first-child': {
      marginRight: '1rem',
    },
    '@media (max-width: 400px)': {
      display: 'flex',
      flexDirection: 'column',
      '& button:first-child': {
        marginRight: 0,
        marginBottom: '1rem',
      },
    },
  },
}));
