import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontWeight: 700
  },
  sideBarComp: {
    padding: '0 1rem 1rem 1rem',
  },
  sideBarDesc: {
    paddingTop: '1rem',
  },
  contentSpacers: {
    borderTop: `2px solid ${theme.palette.common.black}`,
  },
  subHeading: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  policyContainer: {
    marginLeft: '0.5rem'
  },
  policyStarts: theme.bodyStyles.body2,
  policyStartDate: {
    fontWeight: 700
  },
  img: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '2rem',
    maxWidth: '2rem',
  },
}));
