import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from './EmailMeThisQuoteConfirmationModalBodyStyles';
import { Typography, ButtonPrimary } from '../../../components/atoms';

interface IEmailMeThisQuoteConfirmationModalBody {
  setEmailMeThisQuoteConfirmationModalActive: Dispatch<SetStateAction<boolean>>;
}

export const EmailMeThisQuoteConfirmationModalBody: React.FC<IEmailMeThisQuoteConfirmationModalBody> = ({
  setEmailMeThisQuoteConfirmationModalActive,
}: IEmailMeThisQuoteConfirmationModalBody): JSX.Element => {
  const classes = useStyles();

  const handleCloseModal = () => {
    setEmailMeThisQuoteConfirmationModalActive(false);
  };

  return (
    <>
      <Typography className={classes.paragraph}>
        Your quote is making its way to the email address provided. If you can&apos;t find it, please check your spam
        folder as it might have been accidentally placed there.
      </Typography>
      <Typography className={classes.paragraph}>
        If you&apos;d like, you can return to your quote using the button below.
      </Typography>
      <ButtonPrimary onClick={handleCloseModal}>Back to Quote</ButtonPrimary>
    </>
  );
};

export default EmailMeThisQuoteConfirmationModalBody;
