import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    color: theme.palette.common.black,
    paddingBottom: '0.7rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '1.3rem',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  vehicleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  vehicleTextField: {
    marginLeft: '1rem',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  btnAdd: {
    marginRight: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  noVehicles: {
    marginTop: '1rem',
    ...theme.bodyStyles.body3,
  },
  selection: {
    ...theme.bodyStyles.body5,
    fontWeight: 700,
  },
  changeYourRequirementsContainer: {
    display: 'flex',
    '& p': {
      marginRight: '0.3rem',
    },
    '& button': {
      '&:focus': {
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineColor: `${theme.palette.utility.focusBlue}`,
        outlineOffset: '2px',
      },
    }
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.utility.greenHover,
      cursor: 'pointer',
    },
  },
}));
