import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles } from './EmailMeThisQuoteModalBodyStyles';
import { Typography, TextField, ButtonPrimary, ReCAPTCHA } from '../../../components/atoms';
import { IStepData } from '../../../contexts/StepContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format } from 'date-fns';
import axios from 'axios';

interface IEmailMeThisQuoteModalBody {
  formData: IStepData;
  emailAddress: string;
  setEmailAddress: Dispatch<SetStateAction<string>>;
  setEmailMeThisQuoteModalActive: Dispatch<SetStateAction<boolean>>;
  setEmailMeThisQuoteConfirmationModalActive: Dispatch<SetStateAction<boolean>>;
}

export const EmailMeThisQuoteModalBody: React.FC<IEmailMeThisQuoteModalBody> = ({
  formData,
  emailAddress,
  setEmailAddress,
  setEmailMeThisQuoteModalActive,
  setEmailMeThisQuoteConfirmationModalActive,
}: IEmailMeThisQuoteModalBody): JSX.Element => {
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();

  const schema = yup.object().shape({
    email: yup.string().email('Invalid Email.').required('Invalid Email.'),
  });

  const { handleSubmit, control, errors, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: emailAddress,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async ({ email }): Promise<void> => {
    if (!submitting) {
      setSubmitting(true);
      try {
        await axios.post(`${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_QUOTE_POST_ENDPOINT}`, {
          Item: {
            ...formData,
            coverStartDate: formData.coverStartDate !== null ? format(new Date(formData.coverStartDate), 'yyyy/MM/dd') : null,
            email,
            captchaValue: recaptchaToken
          },
        });
        setEmailAddress(email);
        setEmailMeThisQuoteModalActive(false);
        setEmailMeThisQuoteConfirmationModalActive(true);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Typography>
        Enter your email address into the box below, hit submit, and a quote for your business breakdown cover will be
        sent to your inbox. We won&apos;t share your email address with anyone.
      </Typography>
      <div className={classes.emailFieldContainer}>
        <TextField
          inputPropsClassName={classes.emailTextField}
          name="email"
          placeholder={'Type your email address here'.toUpperCase()}
          control={control}
          defaultValue={emailAddress}
          type="email"
          autoComplete="off"
        />
        {errors.email && (
          <Typography className={classes.errorText}>{errors.email.message}</Typography>
        )}
        <div className={classes.recaptchaContainer}>
          <ReCAPTCHA recaptchaToken={recaptchaToken} setRecaptchaToken={setRecaptchaToken} />
        </div>
        <div className={classes.emailMeThisQuoteBtnContainer}>
          <ButtonPrimary disabled={!formState.isValid || recaptchaToken === ''} onClick={handleSubmit(onSubmit)}>Submit</ButtonPrimary>
        </div>
      </div>
    </>
  );
};

export default EmailMeThisQuoteModalBody;
