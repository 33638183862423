import React from 'react';
import { Container, Grid } from '@material-ui/core/';
import { Typography } from '../../atoms';
import { useStyles } from './ContactSideBarStyles';
import contactData from './ContactData.json';

interface IContactSideBar {
  heading?: string;
  callUs?: string;
  sticky?: boolean;
}

interface IOpeningHours {
  date: string;
  time: string;
}

interface IOpeningHoursData {
  openingHoursData: Array<IOpeningHours>;
}

const OpeningHours = ({ openingHoursData }: IOpeningHoursData): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.openingHoursContainer}>
      <div>
        {openingHoursData.map((entry: IOpeningHours, index: number) => {
          return (
            <div className={classes.pr1} key={index}>
              <p className={classes.body5}>
                {entry.date} {entry.time}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ContactSideBar: React.FC<IContactSideBar> = ({ heading, callUs, sticky }: IContactSideBar) => {
  const { openingHoursData } = contactData;
  const classes = useStyles();

  return (
    <div className={sticky === true ? classes.sticky : classes.dNoneMobile}>
      <Grid container>
        <Container className={classes.paper}>
          <div className={classes.uppercase}>
            <Typography variant="h3">{heading}</Typography>
            <Typography className={classes.callUsOn} variant="h3">
              Call us on{' '}
              <a className={classes.anchorStyling} href={`tel:${callUs}`}>
                {callUs}
              </a>
            </Typography>
          </div>
          <div>
            <OpeningHours openingHoursData={openingHoursData} />
          </div>
        </Container>
      </Grid>
    </div>
  );
};

ContactSideBar.defaultProps = {
  heading: 'NEED HELP?',
  callUs: contactData.contactNumber,
  sticky: true,
};

export default ContactSideBar;
