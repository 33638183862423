import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    width: '100%',
    paddingBottom: '2rem',
  },
  container: {
    maxWidth: '1280px',
    padding: '0 1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1.5rem',
    },
  },
  gridContainer: {
    marginTop: '1rem',
    paddingLeft: '12px',
    '@media (min-width: 1280px)': {
      padding: 0,
    },
    '&:first-child > div': {
      padding: 0,
    },
  },
  copyrightBannerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '1.5rem',
    paddingBottom: '0.25rem',
  },
  greenFlagLogo: {
    width: '57px',
  },
  copyrightText: {
    color: theme.palette.green.main,
    fontSize: '0.95rem',
    marginLeft: '1rem',
  },
  notice: {
    color: theme.palette.utility.greyLight,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '22px',
    },
  },
  logoAndCookiesNoticeContainer: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 380px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& button': {
            marginTop: '1rem'
        }
    },
  },
  mastercardLogo: {
    marginRight: '30px',
  },
  body5: theme.bodyStyles.body5,
  footerLinks: {
    marginTop: '10px',
    padding: '10px 0',
    listStyle: 'none',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    '& li': {
      '& a': {
        color: theme.palette.utility.cookiesNotice,
        textDecoration: 'none',
        margin: 0,
        padding: 0,
        width: '99%',
        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
      },
    },
  },
  manageCookiesBtn: {
    fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: theme.palette.utility.manageCookiesBtnGreen,
    border: `1px solid ${theme.palette.utility.manageCookiesBtnGreen}`,
    height: 'auto',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    padding: '0.8em 2em',
    lineHeight: 1.2,
    mozTransition: '0.1s ease',
    oTransition: '0.1s ease',
    webkitTransition: '1s ease',
    transition: '0.1s ease',
    fontWeight: 700,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.utility.manageCookiesBtnGreen,
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '5px auto -webkit-focus-ring-color'
    }
  },
  cookiesNotice: {
    fontFamily: ['Oswald', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
}));
