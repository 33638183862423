import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      clearBtn: {
        fontSize: '1rem',
        marginLeft: '1rem',
        '&:focus': {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: theme.palette.utility.focusBlue,
          outlineOffset: '4px',
        },
      },
      clearIcon: {
        fontSize: '1.75rem',
      },
      body: {
        margin: '1rem 0',
        fontSize: '1.2rem',
      },
      btnContainer: {
        marginBottom: '1rem',
      },
      cancelBtn: {
        marginLeft: '1rem',
      },
    }),
  { index: 1 },
);
