import React from 'react';
import { useStyles } from './RadioButtonGroupStyles';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Controller, Control } from 'react-hook-form';
import clsx from 'clsx';

interface IData {
  label: string;
  value: string | number;
}
interface IRadioButtonGroup {
  ariaLabel?: string;
  style?: React.CSSProperties;
  data: Array<IData>;
  name: string;
  control: Control;
  rules?: any;
  inputLabelStyle?: any;
  className?: string;
  onChange?: any;
  defaultValue?: string;
  onClick?: (e) => void;
}

export const RadioButtonGroup: React.FC<IRadioButtonGroup> = ({
  ariaLabel,
  style,
  data,
  name,
  control,
  rules,
  className,
  onChange,
  onClick,
  defaultValue,
}: IRadioButtonGroup) => {
  const classes = useStyles();
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        as={
          <RadioGroup name={name} className={className} aria-label={ariaLabel}>
            {data.map((entry: IData, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  value={entry.value}
                  style={style}
                  className={clsx(classes.root, classes.body)}
                  control={<Radio color="primary" onChange={onChange}/>}
                  label={entry.label}
                  onClick={onClick}
                />
              );
            })}
          </RadioGroup>
        }
      />
    </>
  );
};

export default RadioButtonGroup;
