import React from 'react';
import theme from './theme';
import './fonts.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { StepProvider, steps, Step } from './contexts/StepContext';
import {
  Quote,
  Assumptions,
  VehicleDetails,
  BusinessDetails,
  Review,
  Payment,
  Summary,
  Confirmation,
  StyleGuide,
  // Stripe
} from './pages';
import GlobalCss from './globalCss';
import { StylesProvider } from '@material-ui/core/styles';
import { ErrorBoundary } from './components/atoms/ErrorBoundary';

// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <GlobalCss />
          {/* <Elements stripe={stripePromise}> */}
          <ErrorBoundary>
            <StepProvider>
              <Switch>
                <Route exact path="/" component={Quote} />
                <Route exact path={`${steps[Step.QUOTE].url}/:id`} component={Quote} />
                <Route exact path={steps[Step.ASSUMPTIONS].url} component={Assumptions} />
                <Route exact path={steps[Step.VEHICLE_DETAILS].url} component={VehicleDetails} />
                <Route exact path={steps[Step.BUSINESS_DETAILS].url} component={BusinessDetails} />
                <Route exact path={steps[Step.REVIEW].url} component={Review} />
                <Route exact path={steps[Step.PAYMENT].url} component={Payment} />
                <Route exact path={steps[Step.SUMMARY].url} component={Summary} />
                <Route exact path={steps[Step.CONFIRMATION].url} component={Confirmation} />
                <Route exact path="/guide" component={StyleGuide} />
                {/* <Route exact path="/stripe" component={Stripe} /> */}
                <Redirect from="*" to="/" />
              </Switch>
            </StepProvider>
          </ErrorBoundary>
          {/* </Elements> */}
        </StylesProvider>
      </ThemeProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
