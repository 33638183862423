import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        backgroundColor: theme.palette.green.main,
        padding: '1rem',
        color: theme.palette.common.white,
    },
    sticky: {
        position: 'sticky',
        display: 'block',
        top: '8rem',
    },
    textUppercase: {
        textTransform: 'uppercase',
    },
    borderTop: {
        borderTop: `5px solid ${theme.palette.common.white}`,
        width: '100%',
        paddingTop: '1rem'
    },
    px05: {
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
    },
    px1: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
    },
    textBlack: {
        color: theme.palette.common.black,
    },
    textRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dropShadow: theme.shadowStyles.masterDropShadow,
    body1: {
        ...theme.bodyStyles.body1
    },
    body2: {
        ...theme.bodyStyles.body2,
    },
    body5: {
        ...theme.bodyStyles.body5,
    }
}), { index: 1 });