import React, { useContext, useState } from 'react';
import { StepChecker, DocumentTitle, ContactSideBar, ButtonPrimary, ButtonSecondary, YourPrice, StateTracker, StateEditor, Typography } from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './VehicleDetailsStyles';
import { StepContext, IStepData, steps, Step } from '../../contexts/StepContext';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VehicleLookup, IVehicleData } from '../../components/molecules';
import * as yup from 'yup';
import yourPriceSchema from '../../components/atoms/YourPrice/YourPriceSchema.json';

export const VehicleDetails: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data } = useContext(StepContext);
  const [vehicles, setVehicles] = useState<Array<IVehicleData>>(data.vehicles);
  const [vehicleIndex, setVehicleIndex] = useState(
    data.vehicles.length > 0
      ? data.vehicles.reduce((p, c) => (p.reactIndex > c.reactIndex ? p : c)).reactIndex
      : data.vehicles.length,
  );
  const history = useHistory();
  const classes = useStyles();
  const { yourPriceData } = yourPriceSchema;

  const schema = yup.object().shape({
    vehicles: yup
      .array()
      .of(
        yup.object().shape({
          reactIndex: yup.number(),
          reg: yup.string().required(),
          make: yup.string().required(),
          model: yup.string().optional().nullable(),
          transmissionType: yup.string().nullable(),
          fuelType: yup.string().nullable(),
          year: yup.string().nullable(),
          apiNoResult: yup.bool().nullable(),
          complete: yup.bool().oneOf([true]).required(),
          fieldDisabled: yup.bool()
        }),
      )
      .min(1)
      .required(),
  });

  const { handleSubmit, control, setValue, trigger, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: data,
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({ ...data, ...stepData });
    history.push(steps[Step.BUSINESS_DETAILS].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({ ...data, vehicles });
    history.push(steps[Step.ASSUMPTIONS].url);
  };

  const handleChangeYourRequirements = () => {
    updateActiveStep(1);
    updateData({ ...data, vehicles });
    history.push(steps[Step.QUOTE].url);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title="DLG Fleet - Vehicle Details" />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.gridMainDiv}>
              <Typography className="stepHeading" variant="h1">
                STEP 3 - VEHICLE DETAILS
              </Typography>
              <VehicleLookup
                vehicles={vehicles}
                setVehicles={setVehicles}
                vehicleIndex={vehicleIndex}
                setVehicleIndex={setVehicleIndex}
                name="vehicles"
                control={control}
                coverType={data.coverType}
                setValue={setValue}
                trigger={trigger}
                handleChangeYourRequirements={handleChangeYourRequirements}
              />
              <Divider className="divider2-my-1"></Divider>
              <div className="stepBtnContainer">
                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
                <ButtonPrimary disabled={!formState.isValid} type="submit">Next</ButtonPrimary>
              </div>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.gridSidebarContainer}>
          <div className={classes.gridSidebarSticky}>
            <ContactSideBar
            sticky={false}
            />
            <div className={classes.gridSidebarYourPriceContainer}>
              <YourPrice
                sticky={false}
                heading={yourPriceData.heading}
                cover={data.coverType}
                price={`£${data.quoteTotal.toFixed(2)}`}
                info={yourPriceData.info}
              />
            </div>
            <StateTracker/>
            <StateEditor/>
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default VehicleDetails;
