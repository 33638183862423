import React from 'react';
import { useStyles} from './MiniAccordionStyles';
import {
  CSSMiniAccordion,
  MiniAccordionSummary,
  MiniAccordionDetails
} from './MiniAccordionStyles';
import { Typography } from '../../atoms';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IMiniAccordion {
  heading: string;
  body: string;
}

const MiniAccordion: React.FC<IMiniAccordion> = ({ heading, body }: IMiniAccordion): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <CSSMiniAccordion defaultExpanded={false}>
        <MiniAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="MiniAccordion1d-content"
          id="MiniAccordion1d-header"
        >
          <Typography className={classes.miniAccordion} variant="h2">
            {heading}
          </Typography>
        </MiniAccordionSummary>
        <MiniAccordionDetails>
          <Typography className={classes.miniAccordion}>{body}</Typography>
        </MiniAccordionDetails>
      </CSSMiniAccordion>
    </div>
  );
};

export default MiniAccordion;
