import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    borderBottom: `3px solid ${theme.palette.common.black}`,
    '&:last-child': {
        margin: '0 0 1rem 0',
    }
  },
  text: {
    color: theme.palette.common.black,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    fontWeight: 700,
  },
}), { index: 1 });
